import React, { useState } from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import calendarIcon from "include/images/calendar.svg";

function DateRangeCalendarPicker({ dateRange, setDateRange, onChange, ...props }) {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const isSelected = (range, definedRange) => {
    return (
      range.startDate.getTime() === definedRange.startDate.getTime() &&
      range.endDate.getTime() === definedRange.endDate.getTime()
    );
  };

  const definedRanges = [
    {
      label: "Last Week",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 2 Weeks",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 2 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 2)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 2)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 4 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 4)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 4)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 5 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 5)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 5)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 6 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 9 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 9)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 9)),
          endDate: new Date(),
        }),
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
        endDate: new Date(),
      }),
      isSelected: (range) =>
        isSelected(range, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
          endDate: new Date(),
        }),
    },
  ];

  return (
    <>
      {showDatePicker && (
        <div className="position-relative">
          <DateRangePicker
            className="custom-date-range-picker"
            ranges={[dateRange]}
            staticRanges={definedRanges}
            inputRanges={[]}
            onChange={(ranges) => {
              const { selection } = ranges;
              console.log("date:", selection.startDate, selection.endDate);
              if (selection.startDate) {
                selection.startDate.setHours(0, 0, 0, 0);
              }
              if (selection.endDate) {
                selection.endDate.setHours(23, 59, 59, 999);
              }
              onChange(ranges);
            }}
            maxDate={
              new Date(Math.min(new Date(), new Date(dateRange.startDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000)))
            }
            minDate={new Date(dateRange.startDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000)}
            showPreview
            rangeColors={["#e46223"]}
          />
        </div>
      )}
      <a
        href="#!"
        onClick={(e) => {
          e.preventDefault();
          setShowDatePicker(!showDatePicker);
        }}
      >
        <img src={calendarIcon} className="pointer" />
      </a>

      {showDatePicker && <div className="date-picker-overlay" onClick={() => setShowDatePicker(false)}></div>}
    </>
  );
}

export default DateRangeCalendarPicker;
