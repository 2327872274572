import Joi from "joi-browser";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTelegram, loadGameImages } from "store/telegram";
import { camelCaseToNormal } from "utils/misc";

//  Images
import closeIcon from "include/images/close-icon.svg";
import copyIcon from "include/images/copy.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { toast } from "react-toastify";
import UploadBattleGameImageFlyer from "./uploadBattleGameImageFlyer";

class GameListing extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: true,
    showUploadImage: false,
    data: {
      type: null,
    },
    appliedFilters: {},
    errors: {},
    sorting: { sort: "createdAt", order: "desc" },
    showFilters: false,
    page: 1,
  };
  componentDidMount = () => {
    this.searchFilters();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.gameImagesLoading &&
      this.props.getTelegram.gameImagesLoading !== prevProps.getTelegram.gameImagesLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.gameImagesLoading &&
      this.props.getTelegram.gameImagesLoading !== prevProps.getTelegram.gameImagesLoading
    )
      this.ref.current.continuousStart();
  };
  //  Filters
  searchFilters = () => {
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const params = {
      type: data?.type?.value,
      sort: sorting?.sort,
      order: sorting?.order,
    };
    params.page = this.state.page;

    this.props.loadGameImages(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
        });
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    if (type === 1) {
      if (sorting?.sort === "createdAt" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "createdAt";
    }
    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  addImageToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showUploadImage: !this.state.showUploadImage });
  };
  schema = {
    type: Joi.object().allow(null),
  };
  typeOptions = [
    // { name: "Character", value: "character" },
    { name: "Enemy", value: "enemy" },
    { name: "Gear", value: "gear" },
    { name: "Reward", value: "reward" },
    { name: "Stage", value: "stage" },
    { name: "Status Effect", value: "statusEffect" },
  ];
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          type: null,
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { loading } = this.state;
    const { gameImages, gameImagesTotalCount } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-battleGame-images" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Battle Game Images {!loading && gameImagesTotalCount > 0 && `(${gameImagesTotalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} alt="filter" /> <span>Filters</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <button className="btn btn-default btn-sm" onClick={this.addImageToggle}>
                    <img src={plusIcon} alt="" /> Upload Images
                  </button>
                </div>
              </div>
            </div>

            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Type</label>
                          <SelectSearch
                            name="type"
                            options={this.typeOptions}
                            label=""
                            value={this.state.data.type}
                            error={this.state.errors.type}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>{" "}
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">{key === "type" ? "Type" : key}</span> :{" "}
                              {this.state.appliedFilters[key].name}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      data: { ...this.state.data, [key]: null },
                                      page: 1,
                                    },
                                    this.searchFilters,
                                  );
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {gameImages?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Image Name</th>
                            <th>Type</th>
                            <th>
                              Date
                              <a href="#!" onClick={(e) => this.sort(e, 1)}>
                                <img
                                  src={
                                    this.state.sorting?.sort === "createdAt"
                                      ? this.state.sorting?.order === "desc"
                                        ? arrowIcon1
                                        : arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {gameImages?.map((gameImage, i) => (
                            <tr key={gameImage?._id}>
                              <td>
                                <a
                                  style={{ width: 60 }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={process.env.REACT_APP_CDN + gameImage?.image}
                                >
                                  {gameImage?.image && (
                                    <img src={process.env.REACT_APP_CDN + gameImage?.image} alt="" />
                                  )}
                                </a>
                              </td>
                              <td>
                                <span
                                  className={`orange-text pointer`}
                                  onClick={(e) => {
                                    if (e) e.preventDefault();
                                    navigator?.clipboard?.writeText(gameImage?.image?.split("/")?.pop());
                                    toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                  }}
                                >
                                  {gameImage?.image?.split("/")?.pop()} <img src={copyIcon} alt="copy" />
                                </span>
                              </td>
                              <td>{camelCaseToNormal(gameImage?.type)}</td>
                              <td>
                                <div className="td-text-box">
                                  {moment(gameImage?.createdAt).format("MM/DD/YYYY hh:mm a")}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading && !(this.state.page === 1 && gameImages?.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#!"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {gameImages?.length >= 100 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${gameImages?.length < 100 && "disabled"}`}
                          href="#!"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <UploadBattleGameImageFlyer
          showModal={this.state.showUploadImage}
          toggleModal={this.addImageToggle}
          reload={() => {
            this.setState({ page: 1 }, this.searchFilters);
          }}
        />
        {this.state.showUploadImage && (
          <div className="modal-backdrop z-index-1000 show fade" onClick={this.addImageToggle}></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadGameImages: (params, callback) => dispatch(loadGameImages(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GameListing));
