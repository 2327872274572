import React, { Component } from "react";
import { DateRangePicker } from "react-date-range";
import calendarIcon from "include/images/calendar.svg";

class DateInput extends Component {
  ref = React.createRef(null);
  modalRef = React.createRef(null);
  state = {
    formclass: "form-group custom-input show on",
    open: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value ? "form-group custom-input show on" : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value ? "form-group custom-input show on" : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const { name, label, error, minDate, disabled, minTime, ...rest } = this.props;

    return (
      <>
        <div className="form-group  d-flex flex-column">
          <label className="form-label" htmlFor={label}>
            {label}
          </label>
          <input
            name={name}
            id={name}
            className={error ? "form-control error" : "form-control"}
            disabled={disabled}
            {...rest}
          />
          <DateRangePicker
            onChange={(item) => this.setState({ date: [item.selection] })}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={this.state.date}
            direction="horizontal"
          />
          ;
        </div>
      </>
    );
  }
}

export default DateInput;
