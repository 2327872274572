import DateInput from "common/dateOnlyInput";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getTelegram,
  loadDailyStatsDashboard,
  dailyStatsDashboardReceived,
  loadDailyStatsActivityEngagementDashboard,
} from "store/telegram";
import AnalyticsAllDataFlyer from "./analyticsAllDataFlyer";
import RetentionTabs from "./retentionTabs";

const GamesAnalytics = (props) => {
  const ref = useRef();

  // States
  const [type, setType] = useState("winner");
  const [tab, setTab] = useState("1");
  const [showViewAll, setShowViewAll] = useState(false);
  const [flyer, setFlyer] = useState({ title: "", data: [], col1Name: "", col2Name: "" });
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingStats, setLoadingStats] = useState(true);
  const [loadingFirst, setLoadingFirst] = useState(true);

  // State to manage the date range for the analytics
  const [dateRange, setDateRange] = useState(new Date(new Date().setDate(new Date().getDate() - 15)));

  // Load data
  useEffect(() => {
    getAnalyticsData();
  }, [tab]);

  const getAnalyticsData = (selection) => {
    setLoadingStats(true);
    setLoadingFirst(true);

    // Prepare payload with start and end dates
    const payLoad = selection ? { date: selection?.getTime() } : { date: dateRange?.getTime() };

    // Function to load data for a specific date
    const loadDataForDate = (date) => {
      return new Promise((resolve) => {
        if (tab === "1") {
          props.loadDailyStatsDashboard({ date: date }, (response) => {
            resolve(response);
          });
        } else {
          props.loadDailyStatsActivityEngagementDashboard({ date: date }, (response) => {
            resolve(response);
          });
        }
      });
    };

    // Load data for the selected date and the next 7 days
    const loadAllData = async () => {
      const responses = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(payLoad.date);
        date.setDate(date.getDate() + i);
        const response = await loadDataForDate(date.getTime());
        if (response.status === 200) {
          responses.push({ ...response.data, date: date });
          props.dailyStatsDashboardReceived([...responses]);

          setLoadingFirst(false);
        }
      }
      // Store all responses in redux store
      setLoadingStats(false);
      setLoadingMain(false);
    };

    loadAllData();
  };

  // Loader
  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  // Destructure props and states from redux
  const { dailyStatsDashboard, analyticsLoading } = props.getTelegram;

  // Toggle functions for flyers
  const toggleViewAll = (e, type) => {
    e.preventDefault();
    setShowViewAll(!showViewAll);
  };

  // Function to handle date range change
  const onDateChange = (date) => {
    if (date?.getTime() !== dateRange?.getTime()) {
      // Load new data based on the selected date range
      getAnalyticsData(date);
    }
    // Update the date range state
    setDateRange(date);
  };

  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="telegram-mini-app-analytics-sweepstakes" />
        <div className="main-content-wrapper position-relative">
          <div className="mcw-header d-flex align-items-center justify-content-between">
            <h1>Retention Analytics</h1>
          </div>
          <div className="fwc-wrapper">
            <div className="flex-wrap grid-card-details-row d-flex">
              <div className=" gcdr-text-box w-100">
                <div className="mt-0 gcdr-widget-wrapper w-100 row">
                  <div className="mt-0 table-responsive col-12">
                    <div className="w-25 retention-tabs-wrapper">
                      <RetentionTabs tab={tab} setTab={setTab} disabled={loadingStats} />
                    </div>
                    <table className="table custom-table-striped">
                      <thead className="table py-3 custom-table-striped d-flex justify-content-center align-items-center">
                        <h6 className="m-0 text-white">
                          User Retention Analytics on the basis of User {tab === "1" ? "Registrations" : "Activity"}{" "}
                        </h6>
                      </thead>
                      <tbody>
                        <tr>
                          <div className="text-center flex-row d-flex justify-content-around align-items-center">
                            <div className="gap-2 d-flex align-items-center">
                              <strong>Date:</strong>{" "}
                              <span>
                                <DateInput date={dateRange} setDate={setDateRange} onChange={onDateChange} />
                              </span>
                            </div>
                          </div>
                          {loadingFirst ? (
                            <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : dailyStatsDashboard?.length === 0 ? (
                            <div className="no-record-found-container">
                              <div className="nfr-box">
                                <img src={emptyIcon} alt="No Record Found" />
                                <div className="nrf-text">
                                  <h5>No Records Found!</h5>
                                </div>
                              </div>
                            </div>
                          ) : (
                            dailyStatsDashboard?.map((stats, index) => {
                              return (
                                <div className="row" key={index}>
                                  <div className="col-12">
                                    <div className="table-responsive">
                                      <strong className="mb-0">
                                        {new Date(stats?.date).toLocaleDateString("en-US")} -{" "}
                                        {tab === "1" ? "New Users" : "Active Users"}:
                                        <span>
                                          {" "}
                                          {tab === "1"
                                            ? stats?.newUser?.toLocaleString("en-US")
                                            : stats?.activeUser?.toLocaleString("en-US")}
                                        </span>
                                        , Total D-7 Retention:{" "}
                                        {isNaN(
                                          (stats?.overallUniqueRetention /
                                            (tab === "1" ? stats?.newUser : stats?.activeUser)) *
                                            100,
                                        )
                                          ? 0?.toFixed(2)
                                          : (
                                              (stats?.overallUniqueRetention /
                                                (tab === "1" ? stats?.newUser : stats?.activeUser)) *
                                              100
                                            )?.toFixed(2)}
                                        %
                                      </strong>
                                      <table className="table custom-table-striped trs-gray">
                                        <thead>
                                          <tr>
                                            {Object.keys(stats.engagementStats).map((key) => (
                                              <th key={key}>{key}</th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            {Object.values(stats.engagementStats).map((value, index) => (
                                              <td key={index}>{value.toLocaleString("en-US")}</td>
                                            ))}
                                          </tr>
                                          <tr>
                                            {Object.values(stats.engagementStats).map((value, index) => (
                                              <td key={index}>
                                                {tab === "1"
                                                  ? stats?.newUser
                                                    ? ((value / stats?.newUser) * 100).toFixed(2)
                                                    : 0
                                                  : stats?.activeUser
                                                  ? ((value / stats?.activeUser) * 100).toFixed(2)
                                                  : 0}
                                                %
                                              </td>
                                            ))}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                          {loadingStats &&
                            !loadingFirst && (
                              <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                                <img src={loadingIcon} className="fa-spin" alt="" />
                              </div>
                            ) && (
                              <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                                <img src={loadingIcon} className="fa-spin" alt="" />
                              </div>
                            )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnalyticsAllDataFlyer
        title={flyer?.title}
        data={flyer?.data}
        col1Name={flyer?.col1Name}
        col2Name={flyer?.col2Name}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
      />

      {showViewAll && <div className="modal-backdrop show fade" onClick={toggleViewAll}></div>}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadDailyStatsDashboard: (params, callback) => dispatch(loadDailyStatsDashboard(params, callback)),
  loadDailyStatsActivityEngagementDashboard: (params, callback) =>
    dispatch(loadDailyStatsActivityEngagementDashboard(params, callback)),
  dailyStatsDashboardReceived: (data) => dispatch(dailyStatsDashboardReceived(data)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamesAnalytics));
