import AlertClose from "common/alert/alertClose";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//  Routes
import AMOEListing from "features/amoe/amoeLIsting";
import AddContest from "features/contests/addContests";
import ContestDetails from "features/contests/contestDetails";
import ContestsListing from "features/contests/contestsListing";
import WinnersListing from "features/contestWinners/winnersListing";
import GamesDetails from "features/games/gameDetails";
import GamesListing from "features/games/gamesListing";
import PracticeGameDetails from "features/games/practiceGameDetails";
import SignIn from "features/onboarding/signIn";
import PaymentsListings from "features/payments/paymentsListings";
import AddSlider from "features/settings/sliders/addSlider";
import Error404 from "features/staticPages/404";
import Error500 from "features/staticPages/500";
import ComingSoon from "features/staticPages/comingSoon";
import UsersListing from "features/users/usersLIsting";
//  Sweepstakes
import ContractListings from "features/sweepstakes/contracts/contractListings";
import CurrencyListings from "features/sweepstakes/currency/currencyListings";
import InstanceDetails from "features/sweepstakes/instances/instanceDetails";
import InstancesListings from "features/sweepstakes/instances/instancesListings";
//  Settings
import CoinRewardConfigListing from "boardGame/settings/coinRewardConfig/coinRewardConfigListing";
import ColorMapListing from "boardGame/settings/colorMap/colorMapListing";
import DiceRewardConfigListing from "boardGame/settings/diceRewardConfig/diceRewardConfigListing";
import GameConfigListing from "boardGame/settings/gameConfig/gameConfigListing";
import BoardGameCardListing from "boardGame/settings/playerCards/cardListing";
import SlotRewardsListing from "boardGame/settings/slotRewards/slotRewardsListing";
import SpinnerRewardsListing from "boardGame/settings/spinnerRewards/spinnerRewardsListing";
import TournamentListing from "boardGame/settings/tournaments/tournamentListing";
import AddBurnGhost from "features/burnghostGames/addBurnghostGame";
import BurnGhostGamesListing from "features/burnghostGames/burnghostGamesListing";
import CowitzerStats from "features/dashboard/cowitzer/cowitzerStats";
import DogRunStats from "features/dashboard/dogRacing/dogRunStats";
import LootboxStats from "features/dashboard/lootbox/lootboxStats";
import ReferralDashboard from "features/dashboard/referral/referralDashboard";
import VerisoulLinkedAccounts from "features/dashboard/verisoul/verisoulLinkedAccounts";
import VerisoulStats from "features/dashboard/verisoul/verisoulStats";
import CrashGameDetails from "features/dashboard/zedRun/zedRunStats";
import AddLootbox from "features/lootbox/addLootbox";
import LootboxListing from "features/lootbox/lootboxListing";
import BlockchainNetworkListing from "features/settings/blockchainNetworks/blockchainNetworkListing";
import CoinsPackageListing from "features/settings/coinsPackages/coinsPackageListing";
import ContestTemplateListing from "features/settings/contestTemplate/contestTemplateListing";
import GeoLocation from "features/settings/geolocation/geolocation";
import HowItWorksListing from "features/settings/howItWorks/howItWorksListing";
import MaintenanceMode from "features/settings/maintenanceMode/maintenanceMode";
import LeaderboardCredits from "features/settings/miscSettings/miscSettingsListing";
import NewsLetterListing from "features/settings/newsLetter/newLetterListing";
import ReportsListing from "features/settings/reports/reportsListings";
import SliderListing from "features/settings/sliders/sliderListing";
import SweepstakeTemplateListing from "features/settings/sweepstakesTemplate/sweepstakeTemplateListing";
import TicketRedemptionRequest from "features/settings/ticketRedemption/ticketRedemptionRequest";
import WaitListListing from "features/settings/waitlist/waitListListing";
import WeeklyLeaderboard from "features/settings/weeklyLeaderboard/weeklyLeaderboard";
import TelegramEngagementAnalytics from "features/telegramSettings/analytics/engagement/telegramEngagementAnalytics";
import DailyStatsAnalytics from "features/telegramSettings/analytics/retention/dailyStatsAnalytics";
import TelegramSweepstakesAnalytics from "features/telegramSettings/analytics/sweepstakes/telegramSweepstakesAnalytics";
import EnemiesCsvList from "features/telegramSettings/battleGame/enemies/enemiesCsvList";
import GameImageListing from "features/telegramSettings/battleGame/gameImage/gameImageListing";
import GearsCsvList from "features/telegramSettings/battleGame/gears/gearsCsvList";
import RewardsCsvList from "features/telegramSettings/battleGame/rewards/rewardsCsvList";
import StagesCsvList from "features/telegramSettings/battleGame/stages/stagesCsvList";
import StatusEffectsCsvList from "features/telegramSettings/battleGame/statusEffects/statusEffectsCsvList";
import GamesPlayedListing from "features/telegramSettings/bgGames/gamesPlayedListing";
import TelegramUserChallenges from "features/telegramSettings/challenges/telegramUserChallenges";
import DailyTournamentListings from "features/telegramSettings/dailyTournament/dailyTournamentListings";
import TelegramCowitzerStats from "features/telegramSettings/luckGames/cowitzer/telegramCowitzerStats";
import TelegramRobotRacerStats from "features/telegramSettings/luckGames/robotRacer/telegramRobotRacerStats";
import TelegramZedRunStats from "features/telegramSettings/luckGames/zedRun/telegramZedRunStats";
import MiscTelegramSettingsListing from "features/telegramSettings/miscSettings/miscTelegramSettingsListing";
import QuestsListings from "features/telegramSettings/quests/questsListings";
import TelegramSpinnerRewardsListing from "features/telegramSettings/spinnerRewards/spinnerRewardsListing";
import PrizesTemplateListings from "features/telegramSettings/sweepstakes/prizesTemplateListings";
import SweepstakesListings from "features/telegramSettings/sweepstakes/sweepstakesListings";
import SweepstakesListingsTemplate from "features/telegramSettings/sweepstakes/sweepstakesListingsTemplate";
import TeamPlayChallenges from "features/telegramSettings/teamPlay/teamPlayChallenges";
import UsdtWithdrawalRequestsListing from "features/telegramSettings/usdtWithdrawal/usdtWithdrawalRequestsListing";
import TelegramNotificationsListing from "features/telegramSettings/users/telegramNotificationsListing";
import TelegramUsersListing from "features/telegramSettings/users/telegramUserListing";
import VerisoulLinkedTelegramAccounts from "features/telegramSettings/verisoul/verisoulLinkedTelegramAccounts";
import VerisoulTelegramUsers from "features/telegramSettings/verisoul/verisoulTelegramUsers";
import GearSubTypesList from "features/telegramSettings/battleGame/gearSubTypes/gearSubTypesList";
import BattleGamesListing from "features/telegramSettings/battleGame/game/battleGamesListing";

export default function Router(props) {
  const isLoggedIn = localStorage.getItem("x-auth-token");
  return (
    <>
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        autoClose={3000}
        toastClassName="alert d-flex align-items-center"
        closeButton={<AlertClose />}
        containerId={1}
      />{" "}
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        autoClose={false}
        toastClassName="alert d-flex align-items-center"
        closeButton={<AlertClose />}
        position="bottom-right"
        containerId={3}
      />
      <ToastContainer
        hideProgressBar
        autoClose={false}
        enableMultiContainer
        toastClassName="alert alert-red d-flex align-items-center"
        containerId={2}
        position="bottom-right"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
      />
      <BrowserRouter>
        <Switch>
          <Route exact path="/404">
            <Error404 />
          </Route>
          <Route exact path="/500">
            <Error500 />
          </Route>
          <Route exact path="/coming-soon">
            <ComingSoon />
          </Route>
          <Route exact path="/signin">
            <SignIn />
          </Route>
          <Route exact path="/">
            {isLoggedIn ? <Redirect to="/games?scoringType=2" /> : <Redirect to="/signin" />}
          </Route>
          <Route exact path="/contests">
            <ContestsListing />
          </Route>
          <Route exact path="/add-contest">
            <AddContest />
          </Route>
          <Route exact path="/contests/:name">
            <ContestDetails />
          </Route>
          <Route exact path="/geolocation">
            <GeoLocation />
          </Route>{" "}
          <Route exact path="/custom-sliders">
            <SliderListing />
          </Route>
          <Route exact path="/add-slider">
            <AddSlider />
          </Route>
          <Route exact path="/games">
            <GamesListing />
          </Route>{" "}
          <Route exact path="/practice-game-details/:id">
            <PracticeGameDetails />
          </Route>{" "}
          <Route exact path="/amoe">
            <AMOEListing />
          </Route>
          <Route exact path="/game-details/:id">
            <GamesDetails />
          </Route>{" "}
          <Route exact path="/users">
            <UsersListing />
          </Route>{" "}
          <Route exact path="/payments">
            <PaymentsListings />
          </Route>{" "}
          <Route exact path="/contest-winners">
            <WinnersListing />
          </Route>
          <Route exact path="/newsletter-subscribers">
            <NewsLetterListing />
          </Route>
          <Route exact path="/wait-list">
            <WaitListListing />
          </Route>{" "}
          <Route exact path="/settings/misc-settings">
            <LeaderboardCredits />
          </Route>
          <Route exact path="/maintenance">
            <MaintenanceMode />
          </Route>
          <Route exact path="/dashboard/zed-run">
            <CrashGameDetails />
          </Route>
          <Route exact path="/dashboard/robot-racer">
            <DogRunStats />
          </Route>
          <Route exact path="/dashboard/cowitzer">
            <CowitzerStats />
          </Route>
          <Route exact path="/burn-ghost-games">
            <BurnGhostGamesListing />
          </Route>
          <Route exact path="/add-burn-ghost-game">
            <AddBurnGhost />
          </Route>
          <Route exact path="/how-it-works-template">
            <HowItWorksListing />
          </Route>{" "}
          <Route exact path="/lootbox">
            <LootboxListing />
          </Route>
          <Route exact path="/add-lootbox">
            <AddLootbox />
          </Route>
          <Route exact path="/lootbox/:name">
            <AddLootbox />
          </Route>{" "}
          <Route exact path="/dashboard/lootbox">
            <LootboxStats />
          </Route>{" "}
          <Route exact path="/dashboard/verisoul">
            <VerisoulStats />
          </Route>
          <Route exact path="/dashboard/verisoul-linked-account">
            <VerisoulLinkedAccounts />
          </Route>
          <Route exact path="/dashboard/referrals">
            <ReferralDashboard />
          </Route>
          <Route exact path="/settings/blockchain-networks">
            <BlockchainNetworkListing />
          </Route>
          <Route exact path="/settings/packages">
            <CoinsPackageListing />
          </Route>
          <Route exact path="/settings/coin-balance-report">
            <ReportsListing />
          </Route>
          {/* Sweepstakes */}
          <Route exact path="/sweepstakes/contracts">
            <ContractListings />
          </Route>{" "}
          <Route exact path="/sweepstakes/currencies">
            <CurrencyListings />
          </Route>{" "}
          <Route exact path="/sweepstakes/instances">
            <InstancesListings />
          </Route>
          <Route exact path="/sweepstakes/instances/:slug">
            <InstanceDetails />
          </Route>{" "}
          <Route exact path="/settings/contest-templates">
            <ContestTemplateListing />
          </Route>
          <Route exact path="/settings/sweepstakes-templates">
            <SweepstakeTemplateListing />
          </Route>{" "}
          <Route exact path="/settings/weekly-leaderboard">
            <WeeklyLeaderboard />
          </Route>
          <Route exact path="/settings/ticket-redemption-requests">
            <TicketRedemptionRequest />
          </Route>
          <Route exact path="/board-game/settings/cards">
            <BoardGameCardListing />
          </Route>
          <Route exact path="/board-game/settings/color-map">
            <ColorMapListing />
          </Route>
          <Route exact path="/board-game/settings/tournaments">
            <TournamentListing />
          </Route>
          <Route exact path="/board-game/settings/spinner">
            <SpinnerRewardsListing />
          </Route>{" "}
          <Route exact path="/board-game/settings/slot">
            <SlotRewardsListing />
          </Route>{" "}
          <Route exact path="/telegram/settings/users">
            <TelegramUsersListing />
          </Route>{" "}
          <Route exact path="/telegram/settings/notifications">
            <TelegramNotificationsListing />
          </Route>
          <Route exact path="/telegram/settings/sweepstakes/">
            <SweepstakesListings />
          </Route>
          <Route exact path="/telegram/settings/sweepstakes/prizes">
            <PrizesTemplateListings />
          </Route>
          <Route exact path="/telegram/settings/sweepstakes/templates">
            <SweepstakesListingsTemplate />
          </Route>
          <Route exact path="/board-game/settings/game-config">
            <GameConfigListing />
          </Route>
          <Route exact path="/board-game/settings/coin-reward-config">
            <CoinRewardConfigListing />
          </Route>
          <Route exact path="/board-game/settings/dice-reward-config">
            <DiceRewardConfigListing />
          </Route>
          <Route exact path="/telegram/settings/spinner">
            <TelegramSpinnerRewardsListing />
          </Route>
          <Route exact path="/telegram/settings/usdtWithdrawal">
            <UsdtWithdrawalRequestsListing />
          </Route>
          <Route exact path="/telegram/settings/misc-settings">
            <MiscTelegramSettingsListing />
          </Route>
          <Route exact path="/telegram/settings/challenges">
            <TelegramUserChallenges />
          </Route>
          <Route exact path="/telegram/settings/team-play">
            <TeamPlayChallenges />
          </Route>
          <Route exact path="/telegram/settings/verisoul">
            <VerisoulTelegramUsers />
          </Route>
          <Route exact path="/telegram/settings/verisoul-linked-account">
            <VerisoulLinkedTelegramAccounts />
          </Route>
          <Route exact path="/telegram/settings/quests">
            <QuestsListings />
          </Route>{" "}
          <Route exact path="/telegram/settings/daily-tournament">
            <DailyTournamentListings />
          </Route>
          <Route exact path="/telegram/settings/games">
            <GamesPlayedListing />
          </Route>
          <Route exact path="/telegram/settings/cowitzer">
            <TelegramCowitzerStats />
          </Route>
          <Route exact path="/telegram/settings/zrun">
            <TelegramZedRunStats />
          </Route>
          <Route exact path="/telegram/settings/robot-racer">
            <TelegramRobotRacerStats />
          </Route>
          <Route exact path="/telegram/settings/analytics/sweepstakes">
            <TelegramSweepstakesAnalytics />
          </Route>
          <Route exact path="/telegram/settings/analytics/stats">
            <TelegramEngagementAnalytics />
          </Route>
          <Route exact path="/telegram/settings/analytics/retention">
            <DailyStatsAnalytics />
          </Route>{" "}
          {/* <Route exact path="/telegram/settings/battle-game/characters">
            <CharactersCsvList />
          </Route>{" "} */}
          <Route exact path="/telegram/settings/battle-game/stages">
            <StagesCsvList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/status-effects">
            <StatusEffectsCsvList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/rewards">
            <RewardsCsvList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/enemies">
            <EnemiesCsvList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/gears">
            <GearsCsvList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/gears-subtype">
            <GearSubTypesList />
          </Route>
          <Route exact path="/telegram/settings/battle-game/images">
            <GameImageListing />
          </Route>
          <Route exact path="/telegram/settings/battle-game/games">
            <BattleGamesListing />
          </Route>
          {/* Misc */}
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}
