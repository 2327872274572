import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getSettings } from "store/settings";
import { addQuest, getTelegram, loadQuests, updateQuest } from "store/telegram";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import SelectSearch from "common/form/selectSearch";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initState = {
    loading: false,
    data: {
      title: "",
      description: "",
      type: "",
      url: "",
      image: null,
      reward: 0,
      rewardTransactionTitle: "",
      displayOrder: null,
      internalLink: false,
    },
    errors: {},
    imageChange: false,
    edit: false,
  };
  state = { ...structuredClone(this.initState) };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        const data = { ...this.state.data };
        if (this.props.edit) {
          data.title = this.props.edit.title;
          data.description = this.props.edit.description;
          data.type = this.props.edit.type;
          data.url = this.props.edit.url;
          data.reward = this.props.edit.reward;
          data.rewardTransactionTitle = this.props.edit.rewardTransactionTitle;
          data.internalLink = this.props.edit?.internalLink;

          data.image = this.props.edit.image;
        }
        if (this.props.edit?.displayOrder)
          data.displayOrder = { name: `${this.props.edit?.displayOrder}`, value: this.props.edit?.displayOrder };
        this.setState({
          data,
          edit: this.props.edit,
        });
      }
    }
  };
  handleResponse = (res) => {
    this.setState({ loading: false });
    if (res?.status === 200) {
      toast(<AlertSuccess message="Information Saved" />, { containerId: 1 });
      this.clearData();
      this.props.loadQuests();
      this.props.toggleModal();
    } else {
      toast(<AlertError message={res.data && res.data.message} />, { containerId: 1 });
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      title: data.title,
      description: data.description,
      url: data.url,
      type: data.type,
      rewardTransactionTitle: data.rewardTransactionTitle,
      reward: parseInt(data.reward),
      image: this.state.imageChange ? data.image.medium : data.image,
      internalLink: data.internalLink,
    };
    if (data?.displayOrder?.value) payLoad.displayOrder = data?.displayOrder?.value;

    if (this.props.edit) {
      this.props.updateQuest(this.props.edit._id, payLoad, this.handleResponse);
    } else {
      this.props.addQuest(payLoad, this.handleResponse);
    }
  };

  schema = {
    title: Joi.string().required(),
    description: Joi.string().required(),
    image: Joi.alternatives().try(Joi.string(), Joi.object()).required(),
    url: Joi.string().required(),
    type: Joi.string().required(),
    reward: Joi.number().required(),
    rewardTransactionTitle: Joi.string().required(),
    displayOrder: Joi.object().allow([{}, null]),
    internalLink: Joi.boolean().allow(false),
  };
  clearData = () => {
    this.setState({ ...this.initState });
  };

  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          imageChange: true,
          loadingImages: false,
          data: {
            ...this.state.data,
            image: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };
  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = !data[e.target.name];
    delete errors[e.target.name];
    this.setState({ data, errors });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];

    this.setState({ data, errors });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end  custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add a"} Quest</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">{this.renderInput("title", "Title")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("description", "Description")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("url", "URL")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("type", "Type")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("reward", "Reward (In Points)", "", "number")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("rewardTransactionTitle", "Reward Transaction Title")}</div>
            </div>
            <div className="new-form-group">
              <div className="form-check has-circle-radio">
                <input
                  className={`form-check-input ${this.state.errors.internalLink && "border-error"}`}
                  type="checkbox"
                  name="internalLink"
                  id="radioCheckOneFlyer"
                  checked={this.state.data.internalLink}
                  onChange={this.onTypeChange}
                />
                <label className="form-check-label" htmlFor="radioCheckOneFlyer">
                  Internal Link
                </label>
              </div>
            </div>
            <div className="new-form-group">
              <label className="form-label">Display Order</label>
              <SelectSearch
                name="displayOrder"
                options={[...Array(20).keys()].map((i) => ({ name: `${i + 1}`, value: i + 1 }))}
                label="Select Display Order"
                value={this.state.data.displayOrder}
                error={this.state.errors.displayOrder}
                onChange={this.onChange}
              />
            </div>
            <div className="flex-wrap kt-upload-row d-flex align-items-start">
              <div
                className={`kt-upload-outer ${
                  !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                } ${this.state.errors.image && "border-error"}`}
              >
                <div className="kt-upload-img">
                  <img
                    src={
                      this.state.data.image
                        ? this.state.data.image.medium
                          ? this.state.data.image.medium.includes("https://")
                            ? this.state.data.image.medium
                            : process.env.REACT_APP_CDN + this.state.data.image.medium
                          : process.env.REACT_APP_CDN + this.state.data.image
                        : plusIcon
                    }
                    alt=""
                  />
                </div>{" "}
                {this.state.data.image && (
                  <div
                    className="delete-icon d-flex align-items-center justify-content-center"
                    onClick={(e) => {
                      const data = this.state.data;
                      data.image = null;
                      this.setState({ data });
                    }}
                  >
                    <img src={deleteIcon} />
                  </div>
                )}
              </div>
              <div className="kt-file-input">
                <label htmlFor="formFile" className="kt-label">
                  {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                </label>
                <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadQuests: (callback) => dispatch(loadQuests(callback)),
  addQuest: (data, callback) => dispatch(addQuest(data, callback)),
  updateQuest: (id, data, callback) => dispatch(updateQuest(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
