import React, { Component } from "react";
import loader from "include/images/loading-icon.svg";

class SubmitButton extends Component {
  state = {};
  render() {
    return (
      <a
        onClick={!this.props.loading ? this.props.handleSubmit : null}
        className={`${this.props.classData} ${this.props.loading && "loading disable"} ${
          this.props.disabled && "disable"
        } `}
      >
        {this.props.loading ? <img src={loader} className="fa-spin" alt="" /> : this.props.label}
      </a>
    );
  }
}

export default SubmitButton;
