import React, { createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";
import { getTelegram, loadVerisoulAuthResultsById } from "store/telegram";
import VerisoulAuthResults from "../verisoul/verisoulAuthResults";

class AuthResultsFlyer extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loadingMain: false,
    sorting: {},
    loadingFilters: false,
    page: 1,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.searchFilters();
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({
        loadingMain: false,
        sorting: {},
        loadingFilters: false,
        page: 1,
      });
    }

    if (
      !this.props.getTelegram.verisoulAuthResultsByIdLoading &&
      this.props.getTelegram.verisoulAuthResultsByIdLoading !== prevProps.getTelegram.verisoulAuthResultsByIdLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.verisoulAuthResultsByIdLoading &&
      this.props.getTelegram.verisoulAuthResultsByIdLoading !== prevProps.getTelegram.verisoulAuthResultsByIdLoading
    )
      this.ref.current.continuousStart();
  };
  sort = (e, type) => {
    const sorting = { ...this.state.sorting };

    if (type === 1) {
      if (sorting?.sort === "accountScore" && sorting?.order === "desc") {
        sorting.sort = "accountScore";
        sorting.order = "asc";
      } else {
        sorting.sort = "accountScore";
        sorting.order = "desc";
      }
    } else if (type === 2) {
      if (sorting?.sort === "bot" && sorting?.order === "desc") {
        sorting.sort = "bot";
        sorting.order = "asc";
      } else {
        sorting.sort = "bot";
        sorting.order = "desc";
      }
    } else if (type === 3) {
      if (sorting?.sort === "riskSignals" && sorting?.order === "desc") {
        sorting.sort = "riskSignals";
        sorting.order = "asc";
      } else {
        sorting.sort = "riskSignals";
        sorting.order = "desc";
      }
    } else if (type === 4) {
      if (sorting?.sort === "multipleAccounts" && sorting?.order === "desc") {
        sorting.sort = "multipleAccounts";
        sorting.order = "asc";
      } else {
        sorting.sort = "multipleAccounts";
        sorting.order = "desc";
      }
    }
    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };

  searchFilters = (e, page) => {
    if (e) e.preventDefault();

    this.setState({ loadingFilters: true });
    const sorting = { ...this.state.sorting };
    const params = {
      sort: sorting?.sort,
      order: sorting?.order,
    };
    if (page) {
      this.setState({ page });
      params.page = page;
    } else params.page = this.state.page;

    this.props.loadVerisoulAuthResultsById(this.props.user?._id, params, (userRes) => {
      this.setState({ loading: false, loadingFilters: false });
    });
  };
  render() {
    const { user } = this.props;
    const { verisoulAuthResultsById, verisoulAuthResultsByIdTotalCount, verisoulAuthResultsByIdFilteredCount } =
      this.props.getTelegram;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>
                Verisoul Authentication Stats{" "}
                {verisoulAuthResultsByIdTotalCount > 0 && `(${verisoulAuthResultsByIdTotalCount})`}
              </h5>
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="td-img round-img m-right-2 pointer">
                  <img src={user?.image?.small ? process.env.REACT_APP_CDN + user.image.small : userIcon} alt="" />
                </div>
                {user?.firstName} {user?.lastName}
                <br />
                {user?.userName}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <VerisoulAuthResults
              authResults={verisoulAuthResultsById}
              filteredCount={verisoulAuthResultsByIdFilteredCount}
              loadingMain={this.state.loadingMain}
              sorting={this.state.sorting}
              sort={this.sort}
              page={this.state.page}
              searchFilters={this.searchFilters}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadVerisoulAuthResultsById: (id, params, callback) => dispatch(loadVerisoulAuthResultsById(id, params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthResultsFlyer));
