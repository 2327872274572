import React from "react";
import { Nav } from "react-bootstrap";

function RetentionTabs({ tab, setTab, disabled }) {
  return (
    <Nav fill variant="tabs" defaultActiveKey={tab}>
      <Nav.Item>
        <Nav.Link
          eventKey="1"
          onClick={(e) => {
            e.preventDefault();
            setTab("1");
          }}
          disabled={disabled}
        >
          Registration
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="2"
          onClick={(e) => {
            e.preventDefault();
            setTab("2");
          }}
          disabled={disabled}
        >
          Activity
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default RetentionTabs;
