import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import calendarIcon from "include/images/calendar.svg";
import moment from "moment";

function DateInput({ date, setDate, onChange, ...props }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1c1d23",
        color: "white",
      },
    },
    zIndex: 999999,
    typography: {
      color: "#1b1b1b",
    },
  });

  return (
    <>
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <DatePicker
            renderInput={(props) => (
              <TextField classes={"pointer-none"} focused={false} className="datepicker" {...props} disabled={true} />
            )}
            maxDate={moment().subtract(14, "days").toDate()}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
            className={"form-control datepicker"}
            value={date}
            onChange={(date) => {
              if (onChange) onChange(date);
            }}
            components={{
              OpenPickerIcon: () => <img src={calendarIcon} />,
              SwitchViewIcon: KeyboardArrowDownIcon,
            }}
            {...props}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default DateInput;
