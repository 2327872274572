import moment from "moment/moment";
import React, { createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";
import { getTelegram, loadTeammates } from "store/telegram";

class ListingFlyer extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: false,
    loadingMore: false,
    page: 1,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { teammates, teammatesTotalCount } = this.props.getTelegram;

      if (!this.state.loadingMore && teammates?.length < teammatesTotalCount) {
        this.setState({ loadingMore: true });
        const params = { page: this.state.page + 1 };

        this.props.loadTeammates(this.props.user?._id, params, false, (res) =>
          this.setState({ loadingMore: false, page: this.state.page + 1 }),
        );
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      const params = {};

      this.props.loadTeammates(this.props.user?._id, params, true, (res) => {
        this.setState({ loading: false });
      });
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({
        loading: false,
        loadingMore: false,
        page: 1,
      });
    }

    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();

    if (this.props.getTelegram?.teammates?.length < this.props.getTelegram?.teammatesTotalCount) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  onRefChange = (node, i) => {
    const { teammates, teammatesTotalCount } = this.props.getTelegram;

    if (i === teammates?.length - 1 && teammates?.length < teammatesTotalCount) {
      this.scrollRef.current = node;
    }
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;
    const { teammates, teammatesTotalCount } = this.props.getTelegram;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>Team {!loading && `(${teammates?.length ?? 0})`}</h5>
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="td-img round-img m-right-2 pointer">
                  <img src={user?.image?.small ? process.env.REACT_APP_CDN + user.image.small : userIcon} alt="" />
                </div>
                {user?.firstName} {user?.lastName}
                <br />
                {user?.userName}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {teammates?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Users</th>
                            <th>Country</th>
                            <th>Balance</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teammates?.map((teammate, i) => (
                            <tr key={teammate?._id} ref={(node) => this.onRefChange(node, i)}>
                              <td className="td-img-group d-flex align-items-start ">
                                <div className="td-img round-img m-right-2">
                                  {teammate?.image ? (
                                    <img src={process.env.REACT_APP_CDN + teammate?.image.small} alt="" />
                                  ) : (
                                    <img src={userIcon} alt="" />
                                  )}
                                </div>

                                <div>
                                  {teammate?.firstName} {teammate?.lastName}
                                  <br />
                                  {teammate?.userName} <br />
                                  {teammate?.telegramId}
                                </div>
                              </td>
                              <td>
                                <div className="td-text-box">{teammate?.country ?? "NA"}</div>
                              </td>{" "}
                              <td>
                                <div>
                                  {teammate?.points?.toLocaleString("en-us")} Points
                                  <br />
                                  {teammate?.usdtBalance?.toLocaleString("en-us")} USDT
                                </div>
                              </td>{" "}
                              <td>
                                <div className="td-text-box">
                                  {moment(teammate.updatedAt).format("MM/DD/YYYY hh:mm a")}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadTeammates: (id, params, fresh, callback) => dispatch(loadTeammates(id, params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
