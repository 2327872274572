import Amplify, { Auth, Storage } from "aws-amplify";
import moment from "moment";
import { createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Joi from "joi-browser";

//  Images
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import filterIcon from "include/images/filter-icon.svg";
import closeIcon from "include/images/close-icon.svg";
import carrentIcon from "include/images/carrent-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { addGearsCsv, deleteGearSubType, getTelegram, loadGearsSubTypeCsv } from "store/telegram";
import CsvListingsFlyer from "../flyers/csvListingsFlyer";
import { isEmpty, pickBy, startCase } from "lodash";
import NextButton from "common/form/submitButton";
import SortIcon from "common/sortIcon";
import SelectSearch from "common/form/selectSearch";
import AddGearSubTypeFlyer from "./addGearSubTypeFlyer";
import { Dropdown } from "react-bootstrap";
import DeleteModal from "common/alert/alertModal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class GearsCsvList extends Form {
  ref = createRef(null);
  initialState = {
    loading: false,
    loadingFilters: false,
    data: {
      name: null,
    },
    appliedFilters: {},
    errors: {},
    editData: null,
    sorting: { sort: "createdAt", order: "desc" },
    showFilters: false,
    page: 1,
    showDeleteModal: false,
    showAddSubTypeModal: false,
  };
  state = { ...structuredClone(this.initialState) };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.searchFilters();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.gearsSubTypeLoading &&
      this.props.getTelegram.gearsSubTypeLoading !== prevProps.getTelegram.gearsSubTypeLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.gearsSubTypeLoading &&
      this.props.getTelegram.gearsSubTypeLoading !== prevProps.getTelegram.gearsSubTypeLoading
    )
      this.ref.current.continuousStart();

    if (this.state.showAddSubTypeModal !== prevState.showAddSubTypeModal && !this.state.showAddSubTypeModal) {
      this.setState({ editData: null });
    }
  };
  searchFilters = () => {
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const payLoad = {
      name: data?.name?.value,
      sort: sorting?.sort,
      order: sorting?.order,
    };
    const params = pickBy(payLoad, function (value, key) {
      return value !== "";
    });

    params.page = this.state.page;
    this.setState({ loadingFilters: true });
    this.props.loadGearsSubTypeCsv(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
        });
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };
  handleCsvUpload = (e) => {
    this.ref.current.continuousStart();
    const fType = e.target.files[0].type;
    const fName = e.target.files[0].name;
    const ext = e.target.files[0].name.split(".").pop();
    if (!["text/csv"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid format is csv only" />,
        {
          containerId: 1,
        },
      );
      this.ref.current.complete();
      e.target.value = null;
      return;
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put("telegramBattleGame/csv/gear/" + fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        const data = { fileName: fName, filePath: "public/" + result.key };
        this.props.addGearsCsv(data, (response) => {
          this.ref.current.complete();

          e.target.value = null;
          if (response.status === 200) {
            this.setState({ page: 1 }, () => this.searchFilters());
            toast(<AlertSuccess message="Information Saved" />, {
              containerId: 1,
            });
          } else {
            toast(<AlertError message={response.data && response.data.message} />, {
              containerId: 1,
            });
          }
        });
      });
    }
  };
  toggleAddSubTypeModal = () => {
    this.setState({ showAddSubTypeModal: !this.state.showAddSubTypeModal });
  };
  subTypeOptions = ["Sword", "Hammer", "Axe", "Dagger", "Bow", "Crossbow", "Wand"];
  schema = {
    name: Joi.object().allow(null),
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState({ ...structuredClone(this.initialState), showFilters: true }, () => this.searchFilters());
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    sorting.order = sorting?.sort === type && sorting.order === "desc" ? "asc" : "desc";
    sorting.sort = type;

    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteGearSubType(this.state.deleteId, (res) => {
      if (res?.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingDelete: false });
    });
  };

  render() {
    const { gearsSubType, gearsSubTypeTotalCount } = this.props.getTelegram;
    const completedSubTypeOptions = gearsSubType?.map((value) => value?.name);

    const remainingSubTypeOptions = this.subTypeOptions?.filter((value) => !completedSubTypeOptions?.includes(value));
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-battleGame-gear-subtype" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Battle Game Gears Subtypes {gearsSubTypeTotalCount > 0 && `(${gearsSubTypeTotalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} alt="filter" /> <span>Filters</span>
                  </a>
                </div>
                {
                  <div className="fmb-box">
                    <Link
                      to="#"
                      className={"btn btn-default btn-sm" + (remainingSubTypeOptions?.length ? "" : " disabled")}
                      onClick={this.toggleAddSubTypeModal}
                    >
                      <img src={plusIcon} alt="" /> Add Gear Subtype
                    </Link>
                  </div>
                }
              </div>
            </div>
            {!this.state.loading && (
              <div className="fwc-wrapper">
                {this.state.showFilters && (
                  <div className="fwc-head ">
                    <div className="fwc-inner">
                      <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Sub Type</label>
                            <SelectSearch
                              name="name"
                              options={this.subTypeOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.name}
                              error={this.state.errors?.name}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                      </ul>
                      <div className="fwc-btn d-flex align-items-center justify-content-end">
                        <NextButton
                          handleSubmit={this.resetFilters}
                          classData="btn-text pointer"
                          label="Reset"
                          loading={this.state.loadingReset}
                        />
                        <NextButton
                          handleSubmit={() => {
                            this.setState(
                              {
                                page: 1,
                              },
                              () => this.searchFilters(),
                            );
                          }}
                          classData="btn btn-primary btn-sm"
                          label="Search"
                          loading={this.state.loadingFilters}
                        />
                      </div>
                    </div>{" "}
                    {!isEmpty(this.state.appliedFilters) && (
                      <div className="filter-tag-container d-flex align-items-center ">
                        <div className="fwc-left">
                          <div className="filter-tag-list">
                            <p>filter applied :</p>
                            {Object.keys(this.state.appliedFilters).map((key) => (
                              <div className="filter-tag-item">
                                <span className="camelCase">{startCase(key)}</span> :{" "}
                                {this.state.appliedFilters[key]?.value ?? this.state.appliedFilters[key]}
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      {
                                        data: { ...this.state.data, [key]: "" },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="fwc-body">
                  <div className="table-responsive">
                    {gearsSubType?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>Actions</th>

                            <th>
                              Sub Type
                              <SortIcon
                                sortIconType="name"
                                handleSort={(e) => this.sort(e, "name")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>Description</th>
                            <th>Image Name</th>
                            <th>
                              Date
                              <SortIcon
                                sortIconType="createdAt"
                                handleSort={(e) => this.sort(e, "createdAt")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {gearsSubType?.map((subtype, i) => (
                            <tr key={i}>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ editData: subtype }, () => {
                                          this.toggleAddSubTypeModal();
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>{" "}
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ deleteId: subtype?._id }, () => {
                                          this.alertModalToggle(e);
                                        });
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{subtype?.name}</td>
                              <td>{subtype?.description}</td>
                              <td>
                                <div style={{ width: 60 }}>
                                  {subtype?.image && <img src={process.env.REACT_APP_CDN + subtype?.image} alt="" />}
                                </div>
                              </td>

                              <td>{moment(subtype?.createdAt).format("ll")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {!this.state.loading && !(this.state.page === 1 && gearsSubType?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {gearsSubType?.length >= 100 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${gearsSubType?.length < 100 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <AddGearSubTypeFlyer
          showModal={this.state.showAddSubTypeModal}
          toggleModal={this.toggleAddSubTypeModal}
          remainingSubTypeOptions={remainingSubTypeOptions}
          edit={this.state.editData}
          reload={() => {
            this.setState({ page: 1 }, this.searchFilters);
          }}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this gear subtype?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        {this.state.showAddSubTypeModal && (
          <div className="modal-backdrop show fade" onClick={this.toggleAddSubTypeModal}></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadGearsSubTypeCsv: (params, callback) => dispatch(loadGearsSubTypeCsv(params, callback)),
  deleteGearSubType: (data, callback) => dispatch(deleteGearSubType(data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GearsCsvList));
