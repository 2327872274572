import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import { isEmpty, pickBy, startCase } from "lodash";
import moment from "moment";
import { createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { addEnemiesCsv, getTelegram, loadEnemiesCsv } from "store/telegram";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import SortIcon from "common/sortIcon";
import CsvListingsFlyer from "../flyers/csvListingsFlyer";
import SelectSearch from "common/form/selectSearch";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class EnemiesCsvList extends Form {
  ref = createRef(null);
  initialState = {
    loading: false,
    loadingFilters: false,
    data: {
      name: "",
      type: null,
      subType: null,
      element: null,
      health: "",
      armor: "",
      damage: "",
      critChance: "",
      critDamage: "",
      inventoryId: "",
      gearId1: "",
      gearId2: "",
      gearId3: "",
      gearId4: "",
      skillId: "",
      energy: "",
      status: "",
    },
    appliedFilters: {},
    errors: {},
    sorting: { sort: "createdAt", order: "desc" },
    showFilters: false,
    page: 1,
    showCsvModal: false,
  };
  state = { ...structuredClone(this.initialState) };
  componentDidMount = () => {
    this.setState({ loading: true });
    this.searchFilters();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.enemiesCsvLoading &&
      this.props.getTelegram.enemiesCsvLoading !== prevProps.getTelegram.enemiesCsvLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.enemiesCsvLoading &&
      this.props.getTelegram.enemiesCsvLoading !== prevProps.getTelegram.enemiesCsvLoading
    )
      this.ref.current.continuousStart();
  };
  searchFilters = () => {
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const payLoad = {
      ...data,
      type: data?.type?.value,
      subType: data?.subType?.value,
      element: data?.element?.value,
      sort: sorting?.sort,
      order: sorting?.order,
    };
    const params = pickBy(payLoad, function (value, key) {
      return value !== "";
    });

    params.page = this.state.page;
    this.setState({ loadingFilters: true });
    this.props.loadEnemiesCsv(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
        });
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };
  handleCsvUpload = (e) => {
    this.ref.current.continuousStart();
    const fType = e.target.files[0].type;
    const fName = e.target.files[0].name;
    const ext = e.target.files[0].name.split(".").pop();
    if (!["text/csv"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid format is csv only" />,
        {
          containerId: 1,
        },
      );
      this.ref.current.complete();
      e.target.value = null;
      return;
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put("telegramBattleGame/csv/enemy/" + fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        const data = { fileName: fName, filePath: "public/" + result.key };
        this.props.addEnemiesCsv(data, (response) => {
          this.ref.current.complete();

          e.target.value = null;
          if (response.status === 200) {
            this.setState({ page: 1 }, () => this.searchFilters());

            toast(<AlertSuccess message="Information Saved" />, {
              containerId: 1,
            });
          } else {
            toast(<AlertError message={response.data && response.data.message} />, {
              containerId: 1,
            });
          }
        });
      });
    }
  };
  toggleShowCsvModal = () => {
    this.setState({ showCsvModal: !this.state.showCsvModal });
  };
  typeOptions = ["Minion", "Mini-Boss", "Boss"];
  elementOptions = ["Water", "Fire", "Earth", "Air", "Neutral"];
  subTypeOptions = ["Easy", "Medium", "Hard", "Very Hard"];
  schema = {
    name: Joi.string().allow(""),
    type: Joi.object().allow(null),
    subType: Joi.object().allow(null),
    element: Joi.object().allow(null),
    health: Joi.number().min(0.1).allow(""),
    armor: Joi.number().min(0.1).allow(""),
    damage: Joi.number().min(0.1).allow(""),
    critChance: Joi.number().min(0.1).allow(""),
    critDamage: Joi.number().min(0.1).allow(""),
    inventoryId: Joi.number().allow(""),
    gearId1: Joi.number().allow(""),
    gearId2: Joi.number().allow(""),
    gearId3: Joi.number().allow(""),
    gearId4: Joi.number().allow(""),
    skillId: Joi.number().allow(""),
    energy: Joi.number().allow(""),
    status: Joi.string().allow(""),
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState({ ...structuredClone(this.initialState), showFilters: true }, () => this.searchFilters());
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    sorting.order = sorting?.sort === type && sorting.order === "desc" ? "asc" : "desc";
    sorting.sort = type;

    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { enemiesCsv, enemiesCsvTotalCount } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-battleGame-enemies" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Battle Game Enemies {enemiesCsvTotalCount > 0 && `(${enemiesCsvTotalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    className="colored-link orange-text"
                    href={process.env.REACT_APP_CDN + "telegramBattleGame/sampleCsv/enemy.csv"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Sample CSV
                  </a>{" "}
                </div>
                <div className="fmb-box">
                  <Link className="colored-link orange-text" to="#" onClick={this.toggleShowCsvModal}>
                    CSV logs
                  </Link>{" "}
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <div className="kt-file-input">
                    <label htmlFor="formFile" className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Import CSV
                    </label>
                    <input className="form-control" type="file" id="formFile" onChange={this.handleCsvUpload} />
                  </div>
                </div>
              </div>
            </div>
            {!this.state.loading && (
              <div className="fwc-wrapper">
                {this.state.showFilters && (
                  <div className="fwc-head ">
                    <div className="fwc-inner">
                      <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("name", "Name")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Type</label>
                            <SelectSearch
                              name="type"
                              options={this.typeOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.type}
                              error={this.state.errors?.type}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Sub Type</label>
                            <SelectSearch
                              name="subType"
                              options={this.subTypeOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.subType}
                              error={this.state.errors?.subType}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>{" "}
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Element</label>
                            <SelectSearch
                              name="element"
                              options={this.elementOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.element}
                              error={this.state.errors?.element}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("health", "Health", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("armor", "Armor", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("damage", "Damage", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("critChance", "Crit Chance", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("critDamage", "Crit Damage", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("inventoryId", "Inventory ID", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("gearId1", "Gear ID 1", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("gearId2", "Gear ID 2", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("gearId3", "Gear ID 3", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("gearId4", "Gear ID 4", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("skillId", "Skill ID", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("energy", "Energy", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("status", "Status")}</div>
                        </li>
                      </ul>
                      <div className="fwc-btn d-flex align-items-center justify-content-end">
                        <NextButton
                          handleSubmit={this.resetFilters}
                          classData="btn-text pointer"
                          label="Reset"
                          loading={this.state.loadingReset}
                        />
                        <NextButton
                          handleSubmit={() => {
                            this.setState(
                              {
                                page: 1,
                              },
                              () => this.searchFilters(),
                            );
                          }}
                          classData="btn btn-primary btn-sm"
                          label="Search"
                          loading={this.state.loadingFilters}
                        />
                      </div>
                    </div>{" "}
                    {!isEmpty(this.state.appliedFilters) && (
                      <div className="filter-tag-container d-flex align-items-center ">
                        <div className="fwc-left">
                          <div className="filter-tag-list">
                            <p>Filter applied :</p>
                            {Object.keys(this.state.appliedFilters).map((key) => (
                              <div className="filter-tag-item">
                                <span className="camelCase">{startCase(key)}</span> :{" "}
                                {this.state.appliedFilters[key]?.value ?? this.state.appliedFilters[key]}
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="fwc-body">
                  <div className="table-responsive">
                    {enemiesCsv?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>
                              Character ID
                              <SortIcon
                                sortIconType="characterId"
                                handleSort={(e) => this.sort(e, "characterId")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Name
                              <SortIcon
                                sortIconType="name"
                                handleSort={(e) => this.sort(e, "name")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Type
                              <SortIcon
                                sortIconType="type"
                                handleSort={(e) => this.sort(e, "type")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Subtype
                              <SortIcon
                                sortIconType="subType"
                                handleSort={(e) => this.sort(e, "subType")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Element
                              <SortIcon
                                sortIconType="element"
                                handleSort={(e) => this.sort(e, "element")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Health
                              <SortIcon
                                sortIconType="health"
                                handleSort={(e) => this.sort(e, "health")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Armor
                              <SortIcon
                                sortIconType="armor"
                                handleSort={(e) => this.sort(e, "armor")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Damage
                              <SortIcon
                                sortIconType="damage"
                                handleSort={(e) => this.sort(e, "damage")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Crit Chance
                              <SortIcon
                                sortIconType="critChance"
                                handleSort={(e) => this.sort(e, "critChance")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Crit Damage
                              <SortIcon
                                sortIconType="cricDamage"
                                handleSort={(e) => this.sort(e, "cricDamage")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Inventory ID
                              <SortIcon
                                sortIconType="inventoryId"
                                handleSort={(e) => this.sort(e, "inventoryId")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Gear ID 1
                              <SortIcon
                                sortIconType="gearId1"
                                handleSort={(e) => this.sort(e, "gearId1")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Gear ID 2
                              <SortIcon
                                sortIconType="gearId2"
                                handleSort={(e) => this.sort(e, "gearId2")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Gear ID 3
                              <SortIcon
                                sortIconType="gearId3"
                                handleSort={(e) => this.sort(e, "gearId3")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Gear ID 4
                              <SortIcon
                                sortIconType="gearId4"
                                handleSort={(e) => this.sort(e, "gearId4")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Skill ID
                              <SortIcon
                                sortIconType="skillId"
                                handleSort={(e) => this.sort(e, "skillId")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Energy
                              <SortIcon
                                sortIconType="energy"
                                handleSort={(e) => this.sort(e, "energy")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>Image Name</th>
                            <th>
                              Status
                              <SortIcon
                                sortIconType="status"
                                handleSort={(e) => this.sort(e, "status")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Date
                              <SortIcon
                                sortIconType="createdAt"
                                handleSort={(e) => this.sort(e, "createdAt")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {enemiesCsv?.map((enemy, i) => (
                            <tr key={i} className={enemy?.status === "Deleted" ? "deleted-col" : ""}>
                              <td className="ps-3">{enemy?.characterId}</td>
                              <td>{enemy?.name}</td>
                              <td>{enemy?.type}</td>
                              <td>{enemy?.subType}</td>
                              <td>{enemy?.element}</td>
                              <td>{enemy?.health}</td>
                              <td>{enemy?.armor}</td>
                              <td>{enemy?.damage}</td>
                              <td>{enemy?.critChance}</td>
                              <td>{enemy?.critDamage}</td>
                              <td>{enemy?.inventoryId}</td>
                              <td>{enemy?.gearId1}</td>
                              <td>{enemy?.gearId2}</td>
                              <td>{enemy?.gearId3}</td>
                              <td>{enemy?.gearId4}</td>
                              <td>{enemy?.skillId}</td>
                              <td>{enemy?.energy}</td>
                              <td>
                                <div style={{ width: 60 }}>
                                  {enemy?.imageName && (
                                    <img
                                      src={
                                        process.env.REACT_APP_CDN + "telegramBattleGame/image/enemy/" + enemy?.imageName
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                              </td>
                              <td>{enemy?.status}</td>
                              <td>{moment(enemy?.createdAt).format("ll")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {!this.state.loading && !(this.state.page === 1 && enemiesCsv?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {enemiesCsv?.length >= 100 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${enemiesCsv?.length < 100 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}{" "}
                </div>
              </div>
            )}
          </div>
        </div>
        <CsvListingsFlyer type={"enemy"} showModal={this.state.showCsvModal} toggleModal={this.toggleShowCsvModal} />
        {this.state.showCsvModal && <div className="modal-backdrop show fade" onClick={this.toggleShowCsvModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadEnemiesCsv: (params, callback) => dispatch(loadEnemiesCsv(params, callback)),
  addEnemiesCsv: (data, callback) => dispatch(addEnemiesCsv(data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnemiesCsvList));
