import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getSettings } from "store/settings";
import {
  addDailyTournament,
  addQuest,
  getTelegram,
  loadDailyTournament,
  updateDailyTournament,
  updateQuest,
} from "store/telegram";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import SelectSearch from "common/form/selectSearch";
import { sortBy } from "lodash";
import { getGame, loadBGGames } from "store/games";
import { dateAsUtc } from "utils/dateConversion";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initState = {
    loading: false,
    data: {
      bgGame: {},
      numberOfEntries: 0,
      date: "",
      prizePool: 0,
      partnerLogo: null,
    },
    errors: {},
    partnerLogoChange: false,
    loadingPartnerLogo: false,
    edit: false,
  };
  state = { ...structuredClone(this.initState) };

  componentDidMount = () => {
    this.props.loadBGGames({ isActive: true, isComingSoon: false }, () => {
      this.setState({ bgGamesLoading: false });
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        const data = { ...this.state.data };
        if (this.props.edit) {
          data.date = this.props.edit.date;
          data.bgGame = {
            name: this.props.edit?.bgGame?.name,
            value: this.props.edit?.bgGame?.id,
            data: this.props.edit?.bgGame,
          };
          data.numberOfEntries = this.props.edit.numberOfEntries;
          data.prizePool = this.props.edit.prizePool;
          data.partnerLogo = this.props.edit.partnerLogo;
        }
        this.setState({
          data,
          edit: this.props.edit,
        });
      }
    }
  };
  handleResponse = (res) => {
    this.setState({ loading: false });
    if (res?.status === 200) {
      toast(<AlertSuccess message="Information Saved" />, { containerId: 1 });
      this.clearData();
      this.props.loadDailyTournament({});
      this.props.toggleModal();
    } else {
      toast(<AlertError message={res.data && res.data.message} />, { containerId: 1 });
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      bgGameId: data?.bgGame?.value,
      date: parseInt(dateAsUtc(data?.date, "x")),
      prizePool: parseInt(data?.prizePool),
      numberOfEntries: parseInt(data?.numberOfEntries),
    };

    if (this.state.partnerLogoChange) payLoad.partnerLogo = data.partnerLogo;

    if (this.props.edit) {
      this.props.updateDailyTournament(this.props.edit._id, payLoad, this.handleResponse);
    } else {
      this.props.addDailyTournament(payLoad, this.handleResponse);
    }
  };

  schema = {
    bgGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    }),
    numberOfEntries: Joi.number().required(),
    date: Joi.date().required(),
    partnerLogo: Joi.alternatives().try(Joi.string(), Joi.object()).required(),

    prizePool: Joi.number().required(),
  };
  clearData = () => {
    this.setState({ ...this.initState });
  };

  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingPartnerLogo: true });

    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();

    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingPartnerLogo: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingPartnerLogo: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          partnerLogoChange: true,
          loadingPartnerLogo: false,
          data: {
            ...this.state.data,
            partnerLogo: "public/" + result.key,
          },
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };

  onChangeSelect = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;

    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { bgGames } = this.props.getGame;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end  custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add a"} Daily Tournament</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="form-label">BG Game</label>
                </div>
                <SelectSearch
                  name="bgGame"
                  options={sortBy(bgGames, ["name"])
                    .filter((game) => game?.applicableForTelegramGame)
                    .map((game) => ({
                      name: game.name,
                      value: game.id,
                      data: game,
                    }))}
                  label="Select BG Game"
                  value={this.state.data.bgGame}
                  error={this.state.errors.bgGame}
                  onChange={this.onChangeSelect}
                  isLoading={this.state.bgGamesLoading}
                />
              </div>
            </div>

            <div className="new-form-group">
              <div className="row">{this.renderInput("prizePool", "Prize Pool (In Points)", "", "number")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderInput("numberOfEntries", "Max Number Of Entries", "", "number")}</div>
            </div>
            <div className="new-form-group">
              <div className="row"> {this.renderDateOnlyInput("date", "Date", new Date())}</div>
            </div>
            <div className="flex-wrap kt-upload-row d-flex align-items-start">
              <div
                className={`kt-upload-outer ${
                  !this.state.data.partnerLogo ? "placeholder-uploaded-img" : " position-relative"
                } ${this.state.errors.partnerLogo && "border-error"}`}
              >
                <div className="kt-upload-img">
                  <img
                    src={
                      this.state.data.partnerLogo
                        ? this.state.data.partnerLogo.includes("https://")
                          ? this.state.data.partnerLogo
                          : process.env.REACT_APP_CDN + this.state.data.partnerLogo
                        : plusIcon
                    }
                    alt=""
                  />
                </div>{" "}
                {this.state.data.partnerLogo && (
                  <div
                    className="delete-icon d-flex align-items-center justify-content-center"
                    onClick={(e) => {
                      const data = this.state.data;
                      data.partnerLogo = null;
                      this.setState({ data });
                    }}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </div>
                )}
              </div>
              <div className="kt-file-input">
                <label htmlFor="formFile" className="kt-label">
                  {this.state.data.partnerLogo ? " Change Partner Logo" : "+ Upload an Partner Logo"}
                </label>
                <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading || this.state.loadingPartnerLogo}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadDailyTournament: (params, callback) => dispatch(loadDailyTournament(params, callback)),

  addDailyTournament: (data, callback) => dispatch(addDailyTournament(data, callback)),
  updateDailyTournament: (id, data, callback) => dispatch(updateDailyTournament(id, data, callback)),
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
  getSettings: getSettings(state),
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
