import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import MultiSelectSearchAsync from "common/form/multiSelectSearchAsync";
import NextButton from "common/form/submitButton";
import { addBattleGame, addGameImages, getTelegram, loadGearsCsv, updateBattleGame } from "store/telegram";

class AddGameFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    loading: false,
    loadingGears: false,
    data: {
      name: "",
      startTime: "",
      endTime: "",
      gears: [],
    },
    errors: {},
  };
  state = { ...structuredClone(this.initialState) };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      if (!this.props.showModal) this.clearData();
      else {
        if (this.props.edit) {
          console.log(this.props.edit);
          this.setState({
            data: {
              name: this.props.edit.name,
              startTime: new Date(this.props.edit?.startTime),
              endTime: this.props.edit.endTime ? new Date(this.props.edit?.endTime) : "",
              gears: this.props.edit.defaultGear?.map((gear) => ({
                name: gear?.name,
                value: gear._id,
                data: gear,
              })),
            },
          });
        }
      }
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };

    this.setState({ loading: true });
    const payLoad = {
      name: data.name,
      startTime: parseInt(moment(data.startTime).format("x")),
      endTime: parseInt(moment(data.endTime).format("x")),
      gears: data.gears.map((gear) => gear?.value),
    };
    if (this.props.edit) {
      this.props.updateBattleGame(this.props.edit?._id, payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.reload();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addBattleGame(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.reload();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.string().required(),
    startTime: Joi.date().required(),
    endTime: Joi.date().required(),
    gears: Joi.array()
      .min(4)
      .items(
        Joi.object().keys({
          data: Joi.object().allow(null),
          name: Joi.string().allow(""),
          value: Joi.string().required(),
        }),
      ),
  };
  clearData = () => {
    this.setState({ ...structuredClone(this.initialState) });
  };
  getEndDate = () => {
    var startTime = new Date(this.state.data.startTime);
    var endTime = "";
    if (startTime) endTime = new Date(startTime.getTime());
    else endTime = new Date();
    return endTime;
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  loadOptions = (inputValue, callback) => {
    this.setState({ loadingGears: true });
    this.props.loadGearsCsv({ name: inputValue, sort: "name", order: "asc" }, (res) => {
      if (res?.status === 200) {
        callback(
          res?.data?.data?.map((s, i) => ({
            name: s?.name,
            value: s?._id,
            data: s,
          })),
        );
      }
      this.setState({ loadingGears: false });
    });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>{this.props.edit ? "Edit " : "Add a"} Battle Game</h5>{" "}
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">{this.renderInput("name", "Name")}</div>
            </div>
            <div className="new-form-group">
              <div className="row">
                {this.renderDateInput("startTime", "Start Date & Time", new Date(), false, true)}
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">
                {this.renderDateInput("endTime", "End Date & Time", this.getEndDate(), false, true)}
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="new-form-group">
                  <label className="form-label">Gears</label>
                  <MultiSelectSearchAsync
                    name="gears"
                    label="Select Gear"
                    value={this.state?.data?.gears}
                    error={this.state?.errors?.gears}
                    onChange={this.onChange}
                    isLoading={this.state.loadingGears}
                    loadOptions={this.loadOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Send"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
const mapDispatchToProps = (dispatch) => ({
  addBattleGame: (data, callback) => dispatch(addBattleGame(data, callback)),
  updateBattleGame: (id, data, callback) => dispatch(updateBattleGame(id, data, callback)),

  loadGearsCsv: (params, callback) => dispatch(loadGearsCsv(params, callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGameFlyer));
