import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Amplify, { Auth, Storage } from "aws-amplify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import placeholderJson from "include/json/placeholder-img.json";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Lottie from "react-lottie-player";
import { addGearSubType, updateGearSubType } from "store/telegram";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddGearSubTypeFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      name: null,
      description: "",
      image: "",
    },
    errors: {},
    loadingImage: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      if (this.props.edit) {
        this.setState({
          data: {
            name: { name: this.props.edit.name, value: this.props.edit.name },
            description: this.props.edit.description,
            image: this.props.edit.image,
          },
        });
      }
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };

    this.setState({ loading: true });

    const payLoad = {
      name: data?.name?.value,
      description: data.description,
      image: data.image,
    };
    if (this.props.edit) {
      this.props.updateGearSubType(this.props.edit?._id, payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.reload();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addGearSubType(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.reload();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.object().required(),
    description: Joi.string().required(),
    image: Joi.string().required(),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        name: null,
        description: "",
        image: "",
      },
      errors: {},
    });
  };

  handleImageChange = (e) => {
    this.setState({ loadingImage: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImage: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImage: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          data: {
            ...this.state.data,
            image: "public/" + result.key,
          },
          loadingImage: false,
        });
      });
    }
    e.target.value = null;
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>Battle Game Gear Subtype</h5>{" "}
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <label className="form-label">Sub Type</label>
                <SelectSearch
                  name="name"
                  options={this.props.remainingSubTypeOptions?.map((value) => ({ name: value, value }))}
                  label=""
                  value={this.state.data?.name}
                  error={this.state.errors?.name}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">{this.renderTextarea("description", "Description")}</div>
            </div>{" "}
            <div className="new-form-group">
              <div className="kt-upload-row d-flex flex-wrap align-items-start">
                {!this.state.loadingImage ? (
                  <div
                    className={`kt-upload-outer ${
                      !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                    } ${this.state.errors.image && "border-error"}`}
                  >
                    <div className="kt-upload-img" htmlFor="uploadImg">
                      <img
                        src={this.state.data.image ? process.env.REACT_APP_CDN + this.state.data.image : plusIcon}
                        alt=""
                      />
                    </div>
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = this.state.data;
                        data.image = "";
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} />
                    </div>
                  </div>
                ) : (
                  <div className="kt-upload-outer position-relative loading">
                    <div className="lottie-player-box">
                      <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                    </div>
                  </div>
                )}
                <div className="kt-file-input">
                  <label htmlFor="formFile" className="kt-label" id="uploadImg">
                    {this.state.data.image ? " Change Image" : "+ Upload Image"}
                  </label>{" "}
                  <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Send"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addGearSubType: (data, callback) => dispatch(addGearSubType(data, callback)),
  updateGearSubType: (id, data, callback) => dispatch(updateGearSubType(id, data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddGearSubTypeFlyer));
