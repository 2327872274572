import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addBGGame, getGame, updateBGGame, loadBGGames } from "store/games";
import { getSettings, loadSettings, loadHowItWorksTemplates } from "store/settings";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";
import Lottie from "react-lottie-player";
import { v4 as uuidv4 } from "uuid";
import Amplify, { Auth, Storage } from "aws-amplify";

//  Images
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";
import placeholderJson from "include/json/placeholder-img.json";

//  Components
import Header from "common/header";
import SideBar from "common/sidebar";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import AlertSuccess from "common/alert/alertSuccess";
import Loader from "common/loader";
import AlertError from "common/alert/alertError";

//  Amplify Configuration
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddBGGame extends Form {
  ref = createRef(null);
  state = {
    data: {
      name: "",
      subtitle: "",
      gameEndDelay: "",
      url: "",
      externalDemoURL: "",
      telegramGameURL: "",
      category: {},
      description: "",
      desktopImage: null,
      privateDesktopImage: null,
      mobileImage: null,
      privateMobileImage: null,
      thumbnailImage: null,
      telegramAppImage: null,
      telegramMissionImage: null,
      socialLeaderBoardImage: null,
      scoringType: {},
      isWalletRestrictionEnabled: { name: "No", value: false },
      applicableForExternalDemo: { name: "No", value: false },
      applicableForTelegramGame: { name: "No", value: false },
      missionCompletedRewardType: { name: "USDT", value: 2 },
      gameType: { name: "Skill Based Game", value: 0, data: null },
      isAccessPassRestrictionEnabled: { name: "No", value: false },
      displayOrder: null,
      howItWorksTemplate: {},
      maxTimeSpan: "",
      contractAddress: "",
      gameEncryptionKey: "",
      timer: false,
      score: false,
      applicableForBoardGame: { name: "No", value: false },
      levelOneScore: 0,
      levelOneMultiplier: 0,
      levelOnePoints: 0,
      levelTwoScore: 0,
      levelTwoMultiplier: 0,
      levelTwoPoints: 0,
      levelThreeScore: 0,
      levelThreeMultiplier: 0,
      levelThreePoints: 0,
      levelFourScore: 0,
      levelFourMultiplier: 0,
      levelFourPoints: 0,
      missionCompletedReward: 0,
    },
    id: "",
    edit: false,
    errors: {},
    loadingBGGame: false,
    loadingDesktopImage: false,
    loadingPrivateDesktopImage: false,
    loadingMobileImage: false,
    loadingPrivateMobileImage: false,
    loadingTelegramMissionImage: false,
    loadingThumbnailImage: false,
    loadingTelegramAppImage: false,
    loadingSocialLeaderBoardImage: false,
    settingsLoading: false,
    mobileImageChange: false,
    privateMobileImageChange: false,
    telegramMissionImageChange: false,
    thumbnailImageChange: false,
    telegramAppImageChange: false,
    desktopImageChange: false,
    privateDesktopImageChange: false,
    socialLeaderBoardImageChange: false,
  };
  gameTypeOptions = [
    { name: "Skill Based Game", value: 0, data: null },
    { name: "Luck Based Live Game", value: 1, data: null },
    { name: "Luck Based Slot Game", value: 2, data: null },
  ];
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.loadOptionSettings();
    const data = { ...this.state.data };
    this.props.loadHowItWorksTemplates((res) => {
      if (res.status === 200 && this.props?.location?.state) {
        const game = this.props.location.state.game;
        const temp = res.data.data.find((item) => item.id === game.howItWorksTemplateId);
        data.howItWorksTemplate = {
          name: temp.title,
          value: temp.id,
          data: temp,
        };
      }
    });
    if (this.props.location.state) {
      const game = this.props.location.state.game;
      data.name = game.name;
      const { settings } = this.props.getSettings;
      const categoryList = settings?.length > 0 ? structuredClone(settings[0]?.question?.categories) : [];
      const cat = categoryList.find((c) => c.name === game.category);
      data.category = cat
        ? {
            name: cat.name,
            value: cat.name,
            data: cat,
          }
        : {};
      data.score = game?.otherGameDetails?.score;
      data.timer = game?.otherGameDetails?.timer;
      data.scoringType = game?.scoringType
        ? {
            name: game?.scoringType === 1 ? "Round Based Scoring Pattern" : "Normal Scoring Pattern",
            value: game?.scoringType,
            data: null,
          }
        : {};
      data.isAccessPassRestrictionEnabled = {
        name: game?.isAccessPassRestrictionEnabled ? "Yes" : "No",
        value: game?.isAccessPassRestrictionEnabled,
        data: null,
      };
      data.isWalletRestrictionEnabled = {
        name: game?.isWalletRestrictionEnabled ? "Yes" : "No",
        value: game?.isWalletRestrictionEnabled,
        data: null,
      };
      data.url = game.url;
      data.externalDemoURL = game.externalDemoURL ?? "";
      data.telegramGameURL = game.telegramGameURL ?? "";
      data.applicableForExternalDemo = {
        name: game?.applicableForExternalDemo ? "Yes" : "No",
        value: game?.applicableForExternalDemo ?? false,
        data: null,
      };
      data.applicableForTelegramGame = {
        name: game?.applicableForTelegramGame ? "Yes" : "No",
        value: game?.applicableForTelegramGame ?? false,
        data: null,
      };
      data.applicableForBoardGame = {
        name: game?.applicableForBoardGame ? "Yes" : "No",
        value: game?.applicableForBoardGame ?? false,
        data: null,
      };
      data.subtitle = game.subtitle;
      data.gameEndDelay = `${game.gameEndDelay}` ?? "";
      data.maxTimeSpan = game.maxTimeSpan ? `${game.maxTimeSpan}` : "";
      data.contractAddress = game.contractAddress ? `${game.contractAddress}` : "";
      data.gameEncryptionKey = game.gameEncryptionKey ? `${game.gameEncryptionKey}` : "";
      data.mobileImage = game.mobileImage;
      data.privateMobileImage = game.privateMobileImage;
      data.telegramMissionImage = game.telegramMissionImage;
      data.thumbnailImage = { original: game.thumbnailImage };
      data.telegramAppImage = { original: game.telegramAppImage };

      data.privateDesktopImage = game.privateDesktopImage;
      data.socialLeaderBoardImage = game?.socialLeaderBoardImage;
      data.desktopImage = game.desktopImage;
      data.description = game.description;
      if (game?.displayOrder) data.displayOrder = { name: `${game?.displayOrder}`, value: game?.displayOrder };
      data.gameType = this.gameTypeOptions[game?.gameType ?? 0];
      if (game.levelThresholds) {
        data.levelOneScore = game.levelThresholds?.one?.score;
        data.levelOneMultiplier = game.levelThresholds?.one?.multiplier ?? 0;
        data.levelOnePoints = game.levelThresholds?.one?.points;
        data.levelTwoScore = game.levelThresholds?.two?.score;
        data.levelTwoMultiplier = game.levelThresholds?.two?.multiplier ?? 0;
        data.levelTwoPoints = game.levelThresholds?.two?.points;
        data.levelThreeScore = game.levelThresholds?.three?.score;
        data.levelThreeMultiplier = game.levelThresholds?.three?.multiplier ?? 0;
        data.levelThreePoints = game.levelThresholds?.three?.points;
        data.levelFourScore = game.levelThresholds?.four?.score;
        data.levelFourMultiplier = game.levelThresholds?.four?.multiplier ?? 0;
        data.levelFourPoints = game.levelThresholds?.four?.points;
      }
      data.missionCompletedReward = game?.missionCompletedReward ?? 0;
      this.setState({ data, edit: true, id: game.id });
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingBGGame: true });
    const {
      name,
      description,
      category,
      desktopImage,
      privateDesktopImage,
      mobileImage,
      privateMobileImage,
      telegramMissionImage,
      url,
      externalDemoURL,
      telegramGameURL,
      subtitle,
      gameEndDelay,
      scoringType,
      maxTimeSpan,
      contractAddress,
      gameEncryptionKey,
      howItWorksTemplate,
      isWalletRestrictionEnabled,
      applicableForExternalDemo,
      applicableForTelegramGame,
      isAccessPassRestrictionEnabled,
      thumbnailImage,
      telegramAppImage,
      socialLeaderBoardImage,
      timer,
      score,
      displayOrder,
      gameType,
      applicableForBoardGame,
      levelOneScore,
      levelOneMultiplier,
      levelOnePoints,
      levelTwoScore,
      levelTwoMultiplier,
      levelTwoPoints,
      levelThreeScore,
      levelThreeMultiplier,
      levelThreePoints,
      levelFourScore,
      levelFourMultiplier,
      levelFourPoints,
      missionCompletedRewardType,
      missionCompletedReward,
    } = this.state.data;
    const payLoad = {
      name,
      description,
      category: category.value ?? "",
      url,
      externalDemoURL,
      telegramGameURL,
      subtitle,
      gameEndDelay: parseInt(gameEndDelay),
      scoringType: scoringType.value ?? "",
      isAccessPassRestrictionEnabled: isAccessPassRestrictionEnabled.value,
      isWalletRestrictionEnabled: isWalletRestrictionEnabled.value,
      applicableForExternalDemo: applicableForExternalDemo.value,
      applicableForTelegramGame: applicableForTelegramGame.value,
      applicableForBoardGame: applicableForBoardGame.value,
      gameType: gameType?.value,
      thumbnailImage: thumbnailImage.original,
      telegramAppImage: telegramAppImage?.original,
      privateDesktopImage:
        this.state.edit && !this.state.privateDesktopImageChange ? privateDesktopImage : privateDesktopImage?.original,
      privateMobileImage:
        this.state.edit && !this.state.privateMobileImageChange ? privateMobileImage : privateMobileImage?.original,
      telegramMissionImage:
        this.state.edit && !this.state.telegramMissionImageChange
          ? telegramMissionImage
          : telegramMissionImage?.original,
      socialLeaderBoardImage:
        this.state.edit && !this.state.socialLeaderBoardImageChange
          ? socialLeaderBoardImage
          : socialLeaderBoardImage?.original,
      contractAddress,
      gameEncryptionKey,
      otherGameDetails: {
        timer,
        score,
      },
    };
    if (displayOrder?.value) payLoad.displayOrder = displayOrder?.value;
    if (this.state.edit || howItWorksTemplate.value) {
      payLoad.howItWorksTemplateId = howItWorksTemplate.value ?? null;
    }
    if (maxTimeSpan) payLoad.maxTimeSpan = parseInt(maxTimeSpan);
    if (this.state.edit) {
      if (this.state.mobileImageChange) payLoad.mobileImage = mobileImage.original;
      else payLoad.mobileImage = mobileImage;
      if (this.state.desktopImageChange) payLoad.desktopImage = desktopImage.original;
      else payLoad.desktopImage = desktopImage;
    } else {
      payLoad.mobileImage = mobileImage.original;
      payLoad.desktopImage = desktopImage.original;
    }
    payLoad.levelThresholds = {
      one: {
        score: parseInt(levelOneScore),
        multiplier: parseFloat(levelOneMultiplier),
        points: parseInt(levelOnePoints),
      },
      two: {
        score: parseInt(levelTwoScore),
        multiplier: parseFloat(levelTwoMultiplier),
        points: parseInt(levelTwoPoints),
      },
      three: {
        score: parseInt(levelThreeScore),
        multiplier: parseFloat(levelThreeMultiplier),
        points: parseInt(levelThreePoints),
      },
      four: {
        score: parseInt(levelFourScore),
        multiplier: parseFloat(levelFourMultiplier),
        points: parseInt(levelFourPoints),
      },
    };
    payLoad.missionCompletedRewardType = missionCompletedRewardType?.value;
    payLoad.missionCompletedReward = parseFloat(missionCompletedReward);
    if (this.state.edit) {
      this.props.updateBGGame(payLoad, this.state.id, (gameRes) => {
        this.setState({ loadingBGGame: false });
        if (gameRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          setTimeout(() => {
            this.props.history.push("/burn-ghost-games");
          }, 1000);
        } else {
          toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addBGGame(payLoad, (bgGameRes) => {
        this.setState({
          loadingBGGame: false,
        });
        if (bgGameRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          setTimeout(() => {
            this.props.history.push("burn-ghost-games");
          }, 1000);
        } else {
          toast(<AlertError message={bgGameRes.data && bgGameRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.string()
      .max(500)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Name is required.";
              break;
            case "string.max":
              err.message = "Title cannot be longer than 500 characters.";
              break;
          }
        });
        return errors;
      }),

    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Description is required.";
              break;
          }
        });
        return errors;
      }),
    category: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow([""]),
    }),
    timer: Joi.boolean().allow(false),
    score: Joi.boolean().allow(false),
    scoringType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
    isWalletRestrictionEnabled: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),

      value: Joi.boolean().allow(false),
    }),
    applicableForExternalDemo: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.boolean().allow(false),
    }),
    applicableForTelegramGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.boolean().allow(false),
    }),
    applicableForBoardGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.boolean().allow(false),
    }),
    isAccessPassRestrictionEnabled: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.boolean().allow(false),
    }),
    howItWorksTemplate: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow(""),
    }),
    maxTimeSpan: Joi.when("scoringType.value", {
      is: 2,
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    missionCompletedRewardType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().allow(""),
    }),
    contractAddress: Joi.string().allow(""),
    gameEncryptionKey: Joi.string().allow(""),
    url: Joi.string().allow(""),
    externalDemoURL: Joi.string().allow(""),
    telegramGameURL: Joi.string().allow(""),
    subtitle: Joi.string().allow(""),
    gameEndDelay: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    gameType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().allow(""),
    }),
    levelOneScore: Joi.number().allow(""),
    levelOneMultiplier: Joi.number().allow(""),
    levelOnePoints: Joi.number().allow(""),
    levelTwoScore: Joi.number().allow(""),
    levelTwoMultiplier: Joi.number().allow(""),
    levelTwoPoints: Joi.number().allow(""),
    levelThreeScore: Joi.number().allow(""),
    levelThreeMultiplier: Joi.number().allow(""),
    levelThreePoints: Joi.number().allow(""),
    levelFourScore: Joi.number().allow(""),
    levelFourMultiplier: Joi.number().allow(""),
    levelFourPoints: Joi.number().allow(""),
    missionCompletedReward: Joi.number().allow(""),
    mobileImage: Joi.object()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Mobile Image is required.";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    thumbnailImage: Joi.object().required(),
    telegramAppImage: Joi.object().required(),
    privateDesktopImage: Joi.object().allow([{}, null]),
    privateMobileImage: Joi.object().allow([{}, null]),
    telegramMissionImage: Joi.object().allow([{}, null]),
    socialLeaderBoardImage: Joi.object().allow([{}, null]),
    desktopImage: Joi.object()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Desktop Image is required.";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    displayOrder: Joi.object().allow([{}, null]),
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    if (e.name === "scoringType") {
      data.maxTimeSpan = "";
    }
    if (e.name === "isWalletRestrictionEnabled") data.isAccessPassRestrictionEnabled = { name: "No", value: false };
    this.setState({ data, errors });
  };

  //  load lists of the dropdown
  loadOptionSettings = (data) => {
    this.setState({ settingsLoading: true });
    this.props.loadSettings(() => this.setState({ settingsLoading: false }));
  };

  handleImageChange = (e, type) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    console.log(type);
    this.setState({
      [type === "desktop"
        ? "loadingDesktopImage"
        : type === "privateDesktop"
        ? "loadingPrivateDesktopImage"
        : type === "thumbnail"
        ? "loadingThumbnailImage"
        : type === "telegramAppImage"
        ? "loadingTelegramAppImage"
        : type === "leaderBoardImage"
        ? "loadingSocialLeaderBoardImage"
        : type === "privateMobile"
        ? "loadingPrivateMobileImage"
        : type === "telegramMissionImage"
        ? "loadingTelegramMissionImage"
        : "loadingMobileImage"]: true,
    });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      })
        .then((result) => {
          console.log("public/" + result.key, type);

          this.setState({
            errors,
            [type === "desktop"
              ? "loadingDesktopImage"
              : type === "privateDesktop"
              ? "loadingPrivateDesktopImage"
              : type === "thumbnail"
              ? "loadingThumbnailImage"
              : type === "telegramAppImage"
              ? "loadingTelegramAppImage"
              : type === "leaderBoardImage"
              ? "loadingSocialLeaderBoardImage"
              : type === "privateMobile"
              ? "loadingPrivateMobileImage"
              : type === "telegramMissionImage"
              ? "loadingTelegramMissionImage"
              : "loadingMobileImage"]: false,
            data: {
              ...this.state.data,
              [type === "desktop"
                ? "desktopImage"
                : type === "privateDesktop"
                ? "privateDesktopImage"
                : type === "thumbnail"
                ? "thumbnailImage"
                : type === "telegramAppImage"
                ? "telegramAppImage"
                : type === "leaderBoardImage"
                ? "socialLeaderBoardImage"
                : type === "privateMobile"
                ? "privateMobileImage"
                : type === "telegramMissionImage"
                ? "telegramMissionImage"
                : "mobileImage"]: {
                original: "public/" + result.key,
              },
            },
            [type === "desktop"
              ? "desktopImageChange"
              : type === "privateDesktop"
              ? "privateDesktopImageChange"
              : type === "thumbnail"
              ? "thumbnailImageChange"
              : type === "telegramAppImage"
              ? "telegramAppImageChange"
              : type === "leaderBoardImage"
              ? "socialLeaderBoardImageChange"
              : type === "privateMobile"
              ? "privateMobileImageChange"
              : type === "telegramMissionImage"
              ? "telegramMissionImageChange"
              : "mobileImageChange"]: true,
          });
          e.target.value = null;
        })
        .catch((error) => {
          e.target.value = null;
        });
    }
    this.setState({ data });
  };
  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = !data[e.target.name];
    delete errors[e.target.name];
    this.setState({ data, errors });
  };
  render() {
    const { settings, howItWorksTemplates } = this.props.getSettings;
    const categoryList = settings?.length > 0 ? structuredClone(settings[0]?.question?.categories) : [];
    const { data, errors } = this.state;
    console.log(data);

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="bgGames" />

          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>{this.props.location.state ? "Edit" : "Add a"} BG Game</h1>
            </div>
            <div className="fwc-wrapper">
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">{this.renderInput("name", "Name")}</div>
                    </div>
                    <div className="col-6">
                      <div className="fml-box">{this.renderInput("subtitle", "Subtitle")}</div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fml-box">{this.renderInput("url", "URL", "")}</div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <div className="d-flex align-items center justify-content-between">
                            <label className="form-label">How It Works Template</label>
                            {this.state.data.howItWorksTemplate?.value && (
                              <div>
                                {!_.isEmpty(this.state.data.howItWorksTemplate) && (
                                  <a
                                    href="#!"
                                    className="form-label-link ml-10"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          howItWorksTemplate: {},
                                        },
                                      });
                                    }}
                                  >
                                    Clear
                                  </a>
                                )}
                              </div>
                            )}
                          </div>
                          <Select
                            name="howItWorksTemplate"
                            options={howItWorksTemplates?.map((template) => ({
                              name: template.title,
                              value: template.id,
                              data: template,
                            }))}
                            label="Select How It Works Template"
                            value={this.state.data.howItWorksTemplate}
                            error={this.state.errors.howItWorksTemplate}
                            onChange={this.onChange}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fml-box">
                        {this.renderInput("gameEndDelay", "Game-end delay (in Millisecond)", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div
                      className={`col-${
                        !_.isEmpty(data?.scoringType) && data?.scoringType?.value === 2 ? "6" : "12"
                      } fml-box`}
                    >
                      <div className="new-form-group">
                        <label className="form-label">Scoring Type</label>
                        <Select
                          name="scoringType"
                          options={[
                            { name: "Round Based Scoring Pattern", value: 1 },
                            { name: "Normal Scoring Pattern", value: 2 },
                          ]}
                          label="Select Scoring Type"
                          value={this.state.data.scoringType}
                          error={this.state.errors.scoringType}
                          onChange={this.onChange}
                          isLoading={false}
                        />
                      </div>
                    </div>
                    <div className="col-6 fml-box">
                      {!_.isEmpty(data?.scoringType) &&
                        data?.scoringType?.value === 2 &&
                        this.renderInput("maxTimeSpan", "Max Time Span (In Seconds)", "", "number")}
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="fml-box">{this.renderInput("contractAddress", "Contract Address", "")}</div>
                </div>{" "}
                <div className="col-6">
                  <div className="fml-box">{this.renderInput("gameEncryptionKey", "Encryption Key", "")}</div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Wallet Restrictions</label>
                          <Select
                            name="isWalletRestrictionEnabled"
                            options={[
                              { name: "Yes", value: true, data: null },
                              { name: "No", value: false, data: null },
                            ]}
                            label=""
                            value={this.state.data.isWalletRestrictionEnabled}
                            error={this.state.errors.isWalletRestrictionEnabled}
                            onChange={this.onChange}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Access Pass Restrictions</label>
                          <Select
                            name="isAccessPassRestrictionEnabled"
                            options={[
                              { name: "Yes", value: true },
                              { name: "No", value: false },
                            ]}
                            label=""
                            value={this.state.data.isAccessPassRestrictionEnabled}
                            error={this.state.errors.isAccessPassRestrictionEnabled}
                            onChange={this.onChange}
                            isDisabled={!this.state.data.isWalletRestrictionEnabled?.value}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Display Order</label>
                          <Select
                            name="displayOrder"
                            options={[...Array(50).keys()].map((i) => ({ name: `${i + 1}`, value: i + 1 }))}
                            label="Select Display Order"
                            value={this.state.data.displayOrder}
                            error={this.state.errors.displayOrder}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {" "}
                      <label className="form-label">Other Game Details</label>
                      <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.timer && "border-error"}`}
                              type="checkbox"
                              name="timer"
                              id="radioCheckOneFlyer"
                              checked={this.state.data.timer}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingReward || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckOneFlyer">
                              Timer
                            </label>
                          </div>
                        </div>
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.score && "border-error"}`}
                              type="checkbox"
                              name="score"
                              id="checkboxDetails"
                              checked={this.state.data.score}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingReward || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="checkboxDetails" tabindex="1">
                              Score
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="fml-box">{this.renderInput("externalDemoURL", "External Demo URL", "")}</div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Applicable For External Demo</label>
                          <Select
                            name="applicableForExternalDemo"
                            options={[
                              { name: "Yes", value: true, data: null },
                              { name: "No", value: false, data: null },
                            ]}
                            label=""
                            value={this.state.data?.applicableForExternalDemo}
                            error={this.state.errors?.applicableForExternalDemo}
                            onChange={this.onChange}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>{" "}
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="fml-box">{this.renderInput("telegramGameURL", "Telegram Mini App URL", "")}</div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Applicable for Telegram Mini App</label>
                          <Select
                            name="applicableForTelegramGame"
                            options={[
                              { name: "Yes", value: true, data: null },
                              { name: "No", value: false, data: null },
                            ]}
                            label=""
                            value={this.state.data?.applicableForTelegramGame}
                            error={this.state.errors?.applicableForTelegramGame}
                            onChange={this.onChange}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>{" "}
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="new-form-group">
                    <label className="form-label">Game Type</label>
                    <Select
                      name="gameType"
                      options={this.gameTypeOptions}
                      label=""
                      value={this.state.data.gameType}
                      error={this.state.errors.gameType}
                      onChange={this.onChange}
                      isLoading={false}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Applicable for Board Game</label>
                          <Select
                            name="applicableForBoardGame"
                            options={[
                              { name: "Yes", value: true, data: null },
                              { name: "No", value: false, data: null },
                            ]}
                            label=""
                            value={this.state.data?.applicableForBoardGame}
                            error={this.state.errors?.applicableForBoardGame}
                            onChange={this.onChange}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <label className="form-label text-uppercase gcdr-heading-filter">
                  <div className="gcdr-heading-text">Missions</div>
                </label>
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelOneScore", "Level One Score", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelOneMultiplier", "Level One Multiplier", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelOnePoints", "Level One Points", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelTwoScore", "Level Two Score", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelTwoMultiplier", "Level Two Multiplier", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelTwoPoints", "Level Two Points", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelThreeScore", "Level Three Score", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelThreeMultiplier", "Level Three Multiplier", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelThreePoints", "Level Three Points", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelFourScore", "Level Four Score", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelFourMultiplier", "Level Four Multiplier", "", "number")}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fml-box">
                        {this.renderInput("levelFourPoints", "Level Four Points", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="row fieldset">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="fml-box">
                        <div className="new-form-group">
                          <label className="form-label">Mission Reward Type</label>
                          <Select
                            name="missionCompletedRewardType"
                            options={[
                              { name: "Points", value: 1 },
                              { name: "USDT", value: 2 },
                              { name: "Gear Box Nft", value: 3 },
                            ]}
                            label=""
                            value={this.state.data?.missionCompletedRewardType}
                            error={this.state.errors?.missionCompletedRewardType}
                            onChange={this.onChange}
                            isDisabled={true}
                            isLoading={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fml-box">
                        {this.renderInput("missionCompletedReward", "Mission Completed Reward", "", "number")}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="new-form-group"></div>{" "}
              <div className="new-form-group">{this.renderTextarea("description", "How It Works", "")}</div>{" "}
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingDesktopImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner ${
                        !this.state.data.desktopImage ? "placeholder-uploaded-img" : " position-relative"
                      } ${this.state.errors.desktopImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.desktopImage
                              ? process.env.REACT_APP_CDN + this.state.data.desktopImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.desktopImage = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile" className="kt-label" id="uploadImg">
                      {this.state.data.desktopImage ? " Change Desktop Image" : "+ Upload Desktop Image"}
                    </label>{" "}
                    ( Recommended size: 1266 x 475 )
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => this.handleImageChange(e, "desktop")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingMobileImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner-2 ${
                        !this.state.data.mobileImage
                          ? "placeholder-uploaded-img"
                          : "kt-upload-outer-banner-2-h-100  position-relative"
                      } ${this.state.errors.mobileImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.mobileImage
                              ? process.env.REACT_APP_CDN + this.state.data.mobileImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.mobileImage = "";
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner-2 position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile2" className="kt-label" id="uploadImg">
                      {this.state.data.mobileImage ? " Change Mobile Image" : "+ Upload Mobile Image"}
                    </label>{" "}
                    ( Recommended size: 390 x 650 )
                    <input
                      className="form-control"
                      type="file"
                      id="formFile2"
                      onChange={(e) => this.handleImageChange(e, "mobile")}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingThumbnailImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner-3 ${
                        !this.state.data.thumbnailImage
                          ? "placeholder-uploaded-img"
                          : "kt-upload-outer-banner-2-h-100  position-relative"
                      } ${this.state.errors.thumbnailImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.thumbnailImage
                              ? process.env.REACT_APP_CDN + this.state.data.thumbnailImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.thumbnailImage = "";
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner-3 position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile3" className="kt-label" id="uploadImg">
                      {this.state.data.thumbnailImage ? " Change Thumbnail Image" : "+ Upload Thumbnail Image"}
                    </label>{" "}
                    ( Recommended size: 512 x 512 )
                    <input
                      className="form-control"
                      type="file"
                      id="formFile3"
                      onChange={(e) => this.handleImageChange(e, "thumbnail")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingSocialLeaderBoardImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner-3 ${
                        !this.state.data.socialLeaderBoardImage
                          ? "placeholder-uploaded-img"
                          : "kt-upload-outer-banner-2-h-100  position-relative"
                      } ${this.state.errors.socialLeaderBoardImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.socialLeaderBoardImage
                              ? process.env.REACT_APP_CDN + this.state.data.socialLeaderBoardImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.socialLeaderBoardImage = "";
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner-3 position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile4" className="kt-label" id="uploadImg">
                      {this.state.data.socialLeaderBoardImage
                        ? " Change Social Leaderboard Image"
                        : "+ Upload Social Leaderboard Image"}
                    </label>{" "}
                    ( Recommended size: 401 x 400 )
                    <input
                      className="form-control"
                      type="file"
                      id="formFile4"
                      onChange={(e) => this.handleImageChange(e, "leaderBoardImage")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingPrivateDesktopImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner ${
                        !this.state.data.privateDesktopImage ? "placeholder-uploaded-img" : " position-relative"
                      } ${this.state.errors.privateDesktopImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.privateDesktopImage
                              ? process.env.REACT_APP_CDN + this.state.data.privateDesktopImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.privateDesktopImage = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile5" className="kt-label" id="uploadImg">
                      {this.state.data.privateDesktopImage
                        ? " Change Private Desktop Image"
                        : "+ Upload Private Desktop Image"}
                    </label>{" "}
                    ( Recommended size: 1266 x 475 )
                    <input
                      className="form-control d-none"
                      type="file"
                      id="formFile5"
                      onChange={(e) => this.handleImageChange(e, "privateDesktop")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingPrivateMobileImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner-2 ${
                        !this.state.data.privateMobileImage
                          ? "placeholder-uploaded-img"
                          : "kt-upload-outer-banner-2-h-100  position-relative"
                      } ${this.state.errors.privateMobileImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.privateMobileImage
                              ? process.env.REACT_APP_CDN + this.state.data.privateMobileImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.privateMobileImage = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner-2 position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile6" className="kt-label" id="uploadImg">
                      {this.state.data.privateMobileImage
                        ? " Change Private Mobile Image"
                        : "+ Upload Private Mobile Image"}
                    </label>{" "}
                    ( Recommended size: 390 x 650 )
                    <input
                      className="form-control d-none"
                      type="file"
                      id="formFile6"
                      onChange={(e) => this.handleImageChange(e, "privateMobile")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingTelegramAppImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner-3 ${
                        !this.state.data.telegramAppImage?.original
                          ? "placeholder-uploaded-img"
                          : "kt-upload-outer-banner-2-h-100  position-relative"
                      } ${this.state.errors.telegramAppImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.telegramAppImage?.original
                              ? process.env.REACT_APP_CDN + this.state.data.telegramAppImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.telegramAppImage = "";
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner-3 position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile7" className="kt-label" id="uploadImg">
                      {this.state.data.telegramAppImage?.original
                        ? " Change Telegram Game Image"
                        : "+ Upload Telegram Image"}
                    </label>{" "}
                    ( Recommended size: 512 x 512 )
                    <input
                      className="form-control d-none"
                      type="file"
                      id="formFile7"
                      onChange={(e) => this.handleImageChange(e, "telegramAppImage")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  {!this.state.loadingTelegramMissionImage ? (
                    <div
                      className={`kt-upload-outer kt-upload-outer-banner ${
                        !this.state.data.telegramMissionImage ? "placeholder-uploaded-img" : " position-relative"
                      } ${this.state.errors.telegramMissionImage && "border-error"}`}
                    >
                      <div className="kt-upload-img" htmlFor="uploadImg">
                        <img
                          src={
                            this.state.data.telegramMissionImage
                              ? process.env.REACT_APP_CDN + this.state.data.telegramMissionImage.original
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.telegramMissionImage = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </div>
                  ) : (
                    <div className="kt-upload-outer  kt-upload-outer-banner position-relative loading">
                      <div className="lottie-player-box">
                        <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                      </div>
                    </div>
                  )}
                  <div className="kt-file-input">
                    <label htmlFor="formFile8" className="kt-label" id="uploadImg">
                      {this.state.data.telegramMissionImage
                        ? " Change Telegram Mission Image"
                        : "+ Upload Telegram Mission Image"}
                    </label>{" "}
                    ( Recommended size: 341 x 192 )
                    <input
                      className="form-control d-none"
                      type="file"
                      id="formFile8"
                      onChange={(e) => this.handleImageChange(e, "telegramMissionImage")}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group new-form-btn text-end">
                <NextButton
                  handleSubmit={this.handleSubmit}
                  label="Save"
                  loading={this.state.loadingBGGame}
                  classData={"btn btn-default"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  addBGGame: (data, callback) => dispatch(addBGGame(data, callback)),
  loadBGGames: (callback) => dispatch(loadBGGames(callback)),
  updateBGGame: (data, id, callback) => dispatch(updateBGGame(data, id, callback)),
  loadSettings: (callback) => dispatch(loadSettings(callback)),
  loadHowItWorksTemplates: (callback) => dispatch(loadHowItWorksTemplates(callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBGGame));
