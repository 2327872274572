import Joi from "joi-browser";
import moment from "moment";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { deletePrizes, deletePrizeReceived, getTelegram, loadPrizes } from "store/telegram";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import AddTierPrize from "./addPrizeTemplate";
import { TELEGRAM_SWEEPSTAKES_PRIZE_TYPES } from "utils/constants";

class TierPrizesListing extends Form {
  ref = createRef(null);
  refCsv = createRef(null);
  state = {
    data: {
      title: "",
      winnerUserName: "",
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    cancelId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
    page: 1,
    statusInd: "",
    loadingStatus: false,
    showLeaderboard: false,
    instance: null,
    winnersCSV: [],
    loadingCsv: false,
    errors: {},
  };
  schema = {
    title: Joi.string().allow(""),
    winnerUserName: Joi.string().allow(""),
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) this.getInstances();
  };
  componentDidMount = () => {
    this.getInstances();
  };
  getInstances = () => {
    this.setState({ loadingMain: true });
    this.props.loadPrizes((res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleLeaderboardModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showLeaderboard: !this.state.showLeaderboard });
  };
  onChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };
  handleCancel = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deletePrizes(this.state.cancelId, (instanceRes) => {
      if (instanceRes.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deletePrizeReceived(instanceRes?.data?.data);
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={instanceRes.data && instanceRes.data.message} />, {
          containerId: 1,
        });
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };

  cancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const tierPrizes = this.props.getTelegram?.prizes;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-sweepstake-prizes" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Prizes Templates</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link
                      to="#"
                      onClick={this.toggleAddModal}
                      className="btn btn-default btn-sm d-flex justify-content-center"
                    >
                      <img src={plusIcon} alt="" /> Add
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {tierPrizes.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th>Title</th>
                              <th>Start Rank</th>
                              <th>End Rank</th>
                              <th>Prizes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tierPrizes &&
                              tierPrizes.length > 0 &&
                              tierPrizes.map((instance, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <Dropdown className=" custom-dropdown">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className={`custom-dropdown-toggle`}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={instance?.status === 8}
                                        >
                                          <img src={carrentIcon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          <Dropdown.Item
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({ editData: instance }, () => {
                                                this.toggleAddModal();
                                              });
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          {instance?.status !== 8 && (
                                            <Dropdown.Item
                                              className="dropdown-item delete"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ cancelId: instance?._id }, () => {
                                                  this.cancelModalToggle();
                                                });
                                              }}
                                            >
                                              Delete
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    <td>
                                      <Link
                                        to="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ editData: instance }, () => {
                                            this.toggleAddModal();
                                          });
                                        }}
                                      >
                                        {instance?.tierName}
                                      </Link>
                                    </td>
                                    <td>{instance?.startRank}</td>
                                    <td>{instance?.endRank}</td>
                                    <td>
                                      {instance?.prizes?.map((p) => (
                                        <div className="camelCase">
                                          {p.quantity} {TELEGRAM_SWEEPSTAKES_PRIZE_TYPES[p?.prizeType]}
                                          {p.prizeType !== "usdt" && p.quantity > 1 && "s"}
                                        </div>
                                      ))}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddTierPrize
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadPrizes}
          edit={this.state.editData}
          status={status}
        />
        <DeleteModal
          alertModalToggle={this.cancelModalToggle}
          alertModalShow={this.state.showDeleteModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you want to delete this prize template?"
          onPress={this.handleCancel}
          loading={this.state.loadingDelete}
        />

        {(this.state.showAddModal || this.state.showLeaderboard) && (
          <div
            className="modal-backdrop show fade"
            onClick={this.state.showLeaderboard ? this.toggleLeaderboardModal : this.toggleAddModal}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadPrizes: (callback) => dispatch(loadPrizes(callback)),
  deletePrizes: (id, callback) => dispatch(deletePrizes(id, callback)),
  deletePrizeReceived: (payLoad) => dispatch(deletePrizeReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TierPrizesListing));
