import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import backIcon from "include/images/chevron-left.svg";
import robotRacerImg from "include/images/robot-racer.png";
import loadingIcon from "include/images/loader-grey.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { camelize, generateShades, msToHMS, pluralize } from "utils/misc";
import emptyIcon from "include/images/nrf.svg";
import { getTelegram, loadSweepstakeAnalyticsDashboard } from "store/telegram";
import userIcon from "include/images/user.png";
import { TELEGRAM_SWEEPSTAKES_PRIZE_TYPES } from "utils/constants";
import { PieChart } from "@mui/x-charts/PieChart";
import { label } from "aws-amplify";
import DateRangeCalendarPicker from "common/datePicker";

const TelegramSweepstakesAnalytics = (props) => {
  const ref = useRef();
  const [type, setType] = useState("winner");
  const [showViewAll, setShowViewAll] = useState(false);
  const [showAllUserEngagement, setShowAllUserEngagement] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  // State to manage the date range for the analytics
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    getAnalyticsData();
  }, []);

  const getAnalyticsData = (selection) => {
    setLoadingDashboard(true);
    // Prepare payload with start and end dates
    const payLoad = selection
      ? { startDate: selection.startDate.getTime(), endDate: selection.endDate.getTime() }
      : { startDate: dateRange.startDate.getTime(), endDate: dateRange.endDate.getTime() };

    // Load the analytics data
    props.loadSweepstakeAnalyticsDashboard(payLoad, () => {
      setLoadingMain(false);
      setLoadingDashboard(false);
    });
  };
  const { sweepstakeAnalyticsDashboard, analyticsLoading } = props.getTelegram;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    if (type) setType(type);
    setShowViewAll(!showViewAll);
  };
  const toggleViewUserEngagement = (e) => {
    e.preventDefault();
    setShowAllUserEngagement(!showAllUserEngagement);
  };

  // Function to handle date range change
  const onDateChange = (ranges) => {
    const { selection } = ranges;
    if (selection.startDate.getTime() !== selection.endDate.getTime()) {
      // Load new data based on the selected date range
      getAnalyticsData(selection);
    }
    // Update the date range state
    setDateRange(selection);
  };

  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper analytics-wrapper">
        <SideBar page="telegram-mini-app-analytics-sweepstakes" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center justify-content-between">
              <h1>Sweepstakes Analytics</h1>{" "}
              <div className="d-flex align-items-center">
                <span className="mx-2">
                  {dateRange.startDate.toLocaleDateString("en-US")} - {dateRange.endDate.toLocaleDateString("en-US")}
                </span>
                <DateRangeCalendarPicker dateRange={dateRange} setDateRange={setDateRange} onChange={onDateChange} />
              </div>
            </div>
            <div className="fwc-wrapper">
              <div className="flex-wrap grid-card-details-row d-flex">
                <div className="gcdr-text-box w-100">
                  <div className="">
                    <div className="gcdr-widget-box">
                      <ul className="flex-wrap gw-list d-flex">
                        <li>
                          <div className="gw-box">
                            <em>Total Entries</em>
                            <h6>{sweepstakeAnalyticsDashboard?.totalEntries?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Users</em>
                            <h6>{sweepstakeAnalyticsDashboard?.totalUsers?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Points</em>
                            <h6>{sweepstakeAnalyticsDashboard?.totalPoints?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="gcdr-widget-wrapper w-100 row">
                    {(loadingDashboard || sweepstakeAnalyticsDashboard?.statusCounts?.length > 0) && (
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4>Status</h4>{" "}
                          {sweepstakeAnalyticsDashboard?.statusCounts?.length > 5 && (
                            <Link to="#" className="m-0 back-link-btn" onClick={(e) => toggleViewAll(e, "winner")}>
                              View all
                            </Link>
                          )}
                        </div>
                        {loadingDashboard ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : sweepstakeAnalyticsDashboard?.statusCounts?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6 table-responsive">
                              <table className="table custom-table-striped">
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sweepstakeAnalyticsDashboard?.statusCounts?.length > 0 &&
                                    sweepstakeAnalyticsDashboard?.statusCounts?.map((status, index) => (
                                      <tr key={status?._id}>
                                        <td className="camelCase">{status?._id}</td>
                                        <td>{status?.count?.toLocaleString("en-us")}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {sweepstakeAnalyticsDashboard?.statusCounts?.length === 0 &&
                                (analyticsLoading ? (
                                  <div className="w-100 d-flex justify-content-center load-more">
                                    <img src={loadingIcon} className="fa-spin" alt="" />
                                  </div>
                                ) : (
                                  <div className="nfr-box">No Records Found!</div>
                                ))}
                            </div>
                            <div className="col-6">
                              {sweepstakeAnalyticsDashboard?.statusCounts?.length > 0 && (
                                <PieChart
                                  className="my-2 w-100"
                                  series={[
                                    {
                                      data: sweepstakeAnalyticsDashboard?.statusCounts?.map((status, index) => ({
                                        id: index,
                                        value: status?.count,
                                        label: camelize(status?._id),
                                      })),
                                    },
                                  ]}
                                  colors={generateShades("#E46223", 3)}
                                  width={400}
                                  height={200}
                                  slotProps={{
                                    legend: { hidden: true },
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {(loadingDashboard || sweepstakeAnalyticsDashboard?.totalPrize?.length > 0) && (
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4>Total Prizes</h4>{" "}
                          {sweepstakeAnalyticsDashboard?.totalPrize?.length > 5 && (
                            <Link to="#" className="m-0 back-link-btn" onClick={(e) => toggleViewAll(e, "winner")}>
                              View all
                            </Link>
                          )}
                        </div>
                        {loadingDashboard ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : sweepstakeAnalyticsDashboard?.totalPrize?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className=" col-6 table-responsive">
                              <table className="table custom-table-striped">
                                <thead>
                                  <tr>
                                    <th>Prize</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sweepstakeAnalyticsDashboard?.totalPrize?.length > 0 &&
                                    sweepstakeAnalyticsDashboard?.totalPrize?.map((prize, index) => (
                                      <tr key={prize?._id}>
                                        <td className="camelCase">
                                          {TELEGRAM_SWEEPSTAKES_PRIZE_TYPES[prize?._id]}
                                          {prize?._id !== "usdt" ? "s" : ""}
                                        </td>
                                        <td>{prize?.totalPrize?.toLocaleString("en-us")}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {sweepstakeAnalyticsDashboard?.totalPrize?.length === 0 &&
                                (analyticsLoading ? (
                                  <div className="w-100 d-flex justify-content-center load-more">
                                    <img src={loadingIcon} className="fa-spin" alt="" />
                                  </div>
                                ) : (
                                  <div className="nfr-box">No Records Found!</div>
                                ))}
                            </div>
                            <div className="col-6">
                              {sweepstakeAnalyticsDashboard?.totalPrize?.length > 0 && (
                                <PieChart
                                  className="my-2 w-100"
                                  series={[
                                    {
                                      data: sweepstakeAnalyticsDashboard?.totalPrize?.map((prize, index) => ({
                                        id: index,
                                        value: prize?.totalPrize,
                                        label: `${TELEGRAM_SWEEPSTAKES_PRIZE_TYPES[prize?._id]}${
                                          prize?._id !== "usdt" ? "s" : ""
                                        }`,
                                      })),
                                    },
                                  ]}
                                  colors={generateShades("#E46223", 3)}
                                  width={400}
                                  height={200}
                                  slotProps={{
                                    legend: { hidden: true },
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {(showViewAll || showAllUserEngagement) && (
        <div
          className="modal-backdrop show fade"
          onClick={showViewAll ? toggleViewAll : toggleViewUserEngagement}
        ></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadSweepstakeAnalyticsDashboard: (params, callback) => dispatch(loadSweepstakeAnalyticsDashboard(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TelegramSweepstakesAnalytics));
