import { startCase } from "lodash";
import moment from "moment/moment";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  deleteBattleGame,
  getTelegram,
  loadBattleGameParticipants,
  loadBattleGames,
  updateBattleGame,
  updateBattleGameStatusReceived,
} from "store/telegram";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import AddGameFlyer from "./addGameFlyer";
import ParticipantsFlyer from "./participantsFlyer";

class BattleGamesListing extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: true,
    showAddGame: false,
    showDeleteModal: false,
    data: {
      name: "",
    },
    editData: null,
    deleteId: null,
    appliedFilters: {},
    errors: {},
    sorting: { sort: "createdAt", order: "desc" },
    showFilters: false,
    showParticipantFlyer: false,
    battleGameId: null,
    page: 1,
  };
  componentDidMount = () => {
    this.searchFilters();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.battleGamesLoading &&
      this.props.getTelegram.battleGamesLoading !== prevProps.getTelegram.battleGamesLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.battleGamesLoading &&
      this.props.getTelegram.battleGamesLoading !== prevProps.getTelegram.battleGamesLoading
    )
      this.ref.current.continuousStart();
    if (this.state.showAddGame !== prevState.showAddGame && !this.state.showAddGame) {
      this.setState({ editData: null });
    }
  };
  //  Filters
  searchFilters = () => {
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const params = {};
    params.page = this.state.page;

    this.props.loadBattleGames(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
        });
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };

  addGameToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddGame: !this.state.showAddGame });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteBattleGame(this.state.deleteId, (res) => {
      if (res?.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingDelete: false });
    });
  };
  changeStatus = (e) => {
    this.setState({ loadingStatus: true });
    const payLoad = { status: this.state.statusInd };

    this.props.updateBattleGame(this.state.statusChangeId, payLoad, (res) => {
      this.setState({
        loadingStatus: false,
      });
      if (res?.status === 200) {
        this.props.updateBattleGameStatusReceived(res?.data?.data);
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
    });
  };
  toggleParticipantFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showParticipantFlyer: !this.state.showParticipantFlyer });
  };
  render() {
    const { loading } = this.state;
    const { battleGames, battleGamesPagination } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-battleGame-games" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>
                Battle Games{" "}
                {!loading && battleGamesPagination?.totalRecords > 0 && `(${battleGamesPagination?.totalRecords})`}
              </h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <button className="btn btn-default btn-sm" onClick={this.addGameToggle}>
                    <img src={plusIcon} alt="" /> Add Games
                  </button>
                </div>
              </div>
            </div>

            <div className="fwc-wrapper">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {battleGames?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                            <th>Name</th>
                            <th>Gears</th>
                            <th>Start Date & Time</th>
                            <th>End Date & Time</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {battleGames?.map((game, i) => (
                            <tr key={game?._id}>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    {game?.status === "scheduled" && (
                                      <>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editData: game }, () => {
                                              this.addGameToggle();
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item delete"
                                          onClick={(e) => {
                                            this.setState({ deleteId: game?._id }, () => {
                                              this.alertModalToggle(e);
                                            });
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </>
                                    )}
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({ battleGameId: game?._id }, () => {
                                          this.toggleParticipantFlyer();
                                        });
                                      }}
                                    >
                                      View Participants
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{startCase(game?.name)}</td>
                              <td>{game?.defaultGear?.map((gear) => gear?.name)?.join(", ")}</td>
                              <td>
                                <div class="small-text">
                                  {moment(game?.startTime).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(game?.startTime).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>
                                <div class="small-text">
                                  {moment(game?.endTime).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(game?.endTime).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td className="ust-tag">
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={game?.status !== "active" && game?.status !== "inactive"}
                                  >
                                    <span
                                      className={`w-100 text-center d-block camelCase ${
                                        game.status === "completed" || game.status === "active"
                                          ? "active"
                                          : game.status === "inactive"
                                          ? "suspended"
                                          : "inactive"
                                      } ${this.state.loadingStatus && "pointer-none"}`}
                                    >
                                      {this.state.loadingStatus && this.state.statusChangeId === game._id ? (
                                        <img
                                          src={loadingIcon}
                                          alt="loading..."
                                          style={{ width: 20 }}
                                          className="fa-spin"
                                        />
                                      ) : game.status === "completed" ? (
                                        "Completed"
                                      ) : (
                                        game.status
                                      )}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item "
                                      onClick={(e) => {
                                        this.setState(
                                          {
                                            statusChangeId: game._id,
                                            statusInd: game.status === "active" ? "inactive" : "active",
                                            loadingStatus: true,
                                          },
                                          this.changeStatus,
                                        );
                                      }}
                                    >
                                      {game?.status === "active" ? "Inactive" : "Active"}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <div className="td-text-box">
                                  {moment(game?.createdAt).format("MM/DD/YYYY hh:mm a")}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading && !(this.state.page === 1 && battleGames?.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#!"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {this.state.page < battleGamesPagination?.pages && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page >= battleGamesPagination?.pages && "disabled"}`}
                          href="#!"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this Battle Game?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        <AddGameFlyer
          showModal={this.state.showAddGame}
          toggleModal={this.addGameToggle}
          edit={this.state.editData}
          reload={() => {
            this.setState({ page: 1 }, this.searchFilters);
          }}
        />
        <ParticipantsFlyer
          showModal={this.state.showParticipantFlyer}
          toggleModal={this.toggleParticipantFlyer}
          battleGameId={this.state.battleGameId}
        />
        {(this.state.showAddGame || this.state.showParticipantFlyer) && (
          <div
            className="modal-backdrop z-index-1000 show fade"
            onClick={this.state.showAddGame ? this.addGameToggle : this.toggleParticipantFlyer}
          ></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadBattleGames: (params, callback) => dispatch(loadBattleGames(params, callback)),
  loadBattleGameParticipants: (id, params, callback) => dispatch(loadBattleGameParticipants(id, params, callback)),
  deleteBattleGame: (id, callback) => dispatch(deleteBattleGame(id, callback)),
  updateBattleGame: (id, data, callback) => dispatch(updateBattleGame(id, data, callback)),
  updateBattleGameStatusReceived: (payLoad) => dispatch(updateBattleGameStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BattleGamesListing));
