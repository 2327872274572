import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import backIcon from "include/images/chevron-left.svg";
import robotRacerImg from "include/images/robot-racer.png";
import loadingIcon from "include/images/loader-grey.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { msToHMS } from "utils/misc";
import AllUserEngagementFlyer from "../flyers/allUserEngagementFlyer";
import ViewAllFlyer from "../flyers/viewAllFlyer";
import {
  getTelegram,
  loadRobotRacerDashboard,
  loadRobotRacerTopLosers,
  loadRobotRacerTopUsersByTimeSpent,
  loadRobotRacerTopWinners,
} from "store/telegram";
import userIcon from "include/images/user.png";

const TelegramRobotRacerStats = (props) => {
  const ref = useRef();
  const [type, setType] = useState("winner");
  const [showViewAll, setShowViewAll] = useState(false);
  const [showAllUserEngagement, setShowAllUserEngagement] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  useEffect(() => {
    props.loadRobotRacerDashboard(() => setLoadingMain(false));
    props.loadRobotRacerTopWinners({ page: 1 });
    props.loadRobotRacerTopLosers({ page: 1 });
    props.loadRobotRacerTopUsersByTimeSpent({ page: 1 });
  }, []);
  const {
    robotRacerDashboard,
    robotRacerTopWinners,
    robotRacerTopWinnersLoading,
    robotRacerTopWinnersPagination,
    robotRacerTopLosers,
    robotRacerTopLosersPagination,
    robotRacerTopLosersLoading,
    robotRacerTopUsersByTimeSpent,
    robotRacerTopUsersByTimeSpentPagination,
    robotRacerTopUsersByTimeSpentLoading,
  } = props.getTelegram;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    if (type) setType(type);
    setShowViewAll(!showViewAll);
  };
  const toggleViewUserEngagement = (e) => {
    e.preventDefault();
    setShowAllUserEngagement(!showAllUserEngagement);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="telegram-mini-app-luckGame-robot-racer" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Robot Racer Game</h1>
            </div>
            <div className="fwc-wrapper">
              <Link
                to="#"
                className="back-link-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
              >
                <img src={backIcon} alt="" /> Back
              </Link>
              <div className="grid-card-details-row d-flex flex-wrap">
                <div className="gcdr-img-box">
                  <div className="gcdr-img-outer text-white">
                    <div className="gcdr-image">
                      <img src={robotRacerImg} alt="" />
                    </div>
                    <div className="gcdr-group d-flex flex-wrap">
                      <div className="gcdr-item flex-grow-1 w-auto">
                        <h6>{msToHMS(parseInt(robotRacerDashboard?.totalTimeSpend))}</h6>
                        <p>
                          <strong>Time Spend</strong>
                        </p>
                      </div>
                      <div className="gcdr-item flex-grow-1 w-auto">
                        <h6>{robotRacerDashboard?.onlineUsers?.toLocaleString("en-us") ?? 0}</h6>
                        <p>
                          <strong>ONLINE USERS</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gcdr-widget-wrapper">
                    <div className="gcdr-widget-box">
                      <h4>Details</h4>
                      <div className="gcdr-details-box">
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Total Games Played</div>
                          <div>{robotRacerDashboard?.completedRound?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Unique Players</div>
                          <div>{robotRacerDashboard?.totalPlayer?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Games per Player</div>
                          <div>
                            {robotRacerDashboard?.gamePerPlayerRatio?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) ?? 0}
                          </div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Bets per Player</div>
                          <div>
                            {robotRacerDashboard?.betPerPlayerRatio?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) ?? 0}
                          </div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Empty Rounds with No Players</div>
                          <div>{robotRacerDashboard?.emptyRound?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gcdr-text-box">
                  <div className="gcdr-heading d-flex align-items-center justify-content-between">
                    <h2>Robot Racer Game</h2>
                  </div>

                  <div className="gcdr-widget-wrapper mt-2">
                    <div className="gcdr-widget-box">
                      <ul className="gw-list d-flex flex-wrap">
                        <li>
                          <div className="gw-box">
                            <em>Total Rounds</em>
                            <h6>{robotRacerDashboard?.totalRound?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Available Rounds</em>
                            <h6>{robotRacerDashboard?.availableRounds?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Completed Rounds</em>
                            <h6>{robotRacerDashboard?.completedRound?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Bet Amount</em>
                            <h6>
                              {robotRacerDashboard?.totalBetAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Amount Won</em>
                            <h6>
                              {robotRacerDashboard?.totalWinningAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>BG Profit</em>
                            <h6>
                              {robotRacerDashboard?.BGProfit?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Winners</h4>{" "}
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "winner")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>No of Times Bets Placed</th>
                              <th>Points Spent</th>
                              <th>Points Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {robotRacerTopWinners?.length > 0 &&
                              robotRacerTopWinners?.map((winner, index) => (
                                <tr key={winner?._id}>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2">
                                          <img
                                            src={
                                              winner?.user?.image
                                                ? process.env.REACT_APP_CDN + winner?.user?.image?.small
                                                : userIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          {winner?.user?.firstName} {winner?.user?.lastName}
                                          <br />
                                          {winner?.user?.userName} <br />
                                          {winner?.user?.telegramId}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{winner?.totalBetsPlaced}</td>
                                  <td>
                                    {winner?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {robotRacerTopWinners?.length === 0 &&
                          (robotRacerTopWinnersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Losers</h4>
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "loser")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>No of Times Bets Placed</th>
                              <th>Points Spent</th>
                              <th>Points Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {robotRacerTopLosers?.length > 0 &&
                              robotRacerTopLosers?.slice(0, 10)?.map((loser, index) => (
                                <tr key={loser?._id}>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2">
                                          <img
                                            src={
                                              loser?.user?.image
                                                ? process.env.REACT_APP_CDN + loser?.user?.image?.small
                                                : userIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          {loser?.user?.firstName} {loser?.user?.lastName}
                                          <br />
                                          {loser?.user?.userName} <br />
                                          {loser?.user?.telegramId}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{loser?.totalBetsPlaced}</td>
                                  <td>
                                    {loser?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {robotRacerTopLosers?.length === 0 &&
                          (robotRacerTopLosersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>User Engagement</h4>
                        <Link to="#" className="back-link-btn m-0" onClick={toggleViewUserEngagement}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>Total Time Spent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {robotRacerTopUsersByTimeSpent?.length > 0 &&
                              robotRacerTopUsersByTimeSpent?.slice(0, 10)?.map((user, index) => (
                                <tr key={user?._id}>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2">
                                          <img
                                            src={
                                              user?.user?.image
                                                ? process.env.REACT_APP_CDN + user?.user?.image?.small
                                                : userIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          {user?.user?.firstName} {user?.user?.lastName}
                                          <br />
                                          {user?.user?.userName} <br />
                                          {user?.user?.telegramId}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{msToHMS(user?.totalSpendTime)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {robotRacerTopUsersByTimeSpent?.length === 0 &&
                          (robotRacerTopUsersByTimeSpentLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ViewAllFlyer
        type={type}
        players={type === "winner" ? robotRacerTopWinners : robotRacerTopLosers}
        loadMore={type === "winner" ? props.loadRobotRacerTopWinners : props.loadRobotRacerTopLosers}
        pagination={type === "winner" ? robotRacerTopWinnersPagination : robotRacerTopLosersPagination}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
      />
      <AllUserEngagementFlyer
        topUsersByTimeSpent={robotRacerTopUsersByTimeSpent}
        loadMore={props.loadRobotRacerTopUsersByTimeSpent}
        pagination={robotRacerTopUsersByTimeSpentPagination}
        showModal={showAllUserEngagement}
        toggleModal={toggleViewUserEngagement}
      />
      {(showViewAll || showAllUserEngagement) && (
        <div
          className="modal-backdrop show fade"
          onClick={showViewAll ? toggleViewAll : toggleViewUserEngagement}
        ></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadRobotRacerDashboard: (callback) => dispatch(loadRobotRacerDashboard(callback)),
  loadRobotRacerTopWinners: (pramas, fresh, callback) => dispatch(loadRobotRacerTopWinners(pramas, fresh, callback)),
  loadRobotRacerTopLosers: (pramas, fresh, callback) => dispatch(loadRobotRacerTopLosers(pramas, fresh, callback)),
  loadRobotRacerTopUsersByTimeSpent: (params, fresh, callback) =>
    dispatch(loadRobotRacerTopUsersByTimeSpent(params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TelegramRobotRacerStats));
