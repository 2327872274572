import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import { Offcanvas } from "react-bootstrap";

const AnalyticsAllDataFlyer = ({ data, showModal, toggleModal, title, col1Name, col2Name }) => {
  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>{title}</h5>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          <div className="table-responsive">
            {data?.length === 0 ? (
              <div className="fwc-body">
                <div className="no-record-found-container">
                  <div className="nfr-box">
                    <img src={emptyIcon} alt="No Record Found" />
                    <div className="nrf-text">
                      <h5>No Records Found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table custom-table-striped">
                  <thead>
                    <tr>
                      <th>{col1Name}</th>
                      <th>{col2Name}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((row, index) => (
                      <tr key={index}>
                        <td className="camelCase">{row?.col1}</td>
                        <td>{row?.col2}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AnalyticsAllDataFlyer;
