import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import placeholderJson from "include/json/placeholder-img.json";

//  Components
import Amplify, { Auth, Storage } from "aws-amplify";
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Lottie from "react-lottie-player";
import { addGameImages } from "store/telegram";
import { v4 as uuidv4 } from "uuid";

//  Amplify Configuration
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class UploadBattleGameImageFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      type: "",
      images: [],
    },
    errors: {},
    loadingImage: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };

    this.setState({ loading: true });

    const payLoad = {
      type: data?.type?.value,
    };
    if (data?.images?.length) payLoad.images = data?.images;
    this.props.addGameImages(payLoad, (res) => {
      this.setState({
        loading: false,
      });
      if (res?.status === 200) {
        toast(<AlertSuccess message="Images uploaded successfully" />, {
          containerId: 1,
        });
        this.props.reload();
        this.props.toggleModal();
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    type: Joi.object().required(),
    images: Joi.array().min(1).required(),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        type: "",
        images: [],
      },
      errors: {},
    });
  };

  handleImageChange = (e) => {
    this.setState({ loadingImage: true });
    // Array to hold promises for each file upload
    const uploadPromises = [];

    // Iterate over each selected file
    for (const file of e.target.files) {
      // Calculate file size in MB
      const fSize = Math.round(file?.size / 1048576);
      // Get the file type
      const fType = file?.type;
      // Get the file extension
      const ext = file?.name.split(".").pop();
      // Check if file size exceeds 25MB
      if (fSize > 25) {
        toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
          containerId: 1,
        });
        return this.setState({ loadingImage: false });
      } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
          {
            containerId: 1,
          },
        );
        return this.setState({ loadingImage: false });
      } else {
        const fileName = uuidv4() + "." + ext;
        const uploadPromise = Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {},
          contentType: fType,
        }).then((result) => {
          this.setState({
            // Update the state with the new image URL
            data: {
              ...this.state.data,
              images: [...this.state.data?.images, "public/" + result.key],
            },
          });
        });
        // Add the upload promise to the array of promises
        uploadPromises.push(uploadPromise);
      }
    }
    // Set loadingImage to false after all uploads are complete
    Promise.allSettled(uploadPromises)
      .then(() => {
        this.setState({ loadingImage: false });
      })
      .catch(() => {
        this.setState({ loadingImage: false });
      });
    e.target.value = null;
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];

    this.setState({ data, errors });
  };
  typeOptions = [
    // { name: "Character", value: "character" },
    { name: "Enemy", value: "enemy" },
    { name: "Gear", value: "gear" },
    { name: "Reward", value: "reward" },
    { name: "Stage", value: "stage" },
    { name: "Status Effect", value: "statusEffect" },
  ];
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>Battle Game Images Upload</h5>{" "}
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="new-form-group">
                  <label className="form-label">Type</label>
                  <SelectSearch
                    name="type"
                    options={this.typeOptions}
                    label="Select Type"
                    value={this.state.data.type}
                    error={this.state.errors.type}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            {this.state.data?.images?.length > 0 && (
              <div className="new-form-group row">
                {this.state.data?.images?.map((img, index) => (
                  <div
                    className={
                      "kt-upload-outer kt-upload-outer-banner-3 kt-upload-outer-banner-2-h-100  position-relative"
                    }
                    key={index}
                  >
                    <div className="kt-upload-img" htmlFor="uploadImg">
                      <img src={process.env.REACT_APP_CDN + img} alt="" />
                    </div>
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = { ...this.state.data };
                        data?.images?.splice(index, 1);
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="new-form-group">
              <div className="kt-upload-row d-flex flex-wrap align-items-start">
                {!this.state.loadingImage ? (
                  <div
                    className={`kt-upload-outer kt-upload-outer-banner-3 placeholder-uploaded-img position-relative
                    ${this.state.errors.images && "border-error"}`}
                    style={{ width: 450 }}
                  >
                    <label htmlFor="formFile" className="kt-upload-img w-100 p-0 h-100" role="button">
                      <img src={plusIcon} alt="" />
                    </label>
                    <input
                      className="form-control position-absolute h-100 top-0 start-0 opacity-0"
                      type="file"
                      id="formFile"
                      onChange={this.handleImageChange}
                      multiple
                      accept="image/*"
                    />
                  </div>
                ) : (
                  <div
                    className="kt-upload-outer  kt-upload-outer-banner-3 position-relative loading"
                    style={{ width: 450 }}
                  >
                    <div className="lottie-player-box">
                      <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                    </div>
                  </div>
                )}
                <div className="kt-file-input m-2">
                  <label
                    htmlFor="formFile"
                    className={"kt-label" + (this.state.loadingImage ? " pointer-none" : "")}
                    id="uploadImg"
                  >
                    + Upload Images
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Send"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addGameImages: (data, callback) => dispatch(addGameImages(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(UploadBattleGameImageFlyer));
