import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { Component, createRef } from "react";
import { Link, withRouter } from "react-router-dom";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { getTelegram, loadDailyTournament } from "store/telegram";
import AddDailyTournament from "./addDailyTournament";
import LeaderboardFlyer from "./leaderboardFlyer";
import { isDayGreaterThanTodayInUTC, isDayLessThanTodayInUTC } from "utils/misc";

class DailyTournamentListings extends Component {
  state = {
    page: 1,
    loadingMain: false,
    showAddModal: false,
    showLeaderboard: false,
    editData: null,
  };
  ref = createRef(null);
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadDailyTournament({}, () => {
      this.setState({ loadingMain: false });
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.dailyTournamentLoading &&
      this.props.getTelegram.dailyTournamentLoading !== prevProps.getTelegram.dailyTournamentLoading
    ) {
      this.ref.current.complete();
    }
    if (
      this.props.getTelegram.dailyTournamentLoading &&
      this.props.getTelegram.dailyTournamentLoading !== prevProps.getTelegram.dailyTournamentLoading
    ) {
      this.ref.current.continuousStart();
    }
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      this.setState({ editData: null });
    }
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  searchFilters = () => {
    this.props.loadDailyTournament({ page: this.state.page });
  };
  toggleLeaderboardModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showLeaderboard: !this.state.showLeaderboard });
  };

  render() {
    const { dailyTournament } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-daily-tournament" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1 className="camelCase">Daily Tournament</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link
                      to="#"
                      onClick={this.toggleAddModal}
                      className="btn btn-default btn-sm d-flex justify-content-center"
                    >
                      <img src={plusIcon} alt="" /> Add a Daily Tournament
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loadingMain && (
                    <div className="table-responsive">
                      {dailyTournament?.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Actions</th>
                              <th style={{ width: "22%" }}>BG Game Name</th>
                              <th style={{ width: "22%" }}>Partner Logo</th>
                              <th>Status</th>
                              <th>Prize Pool</th>
                              <th>Reward Status</th>
                              <th>Max Number Of Entries</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dailyTournament?.map((tournament, ind) => (
                              <tr>
                                <td>
                                  <Dropdown className=" custom-dropdown">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className="custom-dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={carrentIcon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                      {tournament?.date && isDayGreaterThanTodayInUTC(tournament?.date) && (
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ editData: tournament }, () => {
                                              this.toggleAddModal();
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState({ leaderboardTournament: tournament }, () => {
                                            this.toggleLeaderboardModal(e);
                                          });
                                        }}
                                      >
                                        Leaderboard
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>{tournament?.bgGame?.name}</td>
                                <td>
                                  <div className="td-img margin-right-2 ">
                                    <img
                                      src={
                                        tournament.partnerLogo.includes("https://")
                                          ? tournament.partnerLogo
                                          : process.env.REACT_APP_CDN + tournament.partnerLogo
                                      }
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td className="ust-tag">
                                  <span
                                    className={`w-100 text-center d-block camelCase ${
                                      isDayLessThanTodayInUTC(tournament?.date)
                                        ? "suspended"
                                        : isDayGreaterThanTodayInUTC(tournament?.date)
                                        ? "inactive"
                                        : "active"
                                    } `}
                                  >
                                    {isDayLessThanTodayInUTC(tournament?.date)
                                      ? "Ended"
                                      : isDayGreaterThanTodayInUTC(tournament?.date)
                                      ? "Scheduled"
                                      : "Active"}
                                  </span>
                                </td>
                                <td>{tournament?.prizePool?.toLocaleString("en-us") ?? 0} PTS</td>
                                <td className="ust-tag">
                                  <span
                                    className={`w-100 text-center d-block camelCase ${
                                      tournament?.isRewardTransfered ? "active" : "suspended"
                                    } `}
                                  >
                                    {tournament?.isRewardTransfered ? "YES" : "NO"}
                                  </span>
                                </td>
                                <td>{tournament?.numberOfEntries?.toLocaleString("en-us") ?? 0}</td>{" "}
                                <td>{tournament?.date && moment(tournament.date).format("MM/DD/YYYY")}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && dailyTournament?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {dailyTournament?.length >= 20 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${dailyTournament?.length < 20 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddDailyTournament
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          edit={this.state.editData}
        />
        <LeaderboardFlyer
          leaderboardTournament={this.state.leaderboardTournament}
          showModal={this.state.showLeaderboard}
          toggleModal={this.toggleLeaderboardModal}
        />
        {(this.state.showAddModal || this.state.showLeaderboard) && (
          <div
            className="modal-backdrop show fade"
            onClick={this.state.showAddModal ? this.toggleAddModal : this.toggleLeaderboardModal}
          ></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadDailyTournament: (params, callback) => dispatch(loadDailyTournament(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DailyTournamentListings));
