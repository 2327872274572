import Joi from "joi-browser";
import { Offcanvas } from "react-bootstrap";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import AlertModal from "common/alert/alertModal";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";

class EditUser extends Form {
  state = {
    data: {
      reason: "",
    },
    errors: {},
    showSuspendUser: false,
    loading: false,
    loadingSuspend: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    this.suspendModalToggle(e);
  };

  schema = {
    reason: Joi.string()
      .max(500)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Reason is required.";
              break;
          }
        });
        return errors;
      }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        reason: "",
      },
      errors: {},
      showSuspendUser: false,
      loadingSuspend: false,
    });
  };
  suspendModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showSuspendUser: !this.state.showSuspendUser });
  };
  render() {
    return (
      <>
        <Offcanvas
          className={`offcanvas offcanvas-end custom-offcanvas-pannel ${this.state.showSuspendUser && "invisible"}`}
          show={this.props.showModal && !this.state.showSuspendUser}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header>
            <h5>Suspend User</h5>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="new-form-group">
              {this.renderTextarea("reason", "Reason", "Enter a reason to suspend the user")}
            </div>{" "}
          </Offcanvas.Body>
          <div className="offcanvas-footer">
            <NextButton
              classData="btn btn-default btn-block h-100"
              label="Save"
              loading={this.state.loading}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </Offcanvas>{" "}
        <AlertModal
          alertModalToggle={this.suspendModalToggle}
          alertModalShow={this.state.showSuspendUser}
          title="Alert"
          description="Are you sure you want to suspend the user? User will not be allowed to login after this action."
          onPress={(e) => {
            this.setState({ loading: true });
            this.props.handleSuspendUser(e, -1, this.state.data.reason, () => {
              this.suspendModalToggle();
              this.props.toggleModal();
              this.setState({ loading: false });
            });
          }}
          loading={this.state.loading}
          alertType="Suspend"
        />
      </>
    );
  }
}

export default EditUser;
