import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

const ViewAllFlyer = ({ type, players, loadMore, pagination, showModal, toggleModal }) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);

  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && pagination?.currentPage < pagination?.totalPages) {
          setLoadingMore(true);
          const params = { page: pagination?.currentPage + 1 };
          loadMore(params, false, () => setLoadingMore(false));
        }
      }
    },
    [loadingMore, pagination, loadMore],
  );
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);

  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2 "}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>Top {type === "winner" ? "Winners" : "Losers"}</h5>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          <div className="table-responsive">
            {players?.length === 0 ? (
              <div className="fwc-body">
                <div className="no-record-found-container">
                  <div className="nfr-box">
                    <img src={emptyIcon} alt="No Record Found" />
                    <div className="nrf-text">
                      <h5>No Records Found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table custom-table-striped">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>No of Times Bets Placed</th>
                      <th>Points Spent</th>
                      <th>Points Won</th>
                      <th>Profit/Loss</th>
                    </tr>
                  </thead>
                  <tbody>
                    {players?.map((winner, index) => (
                      <tr
                        key={winner?._id}
                        ref={(ref) => {
                          index === players.length - 1 &&
                            pagination?.currentPage < pagination?.totalPages &&
                            setScrollRef(ref);
                        }}
                      >
                        <td>
                          <span>
                            <div className="td-img-group d-flex align-items-start ">
                              <div className="td-img round-img m-right-2">
                                <img
                                  src={
                                    winner?.user?.image
                                      ? process.env.REACT_APP_CDN + winner?.user?.image?.small
                                      : userIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                {winner?.user?.firstName} {winner?.user?.lastName}
                                <br />
                                {winner?.user?.userName} <br />
                                {winner?.user?.telegramId}
                              </div>
                            </div>
                          </span>
                        </td>
                        <td>{winner?.totalBetsPlaced}</td>
                        <td>
                          {winner?.totalBetAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 4,
                          }) ?? 0}
                        </td>
                        <td>
                          {winner?.totalWinningAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 4,
                          }) ?? 0}
                        </td>
                        <td>
                          {winner?.profitDifference?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 4,
                          }) ?? 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {loadingMore && (
          <div className="w-100 d-flex justify-content-center load-more">
            <img src={loadingIcon} className="fa-spin" alt="" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewAllFlyer;
