import Amplify, { Auth, Storage } from "aws-amplify";
import moment from "moment";
import { createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Joi from "joi-browser";
import { addStatusEffectsCsv, getTelegram, loadStatusEffectsCsv } from "store/telegram";
import SelectSearch from "common/form/selectSearch";

//  Images
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import filterIcon from "include/images/filter-icon.svg";
import closeIcon from "include/images/close-icon.svg";
//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import CsvListingsFlyer from "../flyers/csvListingsFlyer";
import NextButton from "common/form/submitButton";
import { isEmpty, pickBy, startCase } from "lodash";
import SortIcon from "common/sortIcon";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class StatusEffectsCsvList extends Form {
  ref = createRef(null);
  initialState = {
    loading: false,
    loadingFilters: false,
    data: {
      name: "",
      type: null,
      subType: "",
      element: null,
      pulses: "",
      damage: "",
      perLevelBonus: "",
      statusValue1: "",
      statusValue2: "",
      targets: "",
      status: "",
    },
    appliedFilters: {},
    errors: {},
    sorting: { sort: "createdAt", order: "desc" },
    showFilters: false,
    page: 1,
    showCsvModal: false,
  };
  state = { ...structuredClone(this.initialState) };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.searchFilters();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.statusEffectsCsvLoading &&
      this.props.getTelegram.statusEffectsCsvLoading !== prevProps.getTelegram.statusEffectsCsvLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.statusEffectsCsvLoading &&
      this.props.getTelegram.statusEffectsCsvLoading !== prevProps.getTelegram.statusEffectsCsvLoading
    )
      this.ref.current.continuousStart();
  };
  searchFilters = () => {
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const payLoad = {
      ...data,
      type: data?.type?.value,
      element: data?.element?.value,
      sort: sorting?.sort,
      order: sorting?.order,
    };
    const params = pickBy(payLoad, function (value, key) {
      return value !== "";
    });

    params.page = this.state.page;
    this.setState({ loadingFilters: true });

    this.props.loadStatusEffectsCsv(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
        });
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };
  handleCsvUpload = (e) => {
    this.ref.current.continuousStart();
    const fType = e.target.files[0].type;
    const fName = e.target.files[0].name;
    const ext = e.target.files[0].name.split(".").pop();
    if (!["text/csv"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid format is csv only" />,
        {
          containerId: 1,
        },
      );
      this.ref.current.complete();
      e.target.value = null;
      return;
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put("telegramBattleGame/csv/statusEffect/" + fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        const data = { fileName: fName, filePath: "public/" + result.key };
        this.props.addStatusEffectsCsv(data, (response) => {
          this.ref.current.complete();

          e.target.value = null;
          if (response.status === 200) {
            this.setState({ page: 1 }, () => this.searchFilters());

            toast(<AlertSuccess message="Information Saved" />, {
              containerId: 1,
            });
          } else {
            toast(<AlertError message={response.data && response.data.message} />, {
              containerId: 1,
            });
          }
        });
      });
    }
  };
  toggleShowCsvModal = () => {
    this.setState({ showCsvModal: !this.state.showCsvModal });
  };
  typeOptions = [
    "Stun",
    "Speed",
    "Aoe",
    "Dot",
    "Hot",
    "Armor",
    "Buff",
    "Debuff",
    "Rage",
    "Dodge",
    "Shield",
    "Energized",
    "Power Up",
    "Knockback",
    "Knockdown",
  ];

  elementOptions = ["Water", "Fire", "Earth", "Air", "Neutral"];

  schema = {
    name: Joi.string().allow(""),
    type: Joi.object().allow(null),
    subType: Joi.string().allow(""),
    element: Joi.object().allow(null),
    pulses: Joi.number().min(0.1).allow(""),
    damage: Joi.number().min(0.1).allow(""),
    perLevelBonus: Joi.number().min(0.1).allow(""),
    statusValue1: Joi.number().min(0.1).allow(""),
    statusValue2: Joi.number().min(0.1).allow(""),
    targets: Joi.number().min(0.1).allow(""),
    status: Joi.string().allow(""),
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState({ ...structuredClone(this.initialState), showFilters: true }, () => this.searchFilters());
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    sorting.order = sorting?.sort === type && sorting.order === "desc" ? "asc" : "desc";
    sorting.sort = type;

    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { statusEffectsCsv, statusEffectsCsvTotalCount } = this.props.getTelegram;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-battleGame-status-effects" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Battle Game Status Effects {statusEffectsCsvTotalCount > 0 && `(${statusEffectsCsvTotalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    className="colored-link orange-text"
                    href={process.env.REACT_APP_CDN + "telegramBattleGame/sampleCsv/statusEffect.csv"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Sample CSV
                  </a>{" "}
                </div>{" "}
                <div className="fmb-box">
                  <Link className="colored-link orange-text" to="#" onClick={this.toggleShowCsvModal}>
                    CSV logs
                  </Link>
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} alt="filter" /> <span>Filters</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <div className="kt-file-input">
                    <label htmlFor="formFile" className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Import CSV
                    </label>
                    <input className="form-control" type="file" id="formFile" onChange={this.handleCsvUpload} />
                  </div>
                </div>
              </div>
            </div>
            {!this.state.loading && (
              <div className="fwc-wrapper">
                {this.state.showFilters && (
                  <div className="fwc-head ">
                    <div className="fwc-inner">
                      <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("name", "Name")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Type</label>
                            <SelectSearch
                              name="type"
                              options={this.typeOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.type}
                              error={this.state.errors?.type}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("subType", "Subtype")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Element</label>
                            <SelectSearch
                              name="element"
                              options={this.elementOptions?.map((value) => ({ name: value, value }))}
                              label=""
                              value={this.state.data?.element}
                              error={this.state.errors?.element}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("pulses", "Pulses", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("damage", "Damage", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            {this.renderInput("perLevelBonus", "Per Level Bonus", "", "number")}
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            {this.renderInput("statusValue1", "Status Value 1", "", "number")}
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            {this.renderInput("statusValue2", "Status Value 2", "", "number")}
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("targets", "Targets", "", "number")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("status", "Status")}</div>
                        </li>
                      </ul>
                      <div className="fwc-btn d-flex align-items-center justify-content-end">
                        <NextButton
                          handleSubmit={this.resetFilters}
                          classData="btn-text pointer"
                          label="Reset"
                          loading={this.state.loadingReset}
                        />
                        <NextButton
                          handleSubmit={() => {
                            this.setState(
                              {
                                page: 1,
                              },
                              () => this.searchFilters(),
                            );
                          }}
                          classData="btn btn-primary btn-sm"
                          label="Search"
                          loading={this.state.loadingFilters}
                        />
                      </div>
                    </div>{" "}
                    {!isEmpty(this.state.appliedFilters) && (
                      <div className="filter-tag-container d-flex align-items-center ">
                        <div className="fwc-left">
                          <div className="filter-tag-list">
                            <p>filter applied :</p>
                            {Object.keys(this.state.appliedFilters).map((key) => (
                              <div className="filter-tag-item">
                                <span className="camelCase">{startCase(key)}</span> :{" "}
                                {this.state.appliedFilters[key]?.value ?? this.state.appliedFilters[key]}
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      {
                                        data: { ...this.state.data, [key]: "" },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}{" "}
                <div className="fwc-body">
                  <div className="table-responsive">
                    {statusEffectsCsv?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>
                              Status Effect ID
                              <SortIcon
                                sortIconType="statusEffectId"
                                handleSort={(e) => this.sort(e, "statusEffectId")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Name
                              <SortIcon
                                sortIconType="name"
                                handleSort={(e) => this.sort(e, "name")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Type
                              <SortIcon
                                sortIconType="type"
                                handleSort={(e) => this.sort(e, "type")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Subtype
                              <SortIcon
                                sortIconType="subType"
                                handleSort={(e) => this.sort(e, "subType")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Element
                              <SortIcon
                                sortIconType="element"
                                handleSort={(e) => this.sort(e, "element")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Pulses
                              <SortIcon
                                sortIconType="pulses"
                                handleSort={(e) => this.sort(e, "pulses")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Damage
                              <SortIcon
                                sortIconType="damage"
                                handleSort={(e) => this.sort(e, "damage")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Per Level Bonus
                              <SortIcon
                                sortIconType="perLevelBonus"
                                handleSort={(e) => this.sort(e, "perLevelBonus")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Status Value 1
                              <SortIcon
                                sortIconType="statusValue1"
                                handleSort={(e) => this.sort(e, "statusValue1")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Status Value 2
                              <SortIcon
                                sortIconType="statusValue2"
                                handleSort={(e) => this.sort(e, "statusValue2")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Targets
                              <SortIcon
                                sortIconType="targets"
                                handleSort={(e) => this.sort(e, "targets")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>Image Name</th>
                            <th>
                              Status
                              <SortIcon
                                sortIconType="status"
                                handleSort={(e) => this.sort(e, "status")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                            <th>
                              Date
                              <SortIcon
                                sortIconType="createdAt"
                                handleSort={(e) => this.sort(e, "createdAt")}
                                sort={this.state.sorting?.sort}
                                order={this.state.sorting?.order}
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {statusEffectsCsv?.map((statusEffect, i) => (
                            <tr key={i} className={statusEffect?.status === "Deleted" ? "deleted-col" : ""}>
                              <td className="ps-3">{statusEffect?.statusEffectId}</td>
                              <td>{statusEffect?.name}</td>
                              <td>{statusEffect?.type}</td>
                              <td>{statusEffect?.subType}</td>
                              <td>{statusEffect?.element}</td>
                              <td>{statusEffect?.pulses}</td>
                              <td>{statusEffect?.damage}</td>
                              <td>{statusEffect?.perLevelBonus}</td>
                              <td>{statusEffect?.statusValue1}</td>
                              <td>{statusEffect?.statusValue2}</td>
                              <td>{statusEffect?.targets}</td>
                              <td>
                                <div style={{ width: 60 }}>
                                  {statusEffect?.imageName && (
                                    <img
                                      src={
                                        process.env.REACT_APP_CDN +
                                        "telegramBattleGame/image/statusEffect/" +
                                        statusEffect?.imageName
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                              </td>
                              <td>{statusEffect?.status}</td>
                              <td>{moment(statusEffect?.createdAt).format("ll")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {!this.state.loading && !(this.state.page === 1 && statusEffectsCsv?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {statusEffectsCsv?.length >= 100 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${statusEffectsCsv?.length < 100 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}{" "}
                </div>
              </div>
            )}
          </div>
        </div>
        <CsvListingsFlyer
          type={"statusEffect"}
          showModal={this.state.showCsvModal}
          toggleModal={this.toggleShowCsvModal}
        />
        {this.state.showCsvModal && <div className="modal-backdrop show fade" onClick={this.toggleShowCsvModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadStatusEffectsCsv: (params, callback) => dispatch(loadStatusEffectsCsv(params, callback)),
  addStatusEffectsCsv: (data, callback) => dispatch(addStatusEffectsCsv(data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusEffectsCsvList));
