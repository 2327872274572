import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import { Offcanvas } from "react-bootstrap";

const ReferrersFlyer = ({ referrerUsers, showModal, toggleModal }) => {
  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header">
        <div>
          <h5>Top Referrers</h5>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          <div className="table-responsive">
            {referrerUsers?.length === 0 ? (
              <div className="fwc-body">
                <div className="no-record-found-container">
                  <div className="nfr-box">
                    <img src={emptyIcon} alt="No Record Found" />
                    <div className="nrf-text">
                      <h5>No Records Found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table custom-table-striped">
                  <thead>
                    <tr>
                      <th>Users</th>
                      <th style={{ width: 150 }}>Referral Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrerUsers?.map((user, index) => (
                      <tr key={index}>
                        <td className="camelCase">
                          <span>
                            <div className="td-img-group d-flex align-items-start ">
                              <div className="td-img round-img m-right-2 pointer">
                                <img
                                  src={user.image ? process.env.REACT_APP_CDN + user.image.small : userIcon}
                                  alt=""
                                />
                              </div>

                              <div>
                                {user?.firstName} {user?.lastName}
                                <br />
                                {user?.userName} <br />
                                {user?.telegramId}
                              </div>
                            </div>
                          </span>
                        </td>
                        <td>{user?.referredUsersCount?.toLocaleString("en-us")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReferrersFlyer;
