import Loader from "common/loader";
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTelegram, loadBattleGameCSVs } from "store/telegram";
import { camelCaseToNormal } from "utils/misc";

const CsvListingsFlyer = ({ type, loadBattleGameCSVs, getTelegram, showModal, toggleModal }) => {
  const ref = useRef();
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [scrollRef, setScrollRef] = useState(null);
  const { battleGameCsvs, battleGameCsvsTotalCount } = getTelegram;

  useEffect(() => {
    if (showModal) {
      setLoadingMain(true);
      loadBattleGameCSVs({ type, page: 1 }, true, () => setLoadingMain(false));
    }
  }, [showModal]);

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && battleGameCsvs?.length < battleGameCsvsTotalCount) {
          setLoadingMore(true);
          const params = { type, page: page + 1 };
          setPage(page + 1);
          loadBattleGameCSVs(params, false, () => setLoadingMore(false));
        }
      }
    },
    [loadingMore, type, page, loadBattleGameCSVs, battleGameCsvs?.length, battleGameCsvsTotalCount],
  );

  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);

  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2 "}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <Loader loaderRef={ref} />
        <div>
          <h5 className="text-capitalize">
            {camelCaseToNormal(type)} CSV Logs {battleGameCsvsTotalCount > 0 && `(${battleGameCsvsTotalCount})`}
          </h5>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          {!loadingMain && (
            <div className="table-responsive">
              {battleGameCsvs?.length === 0 ? (
                <div className="fwc-body">
                  <div className="no-record-found-container">
                    <div className="nfr-box">
                      <img src={emptyIcon} alt="No Record Found" />
                      <div className="nrf-text">
                        <h5>No Records Found!</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table large-table">
                    <thead>
                      <tr>
                        <th>FILE NAME</th>
                        <th>NUMBER OF RECORDS</th>
                        <th>STATUS</th>
                        <th>START DATE & TIME</th>
                        <th>END DATE & TIME</th>
                        <th>LOGS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {battleGameCsvs?.map((csv, index) => (
                        <tr
                          key={csv?._id}
                          ref={(ref) => {
                            index === battleGameCsvs.length - 1 &&
                              battleGameCsvs?.length < battleGameCsvsTotalCount &&
                              setScrollRef(ref);
                          }}
                        >
                          <td>
                            <a
                              className="colored-link orange-text"
                              href={process.env.REACT_APP_CDN + csv?.filePath}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {csv?.fileName}
                            </a>
                          </td>
                          <td>{csv?.processStatus === "Completed" && JSON.parse(csv?.log).length}</td>
                          <td>{csv?.processStatus}</td>
                          <td>{moment(csv?.createdAt).format("llll")}</td>
                          <td>{csv?.processStatus === "Completed" && moment(csv?.updatedAt).format("llll")}</td>
                          <td>
                            {csv?.log && (
                              <CSVLink
                                filename={csv?.fileName?.split(".")[0] + "-log.csv"}
                                className="colored-link orange-text"
                                data={_.map(JSON.parse(csv?.log), function (row) {
                                  return _.omit(row, ["options"]);
                                })}
                                target="_blank"
                              >
                                Download
                              </CSVLink>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
        {loadingMore && (
          <div className="w-100 d-flex justify-content-center load-more">
            <img src={loadingIcon} className="fa-spin" alt="" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadBattleGameCSVs: (pramas, fresh, callback) => dispatch(loadBattleGameCSVs(pramas, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CsvListingsFlyer));
