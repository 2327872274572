import moment from "moment-timezone";

export const convertDate = (date, format) => {
  return moment(date).tz("America/New_York");
};
export const formatDate = (date, format) => {
  return moment(date).tz("America/New_York").format(format);
};
export const dateAsUtc = (date, format) => {
  const dateObj = moment(date);
  const offSet = dateObj?.utcOffset();
  return dateObj?.add(offSet, "minutes")?.format(format);
};
