import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment/moment";

const slice = createSlice({
  name: "analytics",
  initialState: {
    telegramSpinReward: [],
    telegramUsdtWithdrawals: [],
    telegramUsers: [],
    telegramSetting: [],
    telegramUserTransactions: [],
    telegramChallenges: [],
    telegramChallengesTotalCount: 0,
    verisoulMultipleTelegramAccounts: [],
    verisoulMultipleTelegramAccountsById: [],
    verisoulTelegramAuthResults: [],
    verisoulAuthResultsById: [],
    recentVerisoulAuthResultById: {},
    loading: false,
    loadingChallenges: false,
    verisoulMultipleTelegramAccountsByIdLoading: false,
    verisoulTelegramAuthResultsLoading: false,
    verisoulMultipleTelegramAccountsLoading: false,
    totalCount: 0,
    lastFetch: null,
    totalChallengesCount: 0,
    verisoulMultTelegramAccFilteredCount: 0,
    verisoulMultTelegramAccTotalCount: 0,
    telegramUserTransactionsTotalCount: 0,
    prizes: [],
    sweepstakes: [],
    sweepstakePagination: null,
    sweepstakesTemplate: [],
    verisoulTelegramAuthTotalCount: 0,
    verisoulAuthResultsByIdTotalCount: 0,
    verisoulTelegramAuthFilteredCount: 0,
    participants: [],
    participantsPagination: {},
    winners: [],
    teamPlayChallenges: [],
    dailyTournamentLeaderboard: [],
    teamPlayTotalCount: 0,
    quests: [],
    questsLoading: false,
    dailyTournament: [],
    dailyTournamentLoading: false,
    loadingMore: false,
    dailyTournamentLeaderboardLoading: false,
    telegramGames: [],
    telegramGamesTotalCount: 0,
    telegramGamesLoading: false,
    recentVerisoulAuthResultByIdLoading: {},
    teammates: [],
    loadingTeammates: false,
    teammatesTotalCount: 0,
    teammatesFilteredCount: 0,
    cowitzerDashboardloading: false,
    cowitzerDashboard: {},
    cowitzerTopWinners: [],
    cowitzerTopWinnersPagination: {},
    cowitzerTopWinnersLoading: false,
    cowitzerTopLosers: [],
    cowitzerTopLosersLoading: false,
    cowitzerTopLosersPagination: {},
    zedRunDashboard: {},
    zedRunTopWinners: [],
    zedRunTopWinnersPagination: {},
    zedRunTopLosers: [],
    zedRunTopLosersPagination: {},
    zedRunTopWinnersLoading: false,
    zedRunTopLosersLoading: false,
    zedRunTopUsersByTimeSpent: [],
    zedRunTopUsersByTimeSpentPagination: {},
    zedRunTopUsersByTimeSpentLoading: false,
    robotRacerDashboard: {},
    robotRacerDashboardLoading: false,
    robotRacerTopWinners: [],
    robotRacerTopWinnersPagination: {},
    robotRacerTopLosers: [],
    robotRacerTopLosersPagination: {},
    robotRacerTopWinnersLoading: false,
    robotRacerTopLosersLoading: false,
    robotRacerTopUsersByTimeSpent: [],
    robotRacerTopUsersByTimeSpentPagination: {},
    robotRacerTopUsersByTimeSpentLoading: false,
    telegramNotifications: [],
    telegramNotificationsTotalCount: 0,
    telegramNotificationsLoading: false,
    telegramStatsLoading: false,

    // Analytics
    sweepstakeAnalyticsDashboard: null,
    engagementAnalyticsDashboard: null,
    engagementAnalyticsGames: null,
    engagementAnalyticsHourlyUserCounts: null,
    engagementAnalyticsHourlyGamesCounts: null,
    engagementAnalyticsHourlyEngagementCounts: null,
    appLaunchData: [],
    userAnalyticsDashboard: null,
    dailyStatsDashboard: [],
    analyticsLoading: false,
    userAnalyticsCountries: [],
    userAnalyticsCountriesLoading: false,
    spinRewardAnalytics: [],
    spinRewardAnalyticsLoading: false,
    userAnalyticsReferrals: {},
    userAnalyticsReferralsLoading: false,
    charactersCsvLoading: false,
    charactersCsv: [],
    charactersCsvTotalCount: 0,
    stagesCsvLoading: false,
    stagesCsv: [],
    stagesCsvTotalCount: 0,
    statusEffectsCsvLoading: false,
    statusEffectsCsv: [],
    statusEffectsCsvTotalCount: 0,
    rewardsCsvLoading: false,
    rewardsCsv: [],
    rewardsCsvTotalCount: 0,
    enemiesCsvLoading: false,
    enemiesCsv: [],
    enemiesCsvTotalCount: 0,
    gearsCsvLoading: false,
    gearsCsv: [],
    gearsCsvTotalCount: 0,
    gearsSubTypeLoading: false,
    gearsSubType: [],
    gearsSubTypeTotalCount: 0,
    gameImagesLoading: false,
    gameImages: [],
    gameImagesTotalCount: 0,
    battleGameCsvsLoading: false,
    battleGameCsvs: [],
    battleGameCsvsTotalCount: 0,
    battleGames: [],
    battleGamesPagination: {},
    battleGamesLoading: false,
    battleGameParticipants: [],
    battleGameParticipantsTotalEntries: 0,
    battleGameParticipantsLoading: false,
  },
  reducers: {
    telegramSpinRewardRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSpinRewardReceived: (misc, action) => {
      misc.telegramSpinReward = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSpinRewardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsdtWithdrawalsReceived: (misc, action) => {
      misc.telegramUsdtWithdrawals = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.telegramUsdtWithdrawals = misc.telegramUsdtWithdrawals?.map((request, index) => {
        const newRequest = { ...request };
        if (payLoad?.id === newRequest?._id) {
          newRequest.status = 2;
        }
        return newRequest;
      });
    },
    telegramSettingRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSettingReceived: (misc, action) => {
      misc.telegramSetting = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSettingRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsersRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsersReceived: (misc, action) => {
      misc.telegramUsers = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsersRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUserTransactionsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = action.payload.data;
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreTelegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = [...misc.telegramUserTransactions, ...action.payload.data];
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUserTransactionsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramChallengesRequested: (misc, action) => {
      misc.loadingChallenges = true;
    },
    telegramChallengesReceived: (misc, action) => {
      misc.telegramChallenges = action.payload.data;
      misc.telegramChallengesTotalCount = action.payload.totalCount;
      misc.telegramChallenges?.forEach((challenge) => {
        if (!challenge?.players) {
          challenge.players = [];
        }
        challenge?.players?.sort((a, b) => b?.highestScore - a?.highestScore);
      });
      misc.lastFetch = Date.now();
      misc.loadingChallenges = false;
    },
    telegramChallengesRequestFailed: (misc, action) => {
      misc.loadingChallenges = false;
    },
    //  Prizes
    prizesReceived: (state, action) => {
      state.prizes = action.payload.data;
      state.loading = false;
    },
    addPrizeReceived: (state, action) => {
      state.prizes = [action.payload, ...state.prizes];
      state.loading = false;
    },
    updatePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize._id === action.payload._id);
      const newPrizes = state.prizes;
      newPrizes[ind] = { ...newPrizes[ind], ...action.payload };
      state.prizes = [...newPrizes];
    },
    deletePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize._id === action.payload._id);
      const newPrizes = state.prizes;
      newPrizes.splice(ind, 1);
      state.prizes = [...newPrizes];
    },
    //  Sweepstakes
    sweepstakeReceived: (state, action) => {
      state.sweepstakes = action.payload.data;
      state.sweepstakePagination = action.payload.pagination;
      state.loading = false;
    },
    addSweepstakeReceived: (state, action) => {
      state.sweepstakes = [action.payload, ...state.sweepstakes];
      state.loading = false;
    },
    updateSweepstakeReceived: (state, action) => {
      const ind = state.sweepstakes.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakes = state.sweepstakes;
      newSweepstakes[ind] = { ...newSweepstakes[ind], ...action.payload };
      state.sweepstakes = [...newSweepstakes];
    },
    deleteSweepstakeReceived: (state, action) => {
      const ind = state.sweepstakes.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakes = state.sweepstakes;
      newSweepstakes.splice(ind, 1);
      state.sweepstakes = [...newSweepstakes];
    },
    sweepstakeTemplateReceived: (state, action) => {
      state.sweepstakesTemplate = action.payload.data;
      state.loading = false;
    },
    addSweepstakeTemplateReceived: (state, action) => {
      state.sweepstakesTemplate = [action.payload, ...state.sweepstakesTemplate];
      state.loading = false;
    },
    updateSweepstakeTemplateReceived: (state, action) => {
      const ind = state.sweepstakesTemplate.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakesTemplate = state.sweepstakesTemplate;
      newSweepstakesTemplate[ind] = { ...newSweepstakesTemplate[ind], ...action.payload };
      state.sweepstakesTemplate = [...newSweepstakesTemplate];
    },
    deleteSweepstakeTemplateReceived: (state, action) => {
      const ind = state.sweepstakesTemplate.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakesTemplate = state.sweepstakesTemplate;
      newSweepstakesTemplate.splice(ind, 1);
      state.sweepstakesTemplate = [...newSweepstakesTemplate];
    },
    sweepRequested: (state, action) => {
      state.loading = true;
      state.participantsPagination = {};
    },
    sweepRequestedLoadMore: (state, action) => {
      state.loadingMore = true;
    },
    sweepRequestFailed: (state, action) => {
      state.loading = false;
    },
    verisoulMultipleTelegramAccountsRequested: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = true;
    },
    verisoulMultipleTelegramAccountsReceived: (misc, action) => {
      misc.verisoulMultipleTelegramAccounts = action.payload.data;
      misc.verisoulMultTelegramAccFilteredCount = action.payload.filteredCount;
      misc.verisoulMultTelegramAccTotalCount = action.payload.totalCount;
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulMultipleTelegramAccountsRequestFailed: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulMultipleTelegramAccountsByIdRequested: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsByIdLoading = true;
    },
    verisoulMultipleTelegramAccountsByIdReceived: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsById = action.payload.data;
      misc.verisoulMultipleTelegramAccountsByIdLoading = false;
    },
    verisoulMultipleTelegramAccountsByIdRequestFailed: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsByIdLoading = false;
    },
    verisoulTelegramAuthResultsRequested: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = true;
    },
    verisoulTelegramAuthResultsReceived: (misc, action) => {
      misc.verisoulTelegramAuthResults = action.payload.data;
      misc.verisoulTelegramAuthFilteredCount = action.payload.filteredCount;
      misc.verisoulTelegramAuthTotalCount = action.payload.totalCount;
      misc.verisoulTelegramAuthResultsLoading = false;
    },
    verisoulTelegramAuthResultsRequestFailed: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = false;
    },
    verisoulAuthResultsByIdRequested: (misc, action) => {
      misc.verisoulAuthResultsByIdLoading = true;
    },
    verisoulAuthResultsByIdReceived: (misc, action) => {
      misc.verisoulAuthResultsById = action.payload.data;
      misc.verisoulAuthResultsByIdTotalCount = action.payload.totalCount;
      misc.verisoulAuthResultsByIdFilteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.verisoulAuthResultsByIdLoading = false;
    },
    verisoulAuthResultsByIdRequestFailed: (misc, action) => {
      misc.verisoulAuthResultsByIdLoading = false;
    },
    recentVerisoulAuthResultByIdRequested: (misc, action) => {
      misc.recentVerisoulAuthResultByIdLoading[action?.payload] = true;
    },
    recentVerisoulAuthResultByIdReceived: (misc, action) => {
      misc.recentVerisoulAuthResultById = {
        ...misc.recentVerisoulAuthResultById,
        [action?.payload?.data?.telegramUserId]: action.payload.data,
      };
      misc.lastFetch = Date.now();
    },
    recentVerisoulAuthResultByIdRequestCompleted: (misc, action) => {
      misc.recentVerisoulAuthResultByIdLoading[action?.payload] = false;
    },
    updateTelegramUserStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.telegramUsers = misc.telegramUsers.map((user, index) => {
        const newUser = { ...user };
        if (payLoad._id === newUser._id) {
          newUser.status = payLoad.status;
          if (payLoad.status === -1) {
            newUser.suspensionDetails = {
              date: moment.utc().valueOf(),
              reason: payLoad.reason,
            };
          } else if (payLoad.status === 0) {
            newUser.inactiveDetails = {
              date: moment.utc().valueOf(),
              reason: "",
            };
          } else {
            newUser.inactiveDetails = {};
          }
        }
        return newUser;
      });
    },

    sweepstakesParticipantsReceived: (state, action) => {
      state.participants = action.payload.data;
      state.participantsPagination = {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalEntries: action.payload.totalEntries,
      };
      state.loading = false;
    },

    sweepstakesParticipantsLoadMoreReceived: (state, action) => {
      state.participants = [...state.participants, ...action.payload.data];
      state.participantsPagination = {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalEntries: action.payload.totalEntries,
      };
      state.loadingMore = false;
    },

    sweepstakesWinnersReceived: (state, action) => {
      state.winners = action.payload.data;
      state.loading = false;
    },
    teamPlayChallengesRequested: (state, action) => {
      state.loading = true;
    },
    teamPlayChallengesReceived: (state, action) => {
      state.teamPlayChallenges = action.payload.data;
      state.teamPlayTotalCount = action.payload.totalCount;
      state.loading = false;
    },
    teamPlayChallengesRequestFailed: (state, action) => {
      state.loading = false;
    },
    addTeamPlayChallengeReceived: (state, action) => {
      state.teamPlayChallenges = [action.payload, ...state.teamPlayChallenges];
      state.loading = false;
    },
    updateTeamPlayChallengeReceived: (state, action) => {
      const ind = state.teamPlayChallenges.findIndex((challenge) => challenge._id === action.payload._id);
      const newChallenges = state.teamPlayChallenges;
      newChallenges[ind] = { ...newChallenges[ind], ...action.payload };
      state.teamPlayChallenges = [...newChallenges];
    },
    deleteTeamPlayChallengeReceived: (state, action) => {
      const ind = state.teamPlayChallenges.findIndex((challenge) => challenge._id === action.payload._id);
      const newChallenges = state.teamPlayChallenges;
      newChallenges.splice(ind, 1);
      state.teamPlayChallenges = [...newChallenges];
    },
    questsRequested: (misc, action) => {
      misc.questsLoading = true;
    },
    questsReceived: (misc, action) => {
      misc.quests = action.payload.data;
      misc.quests?.sort((a, b) => a?.displayOrder - b?.displayOrder);
      misc.questsLoading = false;
    },
    questsRequestFailed: (misc, action) => {
      misc.questsLoading = false;
    },
    dailyTournamentRequested: (misc, action) => {
      misc.dailyTournamentLoading = true;
    },
    dailyTournamentReceived: (misc, action) => {
      misc.dailyTournament = action.payload.data;
      misc.dailyTournamentLoading = false;
    },
    dailyTournamentRequestFailed: (misc, action) => {
      misc.dailyTournamentLoading = false;
    },
    dailyTournamentLeaderboardRequested: (misc, action) => {
      misc.dailyTournamentLeaderboardLoading = true;
    },
    dailyTournamentLeaderboardReceived: (misc, action) => {
      misc.dailyTournamentLeaderboard = action.payload.data;
      misc.dailyTournamentLeaderboardLoading = false;
    },
    dailyTournamentLeaderboardRequestFailed: (misc, action) => {
      misc.dailyTournamentLeaderboardLoading = false;
    },
    telegramGamesRequested: (misc, action) => {
      misc.telegramGamesLoading = true;
    },
    telegramGamesReceived: (misc, action) => {
      misc.telegramGames = action.payload.data;
      misc.telegramGamesTotalCount = action.payload.totalCount;
      misc.telegramGamesLoading = false;
    },
    telegramGamesRequestFailed: (misc, action) => {
      misc.telegramGamesLoading = false;
    },
    teammatesRequested: (state, action) => {
      state.loadingTeammates = true;
    },
    teammatesReceived: (state, action) => {
      state.teammates = action.payload.data;
      state.loadingTeammates = false;
      state.teammatesFilteredCount = action.payload.filteredCount;
      state.teammatesTotalCount = action.payload.totalCount;
    },
    teammatesRequestFailed: (state, action) => {
      state.loadingTeammates = false;
    },
    moreTeammatesReceived: (state, action) => {
      state.teammates = [...state.teammates, ...action.payload.data];
      state.teammatesFilteredCount = action.payload.filteredCount;
      state.teammatesTotalCount = action.payload.totalCount;
      state.loadingTeammates = false;
    },
    cowitzerDashboardRequested: (misc, action) => {
      misc.cowitzerDashboardloading = true;
    },
    cowitzerDashboardReceived: (misc, action) => {
      misc.cowitzerDashboard = action.payload;
      misc.cowitzerDashboardloading = false;
    },
    cowitzerDashboardRequestFailed: (misc, action) => {
      misc.cowitzerDashboardloading = false;
    },
    cowitzerTopWinnersRequested: (misc, action) => {
      misc.cowitzerTopWinnersLoading = true;
    },
    cowitzerTopWinnersReceived: (misc, action) => {
      misc.cowitzerTopWinners = action.payload.data;
      misc.cowitzerTopWinnersPagination = action.payload.pagination;
      misc.cowitzerTopWinnersLoading = false;
    },
    moreCowitzerTopWinnersReceived: (misc, action) => {
      misc.cowitzerTopWinners = [...misc.cowitzerTopWinners, ...action.payload.data];
      misc.cowitzerTopWinnersPagination = action.payload.pagination;
      misc.cowitzerTopWinnersLoading = false;
    },
    cowitzerTopWinnersRequestFailed: (misc, action) => {
      misc.cowitzerTopWinnersLoading = false;
    },
    cowitzerTopLosersRequested: (misc, action) => {
      misc.cowitzerTopLosersLoading = true;
    },
    cowitzerTopLosersReceived: (misc, action) => {
      misc.cowitzerTopLosers = action.payload.data;
      misc.cowitzerTopLosersPagination = action.payload.pagination;
      misc.cowitzerTopLosersLoading = false;
    },
    moreCowitzerTopLosersReceived: (misc, action) => {
      misc.cowitzerTopLosers = [...misc.cowitzerTopLosers, ...action.payload.data];
      misc.cowitzerTopLosersPagination = action.payload.pagination;
      misc.cowitzerTopLosersLoading = false;
    },
    cowitzerTopLosersRequestFailed: (misc, action) => {
      misc.cowitzerTopLosersLoading = false;
    },
    zedRunDashboardRequested: (misc, action) => {
      misc.zedRunDashboardLoading = true;
    },
    zedRunDashboardReceived: (misc, action) => {
      misc.zedRunDashboard = action.payload;
      misc.zedRunDashboardLoading = false;
    },
    zedRunDashboardRequestFailed: (misc, action) => {
      misc.zedRunDashboardLoading = false;
    },
    zedRunTopWinnersRequested: (misc, action) => {
      misc.zedRunTopWinnersLoading = true;
    },
    zedRunTopWinnersReceived: (misc, action) => {
      misc.zedRunTopWinners = action.payload.data;
      misc.zedRunTopWinnersPagination = action.payload.pagination;
      misc.zedRunTopWinnersLoading = false;
    },
    moreZedRunTopWinnersReceived: (misc, action) => {
      misc.zedRunTopWinners = [...misc.zedRunTopWinners, ...action.payload.data];
      misc.zedRunTopWinnersPagination = action.payload.pagination;
      misc.zedRunTopWinnersLoading = false;
    },
    zedRunTopWinnersRequestFailed: (misc, action) => {
      misc.zedRunTopWinnersLoading = false;
    },
    zedRunTopLosersRequested: (misc, action) => {
      misc.zedRunTopLosersLoading = true;
    },
    zedRunTopLosersReceived: (misc, action) => {
      misc.zedRunTopLosers = action.payload.data;
      misc.zedRunTopLosersPagination = action.payload.pagination;
      misc.zedRunTopLosersLoading = false;
    },
    moreZedRunTopLosersReceived: (misc, action) => {
      misc.zedRunTopLosers = [...misc.zedRunTopLosers, ...action.payload.data];
      misc.zedRunTopLosersPagination = action.payload.pagination;
      misc.zedRunTopLosersLoading = false;
    },
    zedRunTopLosersRequestFailed: (misc, action) => {
      misc.zedRunTopLosersLoading = false;
    },
    zedRunTopUsersByTimeSpentRequested: (misc, action) => {
      misc.zedRunTopUsersByTimeSpentLoading = true;
    },
    zedRunTopUsersByTimeSpentReceived: (misc, action) => {
      misc.zedRunTopUsersByTimeSpent = action.payload.data;
      misc.zedRunTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.zedRunTopUsersByTimeSpentLoading = false;
    },
    moreZedRunTopUsersByTimeSpentReceived: (misc, action) => {
      misc.zedRunTopUsersByTimeSpent = [...misc.zedRunTopUsersByTimeSpent, ...action.payload.data];
      misc.zedRunTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.zedRunTopUsersByTimeSpentLoading = false;
    },
    zedRunTopUsersByTimeSpentRequestFailed: (misc, action) => {
      misc.zedRunTopUsersByTimeSpentLoading = false;
    },
    robotRacerDashboardRequested: (misc, action) => {
      misc.robotRacerDashboardLoading = true;
    },
    robotRacerDashboardReceived: (misc, action) => {
      misc.robotRacerDashboard = action.payload;
      misc.robotRacerDashboardLoading = false;
    },
    robotRacerDashboardRequestFailed: (misc, action) => {
      misc.robotRacerDashboardLoading = false;
    },
    robotRacerTopWinnersRequested: (misc, action) => {
      misc.robotRacerTopWinnersLoading = true;
    },
    robotRacerTopWinnersReceived: (misc, action) => {
      misc.robotRacerTopWinners = action.payload.data;
      misc.robotRacerTopWinnersPagination = action.payload.pagination;
      misc.robotRacerTopWinnersLoading = false;
    },
    moreRobotRacerTopWinnersReceived: (misc, action) => {
      misc.robotRacerTopWinners = [...misc.robotRacerTopWinners, ...action.payload.data];
      misc.robotRacerTopWinnersPagination = action.payload.pagination;
      misc.robotRacerTopWinnersLoading = false;
    },
    robotRacerTopWinnersRequestFailed: (misc, action) => {
      misc.robotRacerTopWinnersLoading = false;
    },
    robotRacerTopLosersRequested: (misc, action) => {
      misc.robotRacerTopLosersLoading = true;
    },
    robotRacerTopLosersReceived: (misc, action) => {
      misc.robotRacerTopLosers = action.payload.data;
      misc.robotRacerTopLosersPagination = action.payload.pagination;
      misc.robotRacerTopLosersLoading = false;
    },
    moreRobotRacerTopLosersReceived: (misc, action) => {
      misc.robotRacerTopLosers = [...misc.robotRacerTopLosers, ...action.payload.data];
      misc.robotRacerTopLosersPagination = action.payload.pagination;
      misc.robotRacerTopLosersLoading = false;
    },
    robotRacerTopLosersRequestFailed: (misc, action) => {
      misc.robotRacerTopLosersLoading = false;
    },
    robotRacerTopUsersByTimeSpentRequested: (misc, action) => {
      misc.robotRacerTopUsersByTimeSpentLoading = true;
    },
    robotRacerTopUsersByTimeSpentReceived: (misc, action) => {
      misc.robotRacerTopUsersByTimeSpent = action.payload.data;
      misc.robotRacerTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.robotRacerTopUsersByTimeSpentLoading = false;
    },
    moreRobotRacerTopUsersByTimeSpentReceived: (misc, action) => {
      misc.robotRacerTopUsersByTimeSpent = [...misc.robotRacerTopUsersByTimeSpent, ...action.payload.data];
      misc.robotRacerTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.robotRacerTopUsersByTimeSpentLoading = false;
    },
    robotRacerTopUsersByTimeSpentRequestFailed: (misc, action) => {
      misc.robotRacerTopUsersByTimeSpentLoading = false;
    },
    telegramNotificationsRequested: (misc, action) => {
      misc.telegramNotificationsLoading = true;
    },
    telegramNotificationsReceived: (misc, action) => {
      misc.telegramNotifications = action.payload.data;
      misc.telegramNotificationsTotalCount = action.payload.totalCount;
      misc.telegramNotificationsLoading = false;
    },
    moreTelegramNotificationsReceived: (misc, action) => {
      misc.telegramNotifications = [...misc.telegramNotifications, ...action.payload.data];
      misc.telegramNotificationsTotalCount = action.payload.totalCount;
      misc.telegramNotificationsLoading = false;
    },
    telegramNotificationsRequestFailed: (misc, action) => {
      misc.telegramNotificationsLoading = false;
    },
    updateTelegramUsdtWithdrawalStatusReceived: (misc, action) => {
      const { id, status } = action.payload;
      console.log("Payload:", id, status);
      misc.telegramUsdtWithdrawals = misc.telegramUsdtWithdrawals.map((withdrawal) =>
        withdrawal._id === id ? { ...withdrawal, status } : withdrawal,
      );
      console.log("Payload:usdt", misc.telegramUsdtWithdrawals);
    },

    // ======> Analytics Start
    sweepstakeAnalyticsDashboardRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    sweepstakeAnalyticsDashboardReceived: (state, action) => {
      state.sweepstakeAnalyticsDashboard = action.payload;
      state.analyticsLoading = false;
    },
    sweepstakeAnalyticsDashboardRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    engagementAnalyticsDashboardObjectsRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    engagementAnalyticsDashboardObjectsReceived: (state, action) => {
      state.engagementAnalyticsDashboard = action.payload;
      state.analyticsLoading = false;
    },
    engagementAnalyticsDashboardObjectsRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    engagementAnalyticsGamesRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    engagementAnalyticsGamesReceived: (state, action) => {
      state.engagementAnalyticsGames = action.payload;
      state.analyticsLoading = false;
    },
    engagementAnalyticsGamesRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    engagementAnalyticsHourlyUserCountsRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    engagementAnalyticsHourlyUserCountsReceived: (state, action) => {
      state.engagementAnalyticsHourlyUserCounts = action.payload;
      state.analyticsLoading = false;
    },
    engagementAnalyticsHourlyUserCountsRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },

    engagementAnalyticsHourlyGamesCountsRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    engagementAnalyticsHourlyGamesCountsReceived: (state, action) => {
      state.engagementAnalyticsHourlyGamesCounts = action.payload;
      state.analyticsLoading = false;
    },
    engagementAnalyticsHourlyGamesCountsRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    engagementAnalyticsHourlyEngagementCountsRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    engagementAnalyticsHourlyEngagementCountsReceived: (state, action) => {
      state.engagementAnalyticsHourlyEngagementCounts = action.payload;
      state.analyticsLoading = false;
    },
    engagementAnalyticsHourlyEngagementCountsRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },

    userAnalyticsCountriesRequested: (misc, action) => {
      misc.userAnalyticsCountriesLoading = true;
    },
    userAnalyticsCountriesReceived: (misc, action) => {
      misc.userAnalyticsCountries = action.payload.usersByCountry;
      misc.userAnalyticsCountriesLoading = false;
    },
    userAnalyticsCountriesRequestFailed: (misc, action) => {
      misc.userAnalyticsCountriesLoading = false;
    },
    spinRewardAnalyticsRequested: (misc, action) => {
      misc.spinRewardAnalyticsLoading = true;
    },
    spinRewardAnalyticsReceived: (misc, action) => {
      misc.spinRewardAnalytics = action.payload;
      misc.spinRewardAnalyticsLoading = false;
    },
    spinRewardAnalyticsRequestFailed: (misc, action) => {
      misc.spinRewardAnalyticsLoading = false;
    },
    userAnalyticsReferralsRequested: (misc, action) => {
      misc.userAnalyticsReferralsLoading = true;
    },
    userAnalyticsReferralsReceived: (misc, action) => {
      misc.userAnalyticsReferrals = action.payload;
      misc.userAnalyticsReferralsLoading = false;
    },
    userAnalyticsReferralsRequestFailed: (misc, action) => {
      misc.userAnalyticsReferralsLoading = false;
    },
    appLaunchDataRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    appLaunchDataReceived: (state, action) => {
      state.appLaunchData = action.payload;
      state.analyticsLoading = false;
    },
    appLaunchDataRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    // ======> Analytics End
    //======> Character CSV
    charactersCsvRequested: (misc, action) => {
      misc.charactersCsvLoading = true;
    },
    charactersCsvReceived: (misc, action) => {
      misc.charactersCsv = action.payload.data;
      misc.charactersCsvTotalCount = action.payload.totalCount;
      misc.charactersCsvLoading = false;
    },
    charactersCsvRequestFailed: (misc, action) => {
      misc.charactersCsvLoading = false;
    },
    stagesCsvRequested: (misc, action) => {
      misc.stagesCsvLoading = true;
    },
    stagesCsvReceived: (misc, action) => {
      misc.stagesCsv = action.payload.data;
      misc.stagesCsvTotalCount = action.payload.totalCount;
      misc.stagesCsvLoading = false;
    },
    stagesCsvRequestFailed: (misc, action) => {
      misc.stagesCsvLoading = false;
    },
    statusEffectsCsvRequested: (misc, action) => {
      misc.statusEffectsCsvLoading = true;
    },
    statusEffectsCsvReceived: (misc, action) => {
      misc.statusEffectsCsv = action.payload.data;
      misc.statusEffectsCsvTotalCount = action.payload.totalCount;
      misc.statusEffectsCsvLoading = false;
    },
    statusEffectsCsvRequestFailed: (misc, action) => {
      misc.statusEffectsCsvLoading = false;
    },
    rewardsCsvRequested: (misc, action) => {
      misc.rewardsCsvLoading = true;
    },
    rewardsCsvReceived: (misc, action) => {
      misc.rewardsCsv = action.payload.data;
      misc.rewardCsvTotalCount = action.payload.totalCount;
      misc.rewardsCsvLoading = false;
    },
    rewardsCsvRequestFailed: (misc, action) => {
      misc.rewardsCsvLoading = false;
    },
    enemiesCsvRequested: (misc, action) => {
      misc.enemiesCsvLoading = true;
    },
    enemiesCsvReceived: (misc, action) => {
      misc.enemiesCsv = action.payload.data;
      misc.enemiesCsvTotalCount = action.payload.totalCount;
      misc.enemiesCsvLoading = false;
    },
    enemiesCsvRequestFailed: (misc, action) => {
      misc.enemiesCsvLoading = false;
    },
    gearsCsvRequested: (misc, action) => {
      misc.gearsCsvLoading = true;
    },
    gearsCsvReceived: (misc, action) => {
      misc.gearsCsv = action.payload.data;
      misc.gearsCsvTotalCount = action.payload.totalCount;
      misc.gearsCsvLoading = false;
    },
    gearsCsvRequestFailed: (misc, action) => {
      misc.gearsCsvLoading = false;
    },
    gearsSubTypeRequested: (misc, action) => {
      misc.gearsSubTypeLoading = true;
    },
    gearsSubTypeReceived: (misc, action) => {
      misc.gearsSubType = action.payload.data;
      misc.gearsSubTypeTotalCount = action.payload.totalCount;
      misc.gearsSubTypeLoading = false;
    },
    gearsSubTypeRequestFailed: (misc, action) => {
      misc.gearsSubTypeLoading = false;
    },
    gameImagesRequested: (misc, action) => {
      misc.gameImagesLoading = true;
    },
    gameImagesReceived: (misc, action) => {
      misc.gameImages = action.payload.data;
      misc.gameImagesTotalCount = action.payload.totalCount;
      misc.gameImagesLoading = false;
    },
    gameImagesRequestFailed: (misc, action) => {
      misc.gameImagesLoading = false;
    },
    battleGameCsvsRequested: (misc, action) => {
      misc.battleGameCsvsLoading = true;
    },
    battleGameCsvsReceived: (misc, action) => {
      misc.battleGameCsvs = action.payload.data;
      misc.battleGameCsvsTotalCount = action.payload.totalCount;
      misc.battleGameCsvsLoading = false;
    },
    moreBattleGameCsvsReceived: (misc, action) => {
      misc.battleGameCsvs = [...misc.battleGameCsvs, ...action.payload.data];
      misc.battleGameCsvsTotalCount = action.payload.totalCount;
      misc.battleGameCsvsLoading = false;
    },
    battleGameCsvsRequestFailed: (misc, action) => {
      misc.battleGameCsvsLoading = false;
    },
    dailyStatsDashboardRequested: (state, action) => {
      state.analyticsLoading = true;
    },
    dailyStatsDashboardReceived: (state, action) => {
      state.dailyStatsDashboard = action.payload;
      state.analyticsLoading = false;
    },
    dailyStatsDashboardRequestFailed: (state, action) => {
      state.analyticsLoading = false;
    },
    battleGamesRequested: (misc, action) => {
      misc.battleGamesLoading = true;
    },
    battleGamesReceived: (misc, action) => {
      misc.battleGames = action.payload.data;
      misc.battleGamesPagination = action.payload.pagination;
      misc.battleGamesLoading = false;
    },
    battleGamesRequestFailed: (misc, action) => {
      misc.battleGamesLoading = false;
    },
    updateBattleGameStatusReceived: (misc, action) => {
      const newBattleGames = misc.battleGames.map((game) =>
        game._id === action.payload._id ? { ...game, status: action.payload?.status } : game,
      );
      misc.battleGames = newBattleGames;
    },
    battleGameParticipantsRequested: (misc, action) => {
      misc.battleGameParticipantsLoading = true;
    },
    battleGameParticipantsReceived: (misc, action) => {
      misc.battleGameParticipants = action.payload.data;
      misc.battleGameParticipantsTotalEntries = action.payload.totalEntries;
      misc.battleGameParticipantsLoading = false;
    },
    moreBattleGameParticipantsReceived: (misc, action) => {
      misc.battleGameParticipants = [...misc.battleGameParticipants, ...action.payload.data];
      misc.battleGameParticipantsTotalEntries = action.payload.totalEntries;
      misc.battleGameParticipantsLoading = false;
    },
    battleGameParticipantsRequestFailed: (misc, action) => {
      misc.battleGameParticipantsLoading = false;
    },
  },
});

export const {
  telegramSpinRewardRequested,
  telegramSpinRewardReceived,
  telegramSpinRewardRequestFailed,
  telegramUsdtWithdrawalsReceived,
  telegramUsdtWithdrawalsRequestFailed,
  telegramUsdtWithdrawalsRequested,
  telegramUsdtStatusReceived,
  telegramSettingRequested,
  telegramSettingReceived,
  telegramSettingRequestFailed,
  telegramUsersRequested,
  telegramUsersReceived,
  telegramUsersRequestFailed,
  telegramUserTransactionsRequested,
  telegramUserTransactionsReceived,
  moreTelegramUserTransactionsReceived,
  telegramUserTransactionsRequestFailed,
  telegramChallengesRequested,
  telegramChallengesReceived,
  prizesReceived,
  addPrizeReceived,
  updatePrizeReceived,
  deletePrizeReceived,
  sweepRequested,
  sweepRequestFailed,
  sweepstakeReceived,
  addSweepstakeReceived,
  updateSweepstakeReceived,
  deleteSweepstakeReceived,
  telegramChallengesRequestFailed,
  verisoulTelegramAuthResultsRequested,
  verisoulTelegramAuthResultsReceived,
  verisoulTelegramAuthResultsRequestFailed,
  verisoulMultipleTelegramAccountsRequested,
  verisoulMultipleTelegramAccountsReceived,
  verisoulMultipleTelegramAccountsRequestFailed,
  verisoulMultipleTelegramAccountsByIdRequested,
  verisoulMultipleTelegramAccountsByIdReceived,
  verisoulMultipleTelegramAccountsByIdRequestFailed,
  updateTelegramUserStatusReceived,
  sweepstakeTemplateReceived,
  addSweepstakeTemplateReceived,
  updateSweepstakeTemplateReceived,
  deleteSweepstakeTemplateReceived,
  sweepstakesParticipantsReceived,
  sweepstakesWinnersReceived,
  teamPlayChallengesRequested,
  teamPlayChallengesReceived,
  teamPlayChallengesRequestFailed,
  addTeamPlayChallengeReceived,
  updateTeamPlayChallengeReceived,
  deleteTeamPlayChallengeReceived,
  verisoulAuthResultsByIdRequested,
  verisoulAuthResultsByIdReceived,
  verisoulAuthResultsByIdRequestFailed,
  questsRequested,
  questsReceived,
  questsRequestFailed,
  dailyTournamentRequested,
  dailyTournamentReceived,
  dailyTournamentRequestFailed,
  sweepstakesParticipantsLoadMoreReceived,
  sweepRequestedLoadMore,
  dailyTournamentLeaderboardRequested,
  dailyTournamentLeaderboardReceived,
  dailyTournamentLeaderboardRequestFailed,
  telegramGamesRequested,
  telegramGamesReceived,
  telegramGamesRequestFailed,
  recentVerisoulAuthResultByIdRequested,
  recentVerisoulAuthResultByIdReceived,
  recentVerisoulAuthResultByIdRequestCompleted,
  teammatesRequested,
  teammatesReceived,
  teammatesRequestFailed,
  moreTeammatesReceived,
  cowitzerDashboardRequested,
  cowitzerDashboardReceived,
  cowitzerDashboardRequestFailed,
  cowitzerTopWinnersRequested,
  cowitzerTopWinnersReceived,
  moreCowitzerTopWinnersReceived,
  cowitzerTopWinnersRequestFailed,
  cowitzerTopLosersRequested,
  cowitzerTopLosersReceived,
  moreCowitzerTopLosersReceived,
  cowitzerTopLosersRequestFailed,
  zedRunDashboardRequested,
  zedRunDashboardReceived,
  zedRunDashboardRequestFailed,
  zedRunTopWinnersRequested,
  zedRunTopWinnersReceived,
  moreZedRunTopWinnersReceived,
  zedRunTopWinnersRequestFailed,
  zedRunTopLosersRequested,
  zedRunTopLosersReceived,
  moreZedRunTopLosersReceived,
  zedRunTopLosersRequestFailed,
  zedRunTopUsersByTimeSpentRequested,
  zedRunTopUsersByTimeSpentReceived,
  moreZedRunTopUsersByTimeSpentReceived,
  zedRunTopUsersByTimeSpentRequestFailed,
  robotRacerDashboardRequested,
  robotRacerDashboardReceived,
  robotRacerDashboardRequestFailed,
  robotRacerTopWinnersRequested,
  robotRacerTopWinnersReceived,
  moreRobotRacerTopWinnersReceived,
  robotRacerTopWinnersRequestFailed,
  robotRacerTopLosersRequested,
  robotRacerTopLosersReceived,
  moreRobotRacerTopLosersReceived,
  robotRacerTopLosersRequestFailed,
  robotRacerTopUsersByTimeSpentRequested,
  robotRacerTopUsersByTimeSpentReceived,
  moreRobotRacerTopUsersByTimeSpentReceived,
  robotRacerTopUsersByTimeSpentRequestFailed,
  telegramNotificationsRequested,
  telegramNotificationsReceived,
  moreTelegramNotificationsReceived,
  telegramNotificationsRequestFailed,
  updateTelegramUsdtWithdrawalStatusReceived,
  sweepstakeAnalyticsDashboardRequested,
  sweepstakeAnalyticsDashboardReceived,
  sweepstakeAnalyticsDashboardRequestFailed,
  engagementAnalyticsDashboardObjectsRequested,
  engagementAnalyticsDashboardObjectsReceived,
  engagementAnalyticsDashboardObjectsRequestFailed,
  engagementAnalyticsGamesRequested,
  engagementAnalyticsGamesReceived,
  engagementAnalyticsGamesRequestFailed,
  engagementAnalyticsHourlyUserCountsRequested,
  engagementAnalyticsHourlyUserCountsReceived,
  engagementAnalyticsHourlyUserCountsRequestFailed,
  engagementAnalyticsHourlyGamesCountsRequested,
  engagementAnalyticsHourlyGamesCountsReceived,
  engagementAnalyticsHourlyGamesCountsRequestFailed,

  userAnalyticsCountriesRequested,
  userAnalyticsCountriesReceived,
  userAnalyticsCountriesRequestFailed,
  userAnalyticsReferralsRequested,
  userAnalyticsReferralsReceived,
  userAnalyticsReferralsRequestFailed,
  charactersCsvRequested,
  charactersCsvReceived,
  charactersCsvRequestFailed,
  stagesCsvReceived,
  stagesCsvRequestFailed,
  stagesCsvRequested,
  statusEffectsCsvRequested,
  statusEffectsCsvReceived,
  statusEffectsCsvRequestFailed,
  rewardsCsvRequested,
  rewardsCsvReceived,
  rewardsCsvRequestFailed,
  enemiesCsvRequested,
  enemiesCsvReceived,
  enemiesCsvRequestFailed,
  gearsCsvRequested,
  gearsCsvReceived,
  gearsCsvRequestFailed,
  gearsSubTypeRequested,
  gearsSubTypeReceived,
  gearsSubTypeRequestFailed,
  gameImagesRequested,
  gameImagesReceived,
  gameImagesRequestFailed,
  engagementAnalyticsHourlyEngagementCountsRequested,
  engagementAnalyticsHourlyEngagementCountsReceived,
  engagementAnalyticsHourlyEngagementCountsRequestFailed,
  battleGameCsvsRequested,
  battleGameCsvsReceived,
  moreBattleGameCsvsReceived,
  battleGameCsvsRequestFailed,
  dailyStatsDashboardRequested,
  dailyStatsDashboardReceived,
  dailyStatsDashboardRequestFailed,
  spinRewardAnalyticsRequested,
  spinRewardAnalyticsReceived,
  spinRewardAnalyticsRequestFailed,
  appLaunchDataRequested,
  appLaunchDataReceived,
  appLaunchDataRequestFailed,

  battleGamesRequested,
  battleGamesReceived,
  battleGamesRequestFailed,
  updateBattleGameStatusReceived,
  battleGameParticipantsRequested,
  battleGameParticipantsReceived,
  moreBattleGameParticipantsReceived,
  battleGameParticipantsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const telegramSweepstakePrizeURL = "telegramSweepstakes/sweepstakesTier/";
const telegramSweepstakesURL = "telegramSweepstakes/sweepstakes/";
const telegramSweepstakesTemplateURL = "telegramSweepstakes/sweepstakesTemplate/";

export const loadTelegramSpinReward = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward",
      params,
      onStart: telegramSpinRewardRequested.type,
      onSuccess: telegramSpinRewardReceived.type,
      onError: telegramSpinRewardRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramSpinReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsdtRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/usdtWithdrawal",
      params,
      onStart: telegramUsdtWithdrawalsRequested.type,
      onSuccess: telegramUsdtWithdrawalsReceived.type,
      onError: telegramUsdtWithdrawalsRequestFailed.type,
      callback,
    }),
  );
};
export const updateUsdtWithdrawalRequests = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/usdtWithdrawal/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateTelegramUsdtStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/usdtWithdrawal/" + id,
      method: "POST",
      data: { status: 2 },
      callback,
    }),
  );
};
export const loadTelegramSettings = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      onStart: telegramSettingRequested.type,
      onSuccess: telegramSettingReceived.type,
      onError: telegramSettingRequestFailed.type,
      callback,
    }),
  );
};
export const addTelegramSetting = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser",
      params,
      onStart: telegramUsersRequested.type,
      onSuccess: telegramUsersReceived.type,
      onError: telegramUsersRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramUserStatus = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/updateStatus/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadTelegramUserTransactions = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/transactions/" + id,
      params,
      onStart: telegramUserTransactionsRequested.type,
      onSuccess: fresh ? telegramUserTransactionsReceived.type : moreTelegramUserTransactionsReceived.type,
      onError: telegramUserTransactionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramChallenges = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/challenges",
      params,
      onStart: telegramChallengesRequested.type,
      onSuccess: telegramChallengesReceived.type,
      onError: telegramChallengesRequestFailed.type,
      callback,
    }),
  );
};

//  ===>>> Prizes Start
export const loadPrizes = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL,
      onStart: sweepRequested.type,
      onSuccess: prizesReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addPrizes = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updatePrizes = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deletePrizes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Prizes End

//  ===>>> Sweepstakes Start
export const loadSweepstakes = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL,
      params,
      onStart: sweepRequested.type,
      onSuccess: sweepstakeReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addSweepstakes = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSweepstakes = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteSweepstakes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL + id,
      method: "DELETE",
      callback,
    }),
  );
};

export const loadSweepstakesTemplate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL,
      params,
      onStart: sweepRequested.type,
      onSuccess: sweepstakeTemplateReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addSweepstakesTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSweepstakesTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteSweepstakesTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Sweepstakes End

export const loadVerisoulTelegramAuthResults = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulAuthResults",

      onStart: verisoulTelegramAuthResultsRequested.type,
      onSuccess: verisoulTelegramAuthResultsReceived.type,
      onError: verisoulTelegramAuthResultsRequestFailed.type,
      callback,
    }),
  );
};

export const loadVerisoulAuthResultsById = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulAuthResults/" + id,
      onStart: verisoulAuthResultsByIdRequested.type,
      onSuccess: verisoulAuthResultsByIdReceived.type,
      onError: verisoulAuthResultsByIdRequestFailed.type,
      callback,
    }),
  );
};
export const loadRecentVerisoulAuthResultById = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/recentVerisoulAuthResult/" + id,
      onSuccess: recentVerisoulAuthResultByIdReceived.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleTelegramAccounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulMultipleAccounts",
      onStart: verisoulMultipleTelegramAccountsRequested.type,
      onSuccess: verisoulMultipleTelegramAccountsReceived.type,
      onError: verisoulMultipleTelegramAccountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleTelegramAccountsById = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/verisoulMultipleAccounts/" + id,
      onStart: verisoulMultipleTelegramAccountsByIdRequested.type,
      onSuccess: verisoulMultipleTelegramAccountsByIdReceived.type,
      onError: verisoulMultipleTelegramAccountsByIdRequestFailed.type,
      callback,
    }),
  );
};
export const loadSweepstakesParticipants = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/entries/" + id + "?page=1",
      onStart: sweepRequested.type,
      onSuccess: sweepstakesParticipantsReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

export const loadSweepstakesParticipantsLoadMore = (id, page, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/entries/" + id + "?page=" + page,
      onStart: sweepRequestedLoadMore.type,
      onSuccess: sweepstakesParticipantsLoadMoreReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

export const loadSweepstakesWinners = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/winners/" + id,
      onStart: sweepRequested.type,
      onSuccess: sweepstakesWinnersReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

// Notifications
export const sendUserNotifications = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/sendMessage",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const sendBroadcastNotifications = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/sendMessageToAllUsers",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const editUserNotification = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/messages/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteTelgramNotification = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/messages/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
// Team Play Challenges
export const loadTeamPlayChallenges = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/",
      params,
      onStart: teamPlayChallengesRequested.type,
      onSuccess: teamPlayChallengesReceived.type,
      onError: teamPlayChallengesRequestFailed.type,
    }),
  );
};
export const addTeamPlayChallenge = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteTeamPlayChallenge = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const updateTeamPlayChallenge = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

//Quests
export const loadQuests = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest",
      onStart: questsRequested.type,
      onSuccess: questsReceived.type,
      onError: questsRequestFailed.type,
      callback,
    }),
  );
};

export const addQuest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const updateQuest = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const deleteQuest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadDailyTournament = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest",
      params,
      onStart: dailyTournamentRequested.type,
      onSuccess: dailyTournamentReceived.type,
      onError: dailyTournamentRequestFailed.type,
      callback,
    }),
  );
};
export const addDailyTournament = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateDailyTournament = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadDailyTournamentLeaderboard = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest/leaderboard/" + id,
      params,
      onStart: dailyTournamentLeaderboardRequested.type,
      onSuccess: dailyTournamentLeaderboardReceived.type,
      onError: dailyTournamentLeaderboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramBgGame/userGameMetaData",
      params,
      onStart: telegramGamesRequested.type,
      onSuccess: telegramGamesReceived.type,
      onError: telegramGamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadTeammates = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/teammates/" + id,
      params,
      onStart: teammatesRequested.type,
      onSuccess: fresh ? teammatesReceived.type : moreTeammatesReceived.type,
      onError: teammatesRequestFailed.type,
      callback,
    }),
  );
};
//Cowitzer Dashboard
export const loadCowitzerDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramCowitzer/dashboard",
      onStart: cowitzerDashboardRequested.type,
      onSuccess: cowitzerDashboardReceived.type,
      onError: cowitzerDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadCowitzerTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramCowitzer/topWinner",
        params,
        onStart: cowitzerTopWinnersRequested.type,
        onSuccess: fresh ? cowitzerTopWinnersReceived.type : moreCowitzerTopWinnersReceived.type,
        onError: cowitzerTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadCowitzerTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramCowitzer/topLoser",
        params,
        onStart: cowitzerTopLosersRequested.type,
        onSuccess: fresh ? cowitzerTopLosersReceived.type : moreCowitzerTopLosersReceived.type,
        onError: cowitzerTopLosersRequestFailed.type,
        callback,
      }),
    );
  };
// ZedRun Dashboard APIs
export const loadZedRunDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramZedRun/dashboard",
      onStart: zedRunDashboardRequested.type,
      onSuccess: zedRunDashboardReceived.type,
      onError: zedRunDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadZedRunTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramZedRun/topWinner",
        params,
        onStart: zedRunTopWinnersRequested.type,
        onSuccess: fresh ? zedRunTopWinnersReceived.type : moreZedRunTopWinnersReceived.type,
        onError: zedRunTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadZedRunTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramZedRun/topLoser",
        params,
        onStart: zedRunTopLosersRequested.type,
        onSuccess: fresh ? zedRunTopLosersReceived.type : moreZedRunTopLosersReceived.type,
        onError: zedRunTopLosersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadZedRunTopUsersByTimeSpent =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramZedRun/timeSpentByUser",
        params,
        onStart: zedRunTopUsersByTimeSpentRequested.type,
        onSuccess: fresh ? zedRunTopUsersByTimeSpentReceived.type : moreZedRunTopUsersByTimeSpentReceived.type,
        onError: zedRunTopUsersByTimeSpentRequestFailed.type,
        callback,
      }),
    );
  };

// Robot Racer Dashboard APIs
export const loadRobotRacerDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramRobotRacing/dashboard",
      onStart: robotRacerDashboardRequested.type,
      onSuccess: robotRacerDashboardReceived.type,
      onError: robotRacerDashboardRequestFailed.type,
      callback,
    }),
  );
};

export const loadRobotRacerTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramRobotRacing/topWinner",
        params,
        onStart: robotRacerTopWinnersRequested.type,
        onSuccess: fresh ? robotRacerTopWinnersReceived.type : moreRobotRacerTopWinnersReceived.type,
        onError: robotRacerTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };

export const loadRobotRacerTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramRobotRacing/topLoser",
        params,
        onStart: robotRacerTopLosersRequested.type,
        onSuccess: fresh ? robotRacerTopLosersReceived.type : moreRobotRacerTopLosersReceived.type,
        onError: robotRacerTopLosersRequestFailed.type,
        callback,
      }),
    );
  };

export const loadRobotRacerTopUsersByTimeSpent =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "telegramRobotRacing/timeSpentByUser",
        params,
        onStart: robotRacerTopUsersByTimeSpentRequested.type,
        onSuccess: fresh ? robotRacerTopUsersByTimeSpentReceived.type : moreRobotRacerTopUsersByTimeSpentReceived.type,
        onError: robotRacerTopUsersByTimeSpentRequestFailed.type,
        callback,
      }),
    );
  };
export const loadTelegramNotifications = (params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/messageLogs",
      params,
      onStart: telegramNotificationsRequested.type,
      onSuccess: fresh ? telegramNotificationsReceived.type : moreTelegramNotificationsReceived.type,
      onError: telegramNotificationsRequestFailed.type,
      callback,
    }),
  );
};
export const loadSweepstakeAnalyticsDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/sweepstakes/dashboard",
      params,
      onStart: sweepstakeAnalyticsDashboardRequested.type,
      onSuccess: sweepstakeAnalyticsDashboardReceived.type,
      onError: sweepstakeAnalyticsDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadAppLaunchData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/appLaunch",
      params,
      onStart: appLaunchDataRequested.type,
      onSuccess: appLaunchDataReceived.type,
      onError: appLaunchDataRequestFailed.type,
      callback,
    }),
  );
};
export const loadEngagementAnalyticsDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/dashboard",
      params,
      onStart: engagementAnalyticsDashboardObjectsRequested.type,
      onSuccess: engagementAnalyticsDashboardObjectsReceived.type,
      onError: engagementAnalyticsDashboardObjectsRequestFailed.type,
      callback,
    }),
  );
};
export const loadEngagementAnalyticsGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/game",
      params,
      onStart: engagementAnalyticsGamesRequested.type,
      onSuccess: engagementAnalyticsGamesReceived.type,
      onError: engagementAnalyticsGamesRequestFailed.type,
      callback,
    }),
  );
};

export const loadEngagementAnalyticsHourlyUserCounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/users",
      params,
      onStart: engagementAnalyticsHourlyUserCountsRequested.type,
      onSuccess: engagementAnalyticsHourlyUserCountsReceived.type,
      onError: engagementAnalyticsHourlyUserCountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadEngagementAnalyticsHourlyEngagementCounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/hourly",
      params,
      onStart: engagementAnalyticsHourlyEngagementCountsRequested.type,
      onSuccess: engagementAnalyticsHourlyEngagementCountsReceived.type,
      onError: engagementAnalyticsHourlyEngagementCountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadEngagementAnalyticsHourlyGamesCounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/games",
      params,
      onStart: engagementAnalyticsHourlyGamesCountsRequested.type,
      onSuccess: engagementAnalyticsHourlyGamesCountsReceived.type,
      onError: engagementAnalyticsHourlyGamesCountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserAnalyticsReferrals = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/users/referrals",
      onStart: userAnalyticsReferralsRequested.type,
      onSuccess: userAnalyticsReferralsReceived.type,
      onError: userAnalyticsReferralsRequestFailed.type,
      callback,
      params,
    }),
  );
};

export const loadUserAnalyticsCountries = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/users/countries",
      onStart: userAnalyticsCountriesRequested.type,
      onSuccess: userAnalyticsCountriesReceived.type,
      onError: userAnalyticsCountriesRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadSpinRewardAnalytics = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/spinstats",
      params,
      onStart: spinRewardAnalyticsRequested.type,
      onSuccess: spinRewardAnalyticsReceived.type,
      onError: spinRewardAnalyticsRequestFailed.type,
      callback,
    }),
  );
};
export const loadDailyStatsDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/dayStats",
      params,

      callback,
    }),
  );
};

export const loadDailyStatsActivityEngagementDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegram-analytics/engagement/dayStatsEngagement",
      params,
      callback,
    }),
  );
};

export const loadCharactersCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/characters",
      params,
      onStart: charactersCsvRequested.type,
      onSuccess: charactersCsvReceived.type,
      onError: charactersCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addCharactersCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/updateCharactersCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadStagesCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/stages",
      params,
      onStart: stagesCsvRequested.type,
      onSuccess: stagesCsvReceived.type,
      onError: stagesCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addStagesCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadStagesCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadStatusEffectsCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/statusEffects",
      params,
      onStart: statusEffectsCsvRequested.type,
      onSuccess: statusEffectsCsvReceived.type,
      onError: statusEffectsCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addStatusEffectsCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadStatusEffectsCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadRewardsCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/rewards",
      params,
      onStart: rewardsCsvRequested.type,
      onSuccess: rewardsCsvReceived.type,
      onError: rewardsCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addRewardsCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadRewardsCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadEnemiesCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/enemies",
      params,
      onStart: enemiesCsvRequested.type,
      onSuccess: enemiesCsvReceived.type,
      onError: enemiesCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addEnemiesCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadEnemiesCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadGearsCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/gears",
      params,
      onStart: gearsCsvRequested.type,
      onSuccess: gearsCsvReceived.type,
      onError: gearsCsvRequestFailed.type,
      callback,
    }),
  );
};
export const addGearsCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadGearsCsv",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadGearsSubTypeCsv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/gears-subtype",
      params,
      onStart: gearsSubTypeRequested.type,
      onSuccess: gearsSubTypeReceived.type,
      onError: gearsSubTypeRequestFailed.type,
      callback,
    }),
  );
};
export const addGearSubType = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/gears-subtype",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateGearSubType = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/gears-subtype/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteGearSubType = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/gears-subtype/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadGameImages = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/images",
      params,
      onStart: gameImagesRequested.type,
      onSuccess: gameImagesReceived.type,
      onError: gameImagesRequestFailed.type,
      callback,
    }),
  );
};
export const addGameImages = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/uploadImages",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadBattleGameCSVs =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "battle/battleGame/csvs",
        params,
        onStart: battleGameCsvsRequested.type,
        onSuccess: fresh ? battleGameCsvsReceived.type : moreBattleGameCsvsReceived.type,
        onError: battleGameCsvsRequestFailed.type,
        callback,
      }),
    );
  };
export const loadBattleGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/game/getGame",
      params,
      onStart: battleGamesRequested.type,
      onSuccess: battleGamesReceived.type,
      onError: battleGamesRequestFailed.type,
      callback,
    }),
  );
};
export const addBattleGame = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/game/createGame",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateBattleGame = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/game/updateGame/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteBattleGame = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/game/deleteGame/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadBattleGameParticipants = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "battle/battleGame/game/getParticipant/" + id,
      params,
      onStart: battleGameParticipantsRequested.type,
      onSuccess: fresh ? battleGameParticipantsReceived.type : moreBattleGameParticipantsReceived.type,
      onError: battleGameParticipantsRequestFailed.type,
      callback,
    }),
  );
};

export const getTelegram = createSelector(
  (state) => state.entities.telegram,
  (telegram) => telegram,
);
