import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class Textarea extends Component {
  render() {
    const { name, label, error, placeholder, value, onChange, ...rest } = this.props;
    return (
      <>
        {label && <label htmlFor={name}>{label}</label>}
        <Editor
          editorState={value}
          editorClassName={error ? "form-control h-auto error" : "form-control h-auto"}
          toolbar={{
            options: ["inline", "link"],
            inline: {
              options: ["bold", "italic", "underline", "strikethrough"],
            },
          }}
          onEditorStateChange={(a) =>
            onChange({
              currentTarget: { name, value: a },
            })
          }
        />
      </>
    );
  }
}

export default Textarea;
