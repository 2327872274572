import { Component, createRef } from "react";
import { Offcanvas, OverlayTrigger, Popover } from "react-bootstrap";

import moment from "moment/moment";
import { Link } from "react-router-dom";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

//  Components

class PlayersListingFlyer extends Component {
  ref = createRef(null);
  state = {};

  render() {
    const { players, bgGame } = this.props;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
        show={this.props.showModal}
        onHide={(e) => {
          this.props.toggleModal(e);
        }}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <div className="td-img-group d-flex align-items-start m-top-2">
              <div className="round-img m-right-2">
                {bgGame?.telegramAppImage ? (
                  <img src={process.env.REACT_APP_CDN + bgGame?.telegramAppImage} alt="" />
                ) : (
                  <img src={userIcon} alt="" />
                )}
              </div>
              <div>
                <div>
                  {bgGame?.name} {players?.length > 0 && `(${players?.length})`}
                </div>
              </div>
            </div>
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={(e) => {
              this.props.toggleModal(e);
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {" "}
          <div className="">
            <div className="fwc-body">
              {!this.state.loading && (
                <div className="table-responsive">
                  {players?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th>Player</th>
                          <th>Highest Score </th>
                        </tr>
                      </thead>
                      <tbody>
                        {players?.map((player, i) => (
                          <tr>
                            <td>
                              <span>
                                <div className="td-img-group d-flex align-items-start ">
                                  <div className="td-img round-img m-right-2 pointer">
                                    <img
                                      src={player.image ? process.env.REACT_APP_CDN + player.image.small : userIcon}
                                      alt=""
                                    />
                                  </div>

                                  <div>
                                    {/* {player?.firstName} {player?.lastName}
                                    <br /> */}
                                    {player?.userName} <br />
                                    {player?.telegramId}
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td>
                              <div className={`td-text-box `}>{player?.highestScore?.toLocaleString("en-us") ?? 0}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}

export default PlayersListingFlyer;
