import { PieChart } from "@mui/x-charts";
import { LineChart } from "@mui/x-charts/LineChart";
import DateRangeCalendarPicker from "common/datePicker";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getTelegram,
  loadEngagementAnalyticsDashboard,
  loadEngagementAnalyticsGames,
  loadEngagementAnalyticsHourlyEngagementCounts,
  loadEngagementAnalyticsHourlyGamesCounts,
  loadEngagementAnalyticsHourlyUserCounts,
  loadUserAnalyticsCountries,
  loadUserAnalyticsReferrals,
  loadSpinRewardAnalytics,
  loadAppLaunchData,
} from "store/telegram";
import { generateShades, getFormattedHours, pluralize } from "utils/misc";
import AnalyticsAllDataFlyer from "./analyticsAllDataFlyer";
import userIcon from "include/images/user.png";
import ReferrersFlyer from "./referrersFlyer";
import { TELEGRAM_WALLET_TRANSACTION_AMOUNT_TYPE } from "utils/constants";
import moment from "moment";

const TelegramEngagementAnalytics = (props) => {
  const ref = useRef();

  // States
  const [showViewAll, setShowViewAll] = useState(false);
  const [flyer, setFlyer] = useState({ title: "", data: [], col1Name: "", col2Name: "" });
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingGames, setLoadingGames] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [loadingHourlyEngagement, setLoadingHourlyEngagement] = useState(true);
  const [loadingHourlyGamesCount, setLoadingHourlyGamesCount] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingReferrals, setLoadingReferrals] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingSpinRewards, setLoadingSpinRewards] = useState(true);
  const [loadingAppLaunchData, setLoadingAppLaunchData] = useState(true);
  const [showReferrers, setShowReferrers] = useState(false);

  // State to manage the date range for the analytics
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
    key: "selection",
  });

  // Load data
  useEffect(() => {
    setLoadingMain(true);
    getAnalyticsData();
  }, []);

  const getAnalyticsData = (selection) => {
    setLoadingDashboard(true);
    setLoadingGames(true);
    setLoadingHourlyEngagement(true);
    setLoadingHourlyGamesCount(true);
    setLoadingUsers(true);
    setLoadingReferrals(true);
    setLoadingCountries(true);
    setLoadingSpinRewards(true);
    setLoadingAppLaunchData(true);

    // Prepare payload with start and end dates
    const payLoad = selection
      ? {
          startDate: new Date(
            Date.UTC(
              selection.startDate.getFullYear(),
              selection.startDate.getMonth(),
              selection.startDate.getDate(),
              0,
              0,
              0,
            ),
          ).getTime(),
          endDate: new Date(
            Date.UTC(
              selection.endDate.getFullYear(),
              selection.endDate.getMonth(),
              selection.endDate.getDate(),
              23,
              59,
              59,
            ),
          ).getTime(),
        }
      : {
          startDate: new Date(
            Date.UTC(
              dateRange.startDate.getFullYear(),
              dateRange.startDate.getMonth(),
              dateRange.startDate.getDate(),
              0,
              0,
              0,
            ),
          ).getTime(),
          endDate: new Date(
            Date.UTC(
              dateRange.endDate.getFullYear(),
              dateRange.endDate.getMonth(),
              dateRange.endDate.getDate(),
              23,
              59,
              59,
            ),
          ).getTime(),
        };
    // Load engagement analytics dashboard data
    props.loadEngagementAnalyticsDashboard(payLoad, () => {
      setLoadingDashboard(false);
      setLoadingMain(false);
    });

    // Load engagement analytics games data
    props.loadEngagementAnalyticsGames(payLoad, () => setLoadingGames(false));

    // Load engagement analytics hourly engagement counts data
    props.loadEngagementAnalyticsHourlyEngagementCounts(payLoad, () => setLoadingHourlyEngagement(false));

    // Load engagement analytics hourly games counts data
    props.loadEngagementAnalyticsHourlyGamesCounts(payLoad, () => setLoadingHourlyGamesCount(false));

    // Load user analytics countries data
    props.loadUserAnalyticsCountries(payLoad, () => {
      setLoadingMain(false);
      setLoadingCountries(false);
    });

    // Load spin reward analytics data
    props.loadSpinRewardAnalytics(payLoad, () => {
      setLoadingSpinRewards(false);
    });

    // Load user analytics referrals data
    props.loadUserAnalyticsReferrals(payLoad, () => {
      setLoadingReferrals(false);
    });

    // Load engagement analytics hourly user counts data
    props.loadEngagementAnalyticsHourlyUserCounts(payLoad, () => {
      setLoadingUsers(false);
    });

    // Load app launch data
    props.loadAppLaunchData(payLoad, () => {
      setLoadingAppLaunchData(false);
    });
  };

  // Loader
  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  // Destructure props and states from redux
  const {
    engagementAnalyticsDashboard,
    engagementAnalyticsGames,
    analyticsLoading,
    engagementAnalyticsHourlyEngagementCounts,
    engagementAnalyticsHourlyGamesCounts,
    userAnalyticsCountries,
    userAnalyticsReferrals,
    userAnalyticsReferralsLoading,
    engagementAnalyticsHourlyUserCounts,
    spinRewardAnalytics,
    appLaunchData,
  } = props.getTelegram;

  // Sort hourly counts from high to low userCounts
  const sortedHourlyEngagementCounts = [...(engagementAnalyticsHourlyEngagementCounts?.totalEngagement ?? [])]
    ?.sort((a, b) => b.userCount - a.userCount)
    ?.slice(0, 5);
  const sortedHourlyGamesCounts = [...(engagementAnalyticsHourlyGamesCounts?.gameCount ?? [])]
    ?.sort((a, b) => b.userCount - a.userCount)
    .slice(0, 5);
  const sortedHourlyUserCounts = [...(engagementAnalyticsHourlyUserCounts?.userCount ?? [])]
    ?.sort((a, b) => b.userCount - a.userCount)
    .slice(0, 5);

  // Toggle functions for flyers
  const toggleViewAll = (e, type) => {
    e.preventDefault();
    setShowViewAll(!showViewAll);
  };

  // Function to handle date range change
  const onDateChange = (ranges) => {
    const { selection } = ranges;
    if (selection.startDate.getTime() !== selection.endDate.getTime()) {
      // Load new data based on the selected date range
      getAnalyticsData(selection);
    }
    // Update the date range state
    setDateRange(selection);
  };

  const toggleViewReferrers = () => {
    setShowReferrers(!showReferrers);
  };

  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper analytics-wrapper">
        <SideBar page="telegram-mini-app-analytics-sweepstakes" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center justify-content-between">
              <h1>Stats</h1>
              <div className="d-flex align-items-center">
                <span className="mx-2">
                  {dateRange.startDate.toLocaleDateString("en-US")} - {dateRange.endDate.toLocaleDateString("en-US")}
                </span>
                <DateRangeCalendarPicker dateRange={dateRange} setDateRange={setDateRange} onChange={onDateChange} />
              </div>
            </div>
            <div className="fwc-wrapper">
              <div className="flex-wrap grid-card-details-row d-flex">
                <div className="mt-0 gcdr-text-box w-100">
                  <div className="">
                    <div className="mt-0 gcdr-widget-box col-12">
                      <div className="gcdr-widget-box">
                        <ul className="flex-wrap gw-list d-flex eg-grid">
                          <li>
                            <div className="gw-box">
                              <em>Total Sweepstakes Points Entered</em>
                              <h6>{engagementAnalyticsDashboard?.totalPoints?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Completed Sweepstakes</em>
                              <h6>{engagementAnalyticsDashboard?.completedSweepstakes?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Number of Sweepstakes Users</em>
                              <h6>
                                {engagementAnalyticsDashboard?.telegramSweepstakesEntryUniqueUsers?.toLocaleString(
                                  "en-us",
                                )}
                              </h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Number of Sweepstakes Entries</em>
                              <h6>{engagementAnalyticsDashboard?.telegramSweepstakesEntry?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Users Engaged</em>
                              <h6>{engagementAnalyticsDashboard?.allEnageUsers?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>New Users</em>
                              <h6>{engagementAnalyticsDashboard?.registredUser?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Referred Users</em>
                              <h6>{engagementAnalyticsDashboard?.referredUsers?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Non Referred Users</em>
                              <h6>{engagementAnalyticsDashboard?.nonReferredUsers?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Number of Spins</em>
                              <h6>{engagementAnalyticsDashboard?.telegramSpinHistory?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Unique Users used Spinner</em>
                              <h6>
                                {engagementAnalyticsDashboard?.telegramSpinHistoryUniqueUsers?.toLocaleString("en-us")}
                              </h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Number of Games Played</em>
                              <h6>{engagementAnalyticsDashboard?.telegramUserGame?.toLocaleString("en-us")}</h6>
                            </div>
                          </li>
                          <li>
                            <div className="gw-box">
                              <em>Number of Bets Placed</em>
                              <h6>
                                {(
                                  engagementAnalyticsDashboard?.telegramCowitzerBet +
                                  engagementAnalyticsDashboard?.telegramRobotRacingBet +
                                  engagementAnalyticsDashboard?.telegramZedRunBet
                                )?.toLocaleString("en-us")}
                              </h6>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {(loadingGames || engagementAnalyticsGames?.gamePlayCount?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between">
                          <h4>Skill Based Games</h4>{" "}
                        </div>

                        {loadingDashboard ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : engagementAnalyticsGames?.gamePlayCount?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6 table-responsive">
                              <table className="table custom-table-striped ">
                                <thead>
                                  <tr>
                                    <th>Games</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {engagementAnalyticsGames?.gamePlayCount?.length > 0 &&
                                    engagementAnalyticsGames?.gamePlayCount?.map((status, index) => (
                                      <tr key={status?._id}>
                                        <td className="camelCase">{status?.name}</td>
                                        <td>{status?.count?.toLocaleString("en-us")}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {engagementAnalyticsGames?.gamePlayCount?.length === 0 &&
                                (analyticsLoading ? (
                                  <div className="w-100 d-flex justify-content-center load-more">
                                    <img src={loadingIcon} className="fa-spin" alt="" />
                                  </div>
                                ) : (
                                  <div className="nfr-box">No Records Found!</div>
                                ))}
                            </div>{" "}
                            <div className="col-6 d-flex justify-content-between align-items-center">
                              {engagementAnalyticsGames?.gamePlayCount?.length > 0 && (
                                <PieChart
                                  className="my-2 w-100"
                                  series={[
                                    {
                                      data: engagementAnalyticsGames?.gamePlayCount?.map((game, index) => ({
                                        id: index,
                                        value: game.count,
                                        label: game.name,
                                      })),
                                    },
                                  ]}
                                  colors={generateShades(
                                    "#E46223",
                                    engagementAnalyticsGames?.gamePlayCount?.length || 0,
                                  )}
                                  width={400}
                                  height={200}
                                  slotProps={{
                                    legend: {
                                      hidden: true,
                                    },
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingDashboard ||
                    engagementAnalyticsDashboard?.telegramCowitzerBet > 0 ||
                    engagementAnalyticsDashboard?.telegramRobotRacingBet > 0 ||
                    engagementAnalyticsDashboard?.telegramRobotRacingBet > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between">
                          <h4>Luck Based Games</h4>{" "}
                        </div>
                        {loadingDashboard ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : engagementAnalyticsDashboard?.telegramCowitzerBet === 0 &&
                          engagementAnalyticsDashboard?.telegramRobotRacingBet === 0 &&
                          engagementAnalyticsDashboard?.telegramRobotRacingBet === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12 table-responsive">
                              <table className="table custom-table-striped ">
                                <thead>
                                  <tr>
                                    <th>Games</th>
                                    <th>Total Bet Points</th>
                                    <th>Bets</th>
                                    <th>Users</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="camelCase">Cowitzer</td>
                                    <td>
                                      {engagementAnalyticsDashboard?.totalCowitzerBetAmount?.toLocaleString("en-us")}
                                    </td>{" "}
                                    <td>
                                      {engagementAnalyticsDashboard?.telegramCowitzerBet?.toLocaleString("en-us")}
                                    </td>{" "}
                                    <td>
                                      {engagementAnalyticsDashboard?.telegramCowitzerBetUniqueUsers?.toLocaleString(
                                        "en-us",
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="camelCase">Robot Racing</td>
                                    <td>
                                      {engagementAnalyticsDashboard?.totalRobotRacingBetAmount?.toLocaleString("en-us")}
                                    </td>{" "}
                                    <td>
                                      {engagementAnalyticsDashboard?.telegramRobotRacingBet?.toLocaleString("en-us")}
                                    </td>{" "}
                                    <td>
                                      {engagementAnalyticsDashboard?.telegramRobotRacingBetUniqueUsers?.toLocaleString(
                                        "en-us",
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="camelCase">Zed Run</td>
                                    <td>
                                      {engagementAnalyticsDashboard?.totalZedRunBetAmount?.toLocaleString("en-us")}
                                    </td>{" "}
                                    <td>{engagementAnalyticsDashboard?.telegramZedRunBet?.toLocaleString("en-us")}</td>{" "}
                                    <td>
                                      {engagementAnalyticsDashboard?.telegramZedRunBetUniqueUsers?.toLocaleString(
                                        "en-us",
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>{" "}
                            <div className="col-12">
                              <div className="row">
                                {" "}
                                <div className="col-4">
                                  <h4>Total Bet Points</h4>{" "}
                                  <PieChart
                                    className="my-2 w-100"
                                    series={[
                                      {
                                        data: [
                                          {
                                            id: 1,
                                            value: engagementAnalyticsDashboard?.totalCowitzerBetAmount,
                                            label: "Cowitzer",
                                          },
                                          {
                                            id: 2,
                                            value: engagementAnalyticsDashboard?.totalRobotRacingBetAmount,
                                            label: "Robot Racing",
                                          },
                                          {
                                            id: 3,
                                            value: engagementAnalyticsDashboard?.totalZedRunBetAmount,
                                            label: "Zed Run",
                                          },
                                        ],
                                      },
                                    ]}
                                    width={400}
                                    height={200}
                                    colors={generateShades("#E46223", 3)}
                                    slotProps={{
                                      legend: { hidden: true },
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <h4>Bets</h4>{" "}
                                  <PieChart
                                    className="my-2 w-100"
                                    series={[
                                      {
                                        data: [
                                          {
                                            id: 1,
                                            value: engagementAnalyticsDashboard?.telegramCowitzerBet,
                                            label: "Cowitzer",
                                          },
                                          {
                                            id: 2,
                                            value: engagementAnalyticsDashboard?.telegramRobotRacingBet,
                                            label: "Robot Racing",
                                          },
                                          {
                                            id: 3,
                                            value: engagementAnalyticsDashboard?.telegramZedRunBet,
                                            label: "Zed Run",
                                          },
                                        ],
                                      },
                                    ]}
                                    width={400}
                                    height={200}
                                    colors={generateShades("#E46223", 3)}
                                    slotProps={{
                                      legend: { hidden: true },
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <h4>Users</h4>{" "}
                                  <PieChart
                                    className="my-2 w-100"
                                    series={[
                                      {
                                        data: [
                                          {
                                            id: 11,
                                            value: engagementAnalyticsDashboard?.telegramCowitzerBetUniqueUsers,
                                            label: "Cowitzer",
                                          },
                                          {
                                            id: 12,
                                            value: engagementAnalyticsDashboard?.telegramRobotRacingBetUniqueUsers,
                                            label: "Robot Racing",
                                          },
                                          {
                                            id: 13,
                                            value: engagementAnalyticsDashboard?.telegramZedRunBetUniqueUsers,
                                            label: "Zed Run",
                                          },
                                        ],
                                      },
                                    ]}
                                    width={400}
                                    colors={generateShades("#E46223", 3)}
                                    height={200}
                                    slotProps={{
                                      legend: { hidden: true },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingSpinRewards || spinRewardAnalytics?.spins?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      {spinRewardAnalytics?.spins?.[0]?.pointStats?.filter((s) => s._id)?.length > 0 && (
                        <div className="mt-0 gcdr-widget-box col-6">
                          <div className="d-flex justify-content-between">
                            <h4>
                              Spinner Points Rewards (Overall Points -{" "}
                              {spinRewardAnalytics?.spins?.[0]?.overallPointCount?.[0]?.totalCount?.toLocaleString(
                                "en-us",
                              )}
                              )
                            </h4>{" "}
                          </div>

                          {loadingSpinRewards ? (
                            <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-12 table-responsive">
                                <table className="table custom-table-striped ">
                                  <thead>
                                    <tr>
                                      <th>Points</th>
                                      <th>Count</th>
                                      <th>Sum</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {spinRewardAnalytics?.spins?.[0]?.pointStats?.length > 0 &&
                                      spinRewardAnalytics?.spins?.[0]?.pointStats
                                        ?.filter((s) => s._id)
                                        ?.map((spin, index) => (
                                          <tr key={spin?._id}>
                                            <td>{spin?._id?.toLocaleString("en-us")} </td>
                                            <td>{spin?.count?.toLocaleString("en-us")} </td>
                                            <td>{spin?.totalSum?.toLocaleString("en-us")} </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>
                              </div>{" "}
                            </div>
                          )}
                        </div>
                      )}
                      {spinRewardAnalytics?.spins?.[0]?.usdtStats?.filter((s) => s._id)?.length > 0 && (
                        <div className="mt-0 gcdr-widget-box col-6">
                          <div className="d-flex justify-content-between">
                            <h4>
                              Spinner USDT Rewards (Overall USDT -{" "}
                              {spinRewardAnalytics?.spins?.[0]?.overallUsdtCount?.[0]?.totalCount?.toLocaleString(
                                "en-us",
                              )}
                              )
                            </h4>{" "}
                          </div>

                          {loadingSpinRewards ? (
                            <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-12 table-responsive">
                                <table className="table custom-table-striped ">
                                  <thead>
                                    <tr>
                                      <th>USDT</th>
                                      <th>Count</th>
                                      <th>Sum</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {spinRewardAnalytics?.spins?.[0]?.usdtStats?.length > 0 &&
                                      spinRewardAnalytics?.spins?.[0]?.usdtStats
                                        ?.filter((s) => s._id)
                                        ?.map((spin, index) => (
                                          <tr key={spin?._id}>
                                            <td>{spin?._id?.toLocaleString("en-us")} </td>
                                            <td className="camelCase">{spin?.count?.toLocaleString("en-us")} </td>
                                            <td className="camelCase">{spin?.totalSum?.toLocaleString("en-us")} </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>
                              </div>{" "}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {(loadingHourlyEngagement ||
                    engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mb-2 d-flex justify-content-between w-100 align-items-center">
                            <h4 className="m-0">Hourly Engagement Count</h4>{" "}
                            {engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.length > 5 && (
                              <Link
                                to="#"
                                className="m-0 back-link-btn"
                                onClick={(e) => {
                                  setFlyer({
                                    title: "Hourly Engagement Count",
                                    data: engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.map((user) => ({
                                      col1: user?.userCount?.toLocaleString("en-us"),
                                      col2: `${getFormattedHours(user?.hour)} - ${getFormattedHours(
                                        user?.hour === 23 ? 0 : user?.hour + 1,
                                      )}`,
                                    })),
                                    col1Name: "Users",
                                    col2Name: "Hours",
                                  });
                                  setShowViewAll(true);
                                }}
                              >
                                View all
                              </Link>
                            )}
                          </div>
                        </div>
                        {loadingHourlyEngagement ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table custom-table-striped ">
                                  <thead>
                                    <tr>
                                      <th>Users</th>
                                      <th>Hours</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sortedHourlyEngagementCounts?.length > 0 &&
                                      sortedHourlyEngagementCounts?.map((user, index) => (
                                        <tr key={user?._id}>
                                          <td className="camelCase">{user?.userCount?.toLocaleString("en-us")}</td>
                                          <td>
                                            {getFormattedHours(user?.hour)} -{" "}
                                            {getFormattedHours(user?.hour === 23 ? 0 : user?.hour + 1)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                {engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.length === 0 &&
                                  (analyticsLoading ? (
                                    <div className="w-100 d-flex justify-content-center load-more">
                                      <img src={loadingIcon} className="fa-spin" alt="" />
                                    </div>
                                  ) : (
                                    <div className="nfr-box">No Records Found!</div>
                                  ))}
                              </div>
                            </div>{" "}
                            <div className="col-6 d-flex justify-content-between ">
                              {engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.length > 0 && (
                                <LineChart
                                  xAxis={[
                                    {
                                      label: "Hours (24 Hours)",
                                      data: engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.map(
                                        (user) => user.hour,
                                      ),
                                    },
                                  ]}
                                  yAxis={[
                                    {
                                      label: "Users",
                                      data: engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.map(
                                        (user) => user.userCount,
                                      ),
                                      labelStyle: {
                                        transform: "translate(-38px,-35px) rotate(-90deg)",
                                        transformOrigin: "left",
                                      },
                                    },
                                  ]}
                                  series={[
                                    {
                                      data: engagementAnalyticsHourlyEngagementCounts?.totalEngagement?.map(
                                        (user) => user.userCount,
                                      ),
                                    },
                                  ]}
                                  colors={["#E46223"]}
                                  width={500}
                                  height={300}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingHourlyGamesCount || engagementAnalyticsHourlyGamesCounts?.gameCount?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between w-100">
                            <h4>Hourly Games Count</h4>{" "}
                            {engagementAnalyticsHourlyGamesCounts?.gameCount?.length > 5 && (
                              <Link
                                to="#"
                                className="m-0 back-link-btn"
                                onClick={(e) => {
                                  setFlyer({
                                    title: "Hourly Games Count",
                                    data: engagementAnalyticsHourlyGamesCounts?.gameCount?.map((game) => ({
                                      col1: game?.userCount?.toLocaleString("en-us"),
                                      col2: `${getFormattedHours(game?.hour)} - ${getFormattedHours(
                                        game?.hour === 23 ? 0 : game?.hour + 1,
                                      )}`,
                                    })),
                                    col1Name: "Games",
                                    col2Name: "Hours",
                                  });
                                  setShowViewAll(true);
                                }}
                              >
                                View all
                              </Link>
                            )}
                          </div>
                        </div>
                        {loadingHourlyGamesCount ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : engagementAnalyticsHourlyGamesCounts?.gameCount?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            {" "}
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table custom-table-striped ">
                                  <thead>
                                    <tr>
                                      <th>Games</th>
                                      <th>Hours</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sortedHourlyGamesCounts?.length > 0 &&
                                      sortedHourlyGamesCounts?.map((user, index) => (
                                        <tr key={user?._id}>
                                          <td className="camelCase">{user?.userCount?.toLocaleString("en-us")}</td>
                                          <td>
                                            {getFormattedHours(user?.hour)} -{" "}
                                            {getFormattedHours(user?.hour === 23 ? 0 : user?.hour + 1)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                {engagementAnalyticsHourlyGamesCounts?.gameCount?.length === 0 &&
                                  (analyticsLoading ? (
                                    <div className="w-100 d-flex justify-content-center load-more">
                                      <img src={loadingIcon} className="fa-spin" alt="" />
                                    </div>
                                  ) : (
                                    <div className="nfr-box">No Records Found!</div>
                                  ))}
                              </div>
                            </div>
                            <div className="col-6 d-flex justify-content-between ">
                              {engagementAnalyticsHourlyGamesCounts?.gameCount?.length > 0 && (
                                <LineChart
                                  xAxis={[
                                    {
                                      label: "Hours (24 Hours)",
                                      data: engagementAnalyticsHourlyGamesCounts?.gameCount?.map((user) => user.hour),
                                    },
                                  ]}
                                  yAxis={[
                                    {
                                      label: "Games Count",
                                      data: engagementAnalyticsHourlyGamesCounts?.gameCount?.map(
                                        (user) => user.userCount,
                                      ),
                                      labelStyle: {
                                        transform: "translate(-38px,-35px) rotate(-90deg)",
                                        transformOrigin: "left",
                                      },
                                    },
                                  ]}
                                  series={[
                                    {
                                      data: engagementAnalyticsHourlyGamesCounts?.gameCount?.map(
                                        (user) => user.userCount,
                                      ),
                                    },
                                  ]}
                                  width={500}
                                  colors={["#E46223"]}
                                  height={300}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingCountries || userAnalyticsCountries?.length > 0) && (
                    <div className=" gcdr-widget-wrapper w-100 row">
                      <div className="mt-0 gcdr-widget-box col-6">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4>Countries</h4>{" "}
                          {userAnalyticsCountries?.length > 10 && (
                            <Link
                              to="#"
                              className="m-0 back-link-btn"
                              onClick={(e) => {
                                setFlyer({
                                  title: "Countries",
                                  data: userAnalyticsCountries?.map((country) => ({
                                    col1: country?._id,
                                    col2: country?.count?.toLocaleString("en-us"),
                                  })),
                                  col1Name: "Country",
                                  col2Name: "Count",
                                });
                                setShowViewAll(true);
                              }}
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        {loadingCountries ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : userAnalyticsCountries?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <table className="table custom-table-striped ">
                              <thead>
                                <tr>
                                  <th>Country</th>
                                  <th>Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userAnalyticsCountries?.slice(0, 10)?.map((country, index) => (
                                  <tr>
                                    <td className="camelCase" key={index}>
                                      {country?._id ?? "Users without country"}
                                    </td>
                                    <td>{country?.count?.toLocaleString("en-us")}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingReferrals || userAnalyticsReferrals?.topReferredUsers?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row ">
                      <div className="mt-0 gcdr-widget-box col-6">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4>Top Referrers</h4>{" "}
                          {userAnalyticsReferrals?.topReferredUsers?.length > 5 && (
                            <Link to="#" className="m-0 back-link-btn" onClick={toggleViewReferrers}>
                              View all
                            </Link>
                          )}
                        </div>
                        {loadingReferrals ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : userAnalyticsReferrals?.topReferredUsers?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <table className="table custom-table-striped ">
                              <thead>
                                <tr>
                                  <th>Users</th>
                                  <th col="6">Referral Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userAnalyticsReferrals?.topReferredUsers?.slice(0, 5)?.map((user, index) => (
                                  <tr>
                                    <td className="camelCase" key={index}>
                                      <span>
                                        <div className="td-img-group d-flex align-items-start ">
                                          <div className="td-img round-img m-right-2 pointer">
                                            <img
                                              src={user.image ? process.env.REACT_APP_CDN + user.image.small : userIcon}
                                              alt=""
                                            />
                                          </div>

                                          <div>
                                            {user?.firstName} {user?.lastName}
                                            <br />
                                            {user?.userName} <br />
                                            {user?.telegramId}
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td>{user?.referredUsersCount?.toLocaleString("en-us")}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>{" "}
                            {!userAnalyticsReferralsLoading &&
                              userAnalyticsReferrals?.topReferredUsers?.length === 0 && (
                                <div className="nfr-box">No Records Found!</div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(loadingUsers || engagementAnalyticsHourlyUserCounts?.userCount?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row ">
                      <div className="mt-0 gcdr-widget-box col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
                            <h4 className="m-0">User Registration Hourly Stats </h4>{" "}
                            {engagementAnalyticsHourlyUserCounts?.userCount?.length > 5 && (
                              <Link
                                to="#"
                                className="m-0 back-link-btn"
                                onClick={(e) => {
                                  setFlyer({
                                    title: "User Registration Hourly Stats",
                                    data: engagementAnalyticsHourlyUserCounts?.userCount?.map((user) => ({
                                      col1: user?.userCount?.toLocaleString("en-us"),
                                      col2: `${getFormattedHours(user?.hour)} - ${getFormattedHours(
                                        user?.hour === 23 ? 0 : user?.hour + 1,
                                      )}`,
                                    })),
                                    col1Name: "Users",
                                    col2Name: "Hours",
                                  });
                                  setShowViewAll(true);
                                }}
                              >
                                View all
                              </Link>
                            )}
                          </div>
                        </div>
                        {loadingUsers ? (
                          <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        ) : engagementAnalyticsHourlyUserCounts?.userCount?.length === 0 ? (
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table custom-table-striped ">
                                  <thead>
                                    <tr>
                                      <th>Users</th>
                                      <th>Hours</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sortedHourlyUserCounts?.length > 0 &&
                                      sortedHourlyUserCounts?.map((user, index) => (
                                        <tr key={user?._id}>
                                          <td className="camelCase">{user?.userCount?.toLocaleString("en-us")}</td>
                                          <td>
                                            {getFormattedHours(user?.hour)} -{" "}
                                            {getFormattedHours(user?.hour === 23 ? 0 : user?.hour + 1)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                {engagementAnalyticsHourlyUserCounts?.userCount?.length === 0 &&
                                  (analyticsLoading ? (
                                    <div className="w-100 d-flex justify-content-center load-more">
                                      <img src={loadingIcon} className="fa-spin" alt="" />
                                    </div>
                                  ) : (
                                    <div className="nfr-box">No Records Found!</div>
                                  ))}
                              </div>
                            </div>
                            <div className="col-6 d-flex justify-content-between">
                              {engagementAnalyticsHourlyUserCounts?.userCount?.length > 0 && (
                                <LineChart
                                  xAxis={[
                                    {
                                      label: "Hours (24 Hours)",
                                      data: engagementAnalyticsHourlyUserCounts?.userCount?.map((user) => user.hour),
                                    },
                                  ]}
                                  yAxis={[
                                    {
                                      label: "Users",
                                      data: engagementAnalyticsHourlyUserCounts?.userCount?.map(
                                        (user) => user.userCount,
                                      ),
                                      labelStyle: {
                                        transform: "translate(-38px,-35px) rotate(-90deg)",
                                        transformOrigin: "left",
                                      },
                                    },
                                  ]}
                                  series={[
                                    {
                                      data: engagementAnalyticsHourlyUserCounts?.userCount?.map(
                                        (user) => user.userCount,
                                      ),
                                    },
                                  ]}
                                  width={500}
                                  height={300}
                                  colors={["#E46223"]}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}{" "}
                  {(loadingAppLaunchData || appLaunchData?.launchCount?.length > 0) && (
                    <div className="gcdr-widget-wrapper w-100 row">
                      {appLaunchData?.launchCount?.[0]?.topUsers?.length > 0 && (
                        <>
                          <div className="mt-0 gcdr-widget-box col-6">
                            <div className="d-flex justify-content-between">
                              <h4>App Launch Analytics (Since 12/18/2024)</h4>
                            </div>

                            {loadingAppLaunchData ? (
                              <div className="w-100 d-flex justify-content-center load-more" style={{ height: 400 }}>
                                <img src={loadingIcon} className="fa-spin" alt="" />
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-12 table-responsive">
                                  <table className="table custom-table-striped ">
                                    <thead>
                                      <tr>
                                        <th>Users</th>
                                        <th>Launch Count</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {appLaunchData?.launchCount?.[0]?.topUsers?.length > 0 &&
                                        appLaunchData?.launchCount?.[0]?.topUsers?.map((user, index) => (
                                          <tr key={user?.telegramUserId}>
                                            <td>
                                              {user?.firstName} {user?.lastName} <br />
                                              {user?.userName} <br />
                                              {user?.telegramId}
                                            </td>
                                            <td>{user?.launchCount}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>{" "}
                              </div>
                            )}
                          </div>
                          <div className="gcdr-widget-box col-6 eg-sub-grid-wrapper">
                            <ul className="flex-wrap gw-list d-flex eg-sub-grid">
                              <li>
                                <div className="gw-box">
                                  <em>Total App Launch</em>
                                  <h6>{appLaunchData?.launchCount?.[0]?.totalLaunches?.[0]?.totalLaunches}</h6>
                                </div>
                              </li>
                              <li>
                                <div className="gw-box">
                                  <em>Total Users</em>
                                  <h6>{appLaunchData?.launchCount?.[0]?.totalLaunches?.[0]?.uniqueUsers}</h6>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AnalyticsAllDataFlyer
        title={flyer?.title}
        data={flyer?.data}
        col1Name={flyer?.col1Name}
        col2Name={flyer?.col2Name}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
      />
      <ReferrersFlyer
        showModal={showReferrers}
        toggleModal={toggleViewReferrers}
        referrerUsers={userAnalyticsReferrals?.topReferredUsers}
      />
      {(showReferrers || showViewAll) && (
        <div className="modal-backdrop show fade" onClick={showReferrers ? toggleViewReferrers : toggleViewAll}></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadEngagementAnalyticsDashboard: (params, callback) => dispatch(loadEngagementAnalyticsDashboard(params, callback)),
  loadEngagementAnalyticsGames: (params, callback) => dispatch(loadEngagementAnalyticsGames(params, callback)),
  loadEngagementAnalyticsHourlyEngagementCounts: (params, callback) =>
    dispatch(loadEngagementAnalyticsHourlyEngagementCounts(params, callback)),
  loadEngagementAnalyticsHourlyGamesCounts: (params, callback) =>
    dispatch(loadEngagementAnalyticsHourlyGamesCounts(params, callback)),
  loadUserAnalyticsCountries: (params, callback) => dispatch(loadUserAnalyticsCountries(params, callback)),
  loadSpinRewardAnalytics: (params, callback) => dispatch(loadSpinRewardAnalytics(params, callback)),
  loadUserAnalyticsReferrals: (params, callback) => dispatch(loadUserAnalyticsReferrals(params, callback)),
  loadEngagementAnalyticsHourlyUserCounts: (params, callback) =>
    dispatch(loadEngagementAnalyticsHourlyUserCounts(params, callback)),
  loadAppLaunchData: (params, callback) => dispatch(loadAppLaunchData(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TelegramEngagementAnalytics));
