import React from "react";
import { Link } from "react-router-dom";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";

/**
 * SortIcon component renders a clickable icon for sorting
 *
 * @param {string} props.sortIconType - The type of the sort icon
 * @param {function} props.handleSort - Function to handle the sort action
 * @param {string} props.sort - Current sort type
 * @param {string} props.order - Current sort order
 */
const SortIcon = ({ sortIconType, handleSort, sort, order }) => {
  return (
    <span onClick={handleSort} role="button">
      <img src={sortIconType === sort ? (order === "desc" ? arrowIcon1 : arrowIcon2) : arrowIcon} alt="" />
    </span>
  );
};

export default SortIcon;
