import React, { createRef, Component, Fragment } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Form, Dropdown } from "react-bootstrap";
import { getTelegram, loadSweepstakesWinners } from "store/telegram";

import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _, { get } from "lodash";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { getNumberWithOrdinal } from "utils/misc";
import { Popover, OverlayTrigger } from "react-bootstrap";

//  Images
import userIcon from "include/images/user.png";
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import loader from "include/images/loading-icon-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import copyIcon from "include/images/copy.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Select from "common/form/selectSearch";
import AlertModal from "common/alert/alertModal";
import { Joi } from "joi-browser";
import { setFilters } from "utils/localStorageServices";
import { convertDate, formatDate } from "utils/dateConversion";
import AlertSuccess from "common/alert/alertSuccess";
// import ReasonFlyer from "./reasonFlyer";
import HistoryFlyer from "common/historyFlyer";
import AlertError from "common/alert/alertError";
import { TELEGRAM_SWEEPSTAKES_PRIZE_TYPES } from "utils/constants";

class ListingFlyer extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    loadingReason: false,
    showRevertModal: false,
    showReasonFlyer: false,
    reasonType: "",
    gameId: "",
    showHistory: false,
    objectId: "",
    object: null,
    refundIndex: 0,
    game: null,
    metaType: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.props.loadSweepstakesWinners(this.props.instance?._id, null);
    }
  };

  render() {
    const { getTelegram, contest, instance } = this.props;
    const leaderboard = getTelegram?.winners;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={`offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2`}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            {instance && (
              <div className="td-img-group d-flex align-items-start">
                <div className="round-img m-right-2">
                  {instance.image ? (
                    <img src={process.env.REACT_APP_CDN + instance?.image} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <div>
                  {instance.name}
                  <br />
                  <div class="small-text">
                    <small>
                      {moment(instance?.startDate).format("MM/DD/YYYY")} {moment(instance?.startDate).format("hh:mm a")}{" "}
                      - {moment(instance?.endDate).format("MM/DD/YYYY")} {moment(instance?.endDate).format("hh:mm a")}
                    </small>{" "}
                  </div>{" "}
                </div>
              </div>
            )}
            <div className="d-flex flex-column justify-content-between align-items-end h-100">
              <div
                className="ml-40 btn_close pointer"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={this.props.toggleModal}
              >
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.props.getTelegram.loading && (
                  <div className="table-responsive">
                    {leaderboard?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Users</th>
                            <th>Points Entered</th>
                            <th>Prize</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leaderboard?.map((instance, index) => {
                            return (
                              <tr>
                                <td>{instance?.rank}</td>
                                <td className="td-img-group d-flex align-items-start">
                                  <div className="td-img round-img m-right-2">
                                    {instance?.userId?.image ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_CDN + instance.userId.image.small ||
                                          instance?.user?.image?.original
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img src={userIcon} alt="" />
                                    )}
                                  </div>
                                  {instance?.userId && (
                                    <div className="">
                                      <div>
                                        {instance?.userId?.firstName} {instance?.userId?.lastName}
                                      </div>
                                      {instance?.userId?.userName}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {instance?.pointsEntered?.toLocaleString("en-us")}{" "}
                                  {instance?.pointsEntered > 1 ? "Points" : "Point"}
                                </td>
                                <td>
                                  {instance?.prize?.quantity}{" "}
                                  {TELEGRAM_SWEEPSTAKES_PRIZE_TYPES[instance?.prize?.prizeType]}
                                  {instance?.prize?.prizeType !== "usdt" && instance?.prize?.quantity > 1 && "s"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <AlertModal
          alertModalToggle={this.toggleModal}
          alertModalShow={this.state.showAlert}
          title="Alert!"
          classData="z-index-high"
          description="Are you sure you want to mark this user as winner?"
          alertType="Yes"
          onPress={this.markWinner}
          loading={this.state.loadingWinner}
        />{" "}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"game"}
          objectId={this.state.objectId}
          title={contest?.title}
        />
        <AlertModal
          alertModalToggle={this.revertModalToggle}
          alertModalShow={this.state.showRevertModal}
          title="Alert"
          classData="z-index-high"
          description={"Are you sure you want to mark this game as legitimate again?"}
          onPress={(e) => {
            this.handleMarkAsIllegitimate(e, "", () => {
              this.revertModalToggle();
            });
          }}
          loading={this.state.loadingReason}
          alertType="Yes"
        />{" "}
        {(this.state.showReasonFlyer || this.state.showHistory) && (
          <div
            className="modal-backdrop z-index-high show fade"
            onClick={this.state.showHistory ? this.toggleHistoryFlyer : this.alertModalToggle}
          ></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSweepstakesWinners: (contestId, gameId, params, callback) =>
    dispatch(loadSweepstakesWinners(contestId, gameId, params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
