import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { Component, createRef } from "react";
import { Link, withRouter } from "react-router-dom";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteQuest, getTelegram, loadQuests } from "store/telegram";
import AddQuests from "./addQuests";
import DeleteModal from "common/alert/alertModal";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class QuestsListings extends Component {
  state = {
    loadingMain: false,
    loadingDelete: false,
    showAddModal: false,
    editData: null,
    cancelId: null,
    showDeleteModal: false,
  };
  ref = createRef(null);
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadQuests(() => {
      this.setState({ loadingMain: false });
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.questLoading &&
      this.props.getTelegram.questLoading !== prevProps.getTelegram.questLoading
    ) {
      this.ref.current.complete();
    }
    if (
      this.props.getTelegram.questLoading &&
      this.props.getTelegram.questLoading !== prevProps.getTelegram.questLoading
    ) {
      this.ref.current.continuousStart();
    }
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      this.setState({ editData: null });
    }
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  cancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  handleCancel = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteQuest(this.state.cancelId, (res) => {
      if (res?.status === 200) {
        toast(<AlertSuccess message="Canceled!" />, {
          containerId: 1,
        });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      this.props.loadQuests();
      this.cancelModalToggle();
      this.setState({ loadingDelete: false });
    });
  };
  render() {
    const { quests } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-quest" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1 className="camelCase">Quests</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link
                      to="#"
                      onClick={this.toggleAddModal}
                      className="btn btn-default btn-sm d-flex justify-content-center"
                    >
                      <img src={plusIcon} alt="" /> Add a Quest
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loadingMain && (
                    <div className="table-responsive">
                      {quests.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th style={{ width: "22%" }}>Title</th>
                              <th style={{ width: "22%" }}>Description</th>
                              <th>Image</th>
                              <th>URL</th>
                              <th>Reward</th>
                              <th>Display Order</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quests?.map((quest, ind) => (
                              <tr>
                                <td>
                                  <Dropdown className=" custom-dropdown">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className={`custom-dropdown-toggle`}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={carrentIcon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ editData: quest }, () => {
                                            this.toggleAddModal();
                                          });
                                        }}
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="dropdown-item delete"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ cancelId: quest?._id }, () => {
                                            this.cancelModalToggle();
                                          });
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>
                                  <Link
                                    to={`!#`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ editData: quest }, () => {
                                        this.toggleAddModal();
                                      });
                                    }}
                                  >
                                    <div class="small-text">{quest?.title}</div>
                                  </Link>
                                </td>
                                <td>{quest?.description}</td>
                                <td>
                                  <div className="td-img margin-right-2 ">
                                    <img src={process.env.REACT_APP_CDN + quest?.image} alt="" />
                                  </div>
                                </td>
                                <td>{quest?.url}</td>
                                <td>{quest?.reward?.toLocaleString("en-us") ?? 0} PTS</td>
                                <td>{quest?.displayOrder}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddQuests showModal={this.state.showAddModal} toggleModal={this.toggleAddModal} edit={this.state.editData} />
        <DeleteModal
          alertModalToggle={this.cancelModalToggle}
          alertModalShow={this.state.showDeleteModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you want to delete this quest?"
          onPress={this.handleCancel}
          loading={this.state.loadingDelete}
        />
        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadQuests: (callback) => dispatch(loadQuests(callback)),
  deleteQuest: (id, data, callback) => dispatch(deleteQuest(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestsListings));
