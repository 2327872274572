import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import moment from "moment";

const VerisoulAuthResults = (props) => {
  const { loadingMain, sorting, sort, page, searchFilters, authResults, filteredCount } = props;
  return (
    <div className="fwc-body">
      {!loadingMain && (
        <div className="table-responsive">
          {filteredCount === 0 ? (
            <div className="fwc-body">
              <div className="no-record-found-container">
                <div className="nfr-box">
                  <img src={emptyIcon} alt="No Record Found" />
                  <div className="nrf-text">
                    <h5>No Records Found!</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <table className="table large-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Decision</th>
                  <th>
                    Account Score{" "}
                    <a href="#!" onClick={(e) => sort(e, 1)}>
                      <img
                        src={
                          sorting?.sort === "accountScore" && sorting?.order === "desc"
                            ? arrowIcon1
                            : sorting?.sort === "accountScore" && sorting?.order === "asc"
                            ? arrowIcon2
                            : arrowIcon
                        }
                        alt=""
                      />
                    </a>
                  </th>
                  <th>
                    Bot{" "}
                    <a href="#!" onClick={(e) => sort(e, 2)}>
                      <img
                        src={
                          sorting?.sort === "bot" && sorting?.order === "desc"
                            ? arrowIcon1
                            : sorting?.sort === "bot" && sorting?.order === "asc"
                            ? arrowIcon2
                            : arrowIcon
                        }
                        alt=""
                      />
                    </a>
                  </th>
                  <th>
                    Risk Signals{" "}
                    <a href="#!" onClick={(e) => sort(e, 3)}>
                      <img
                        src={
                          sorting?.sort === "riskSignals" && sorting?.order === "desc"
                            ? arrowIcon1
                            : sorting?.sort === "riskSignals" && sorting?.order === "asc"
                            ? arrowIcon2
                            : arrowIcon
                        }
                        alt=""
                      />
                    </a>
                  </th>
                  <th>
                    Multiple Accounts{" "}
                    <a href="#!" onClick={(e) => sort(e, 4)}>
                      <img
                        src={
                          sorting?.sort === "multipleAccounts" && sorting?.order === "desc"
                            ? arrowIcon1
                            : sorting?.sort === "multipleAccounts" && sorting?.order === "asc"
                            ? arrowIcon2
                            : arrowIcon
                        }
                        alt=""
                      />
                    </a>
                  </th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {authResults?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      {user?.firstName} {user?.lastName}
                      <br />
                      {user.userName} <br />
                      {user?.accountId}
                    </td>
                    <td className="ust-tag">
                      <span className={`text-center d-block tickets ${user?.decision === "Fake" && "suspended"} `}>
                        {user?.decision}
                      </span>
                    </td>
                    <td>{user?.accountScore}</td>
                    <td>{user?.bot}</td>
                    <td>{user?.riskSignals}</td>
                    <td>{user?.multipleAccounts}</td>
                    <td>
                      {moment(user.createdAt).format("MM/DD/YYYY")}
                      <br />
                      {moment(user.createdAt).format("hh:mm a")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
      {!(page === 1 && filteredCount === 0) && (
        <div className="page-pagination">
          <ul className="pagination  justify-content-end">
            <li className="page-item">
              <a
                class={`page-link ${page === 1 && "disabled"}`}
                href="#!"
                aria-label="Previous"
                onClick={(e) => {
                  e.preventDefault();
                  if (page > 1) {
                    searchFilters(e, page - 1, sorting);
                  }
                }}
              >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
            </li>
            <li className="page-item active">
              <a
                className="page-link"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {page}
              </a>
            </li>
            {filteredCount >= 20 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    searchFilters(e, page + 1, sorting);
                  }}
                >
                  {page + 1}
                </a>
              </li>
            )}
            <li className="page-item">
              <a
                class={`page-link ${authResults?.length < 20 && "disabled"}`}
                href="#!"
                aria-label="Next"
                onClick={(e) => {
                  e.preventDefault();
                  searchFilters(e, page + 1, sorting);
                }}
              >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default VerisoulAuthResults;
