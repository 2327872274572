import { Component, createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { withRouter } from "react-router-dom";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import { connect } from "react-redux";
import { getTelegram, loadDailyTournamentLeaderboard } from "store/telegram";

//  Components

class LeaderboardFlyer extends Component {
  ref = createRef(null);
  state = { loading: true };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });

      this.props.loadDailyTournamentLeaderboard(this.props.leaderboardTournament?._id, {}, (res) => {
        this.setState({ loading: false });
      });
    }
  };
  render() {
    const { leaderboardTournament } = this.props;
    const { dailyTournamentLeaderboard } = this.props.getTelegram;

    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
        show={this.props.showModal}
        onHide={(e) => {
          this.props.toggleModal(e);
        }}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>Daily Tournament Leaderboard</h5>
            <div className="td-img-group d-flex align-items-start m-top-2">
              <div>
                <div>
                  {leaderboardTournament?.bgGame?.name}{" "}
                  {dailyTournamentLeaderboard?.length > 0 && `(${dailyTournamentLeaderboard?.length})`}
                </div>
              </div>
            </div>
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={(e) => {
              this.props.toggleModal(e);
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {" "}
          <div className="">
            <div className="fwc-body">
              {!this.state.loading && (
                <div className="table-responsive">
                  {dailyTournamentLeaderboard?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th style={{ width: "22px" }}>Rankings</th>
                          <th>Player</th>
                          <th>Number Of Entries</th>
                          <th>Score</th>
                          <th>Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyTournamentLeaderboard?.map((player, i) => (
                          <tr>
                            <td>{player?.rank?.toLocaleString("en-us") ?? 0}</td>
                            <td>
                              <span>
                                <div className="td-img-group d-flex align-items-start ">
                                  <div className="td-img round-img m-right-2 pointer">
                                    <img
                                      src={
                                        player?.userDetails?.image
                                          ? process.env.REACT_APP_CDN + player?.userDetails?.image?.small
                                          : userIcon
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <div>
                                    {player?.userDetails?.firstName} {player?.userDetails?.lastName}
                                    <br />
                                    {player?.userDetails?.userName} <br />
                                    {player?.userDetails?.telegramId}
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td>{player?.numberOfEntries?.toLocaleString("en-us") ?? 0}</td>
                            <td>
                              <div className={`td-text-box `}>{player?.totalScore?.toLocaleString("en-us") ?? 0}</div>
                            </td>
                            <td>
                              <div className={`td-text-box `}>
                                {player?.pointAwarded?.toLocaleString("en-us") ?? 0} PTS
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadDailyTournamentLeaderboard: (id, params, callback) =>
    dispatch(loadDailyTournamentLeaderboard(id, params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaderboardFlyer));
