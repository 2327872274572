import moment from "moment/moment";
import React, { createRef } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import carrentIcon from "include/images/carrent-icon.svg";
import filterIcon from "include/images/filter-icon.svg";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";
import { deleteTelgramNotification, getTelegram, loadTelegramNotifications } from "store/telegram";
import parse from "html-react-parser";
import { isEmpty } from "lodash";
import Header from "common/header";
import SideBar from "common/sidebar";
import NextButton from "common/form/submitButton";
import DeleteModal from "common/alert/alertModal";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import SendNotificationFlyer from "./sendNotificationFlyer";
import SelectSearch from "common/form/selectSearch";

class TelegramNotificationsListing extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    data: {
      telegramId: "",
      message: "",
      type: null,
    },
    loading: false,
    loadingFilters: false,
    page: 1,
    deleteId: "",
    errors: {},
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    const params = { page: 1 };
    this.props.loadTelegramNotifications(params, true, (res) => {
      if (res?.status === 200) {
        this.setState({
          loading: false,
        });
      } else this.setState({ loading: false });
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.telegramNotificationsLoading &&
      this.props.getTelegram.telegramNotificationsLoading !== prevProps.getTelegram.telegramNotificationsLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.telegramNotificationsLoading &&
      this.props.getTelegram.telegramNotificationsLoading !== prevProps.getTelegram.telegramNotificationsLoading
    )
      this.ref.current.continuousStart();
  };
  //  Filters
  searchFilters = () => {
    const data = { ...this.state.data };

    const params = {};
    if (data?.telegramId) params.telegramId = data?.telegramId;
    if (data?.message) params.message = data?.message;
    if (data?.type) params.type = data?.type?.value;

    params.page = this.state.page;

    this.setState({ loadingFilters: true });

    this.props.loadTelegramNotifications(params, true, (userRes) => {
      if (userRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          telegramId: "",
          message: "",
          type: null,
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };
  schema = {
    telegramId: Joi.string().allow(""),
    message: Joi.string().allow(""),
    type: Joi.object().allow(null),
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  editNotificationToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showEditNotification: !this.state.showEditNotification });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteTelgramNotification(this.state.deleteId, (res) => {
      if (res?.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={res?.data?.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingDelete: false });
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { loading } = this.state;
    const { telegramNotifications, telegramNotificationsTotalCount } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-notifications" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>
                Telegram Notifications{" "}
                {!loading && telegramNotificationsTotalCount > 0 && `(${telegramNotificationsTotalCount})`}
              </h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("telegramId", "Telegram Id")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("message", "Message")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Type</label>
                          <SelectSearch
                            name="type"
                            options={[
                              {
                                name: "Individual",
                                value: "individual",
                              },
                              {
                                name: "Broadcast",
                                value: "broadcast",
                              },
                            ]}
                            label=""
                            value={this.state.data.type}
                            error={this.state.errors.type}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>{" "}
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>Filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "telegramId"
                                  ? "Telegram Id"
                                  : key === "message"
                                  ? "Message"
                                  : key === "type"
                                  ? "Type"
                                  : key}
                              </span>{" "}
                              : {key === "type" ? this.state.appliedFilters[key].name : this.state.appliedFilters[key]}
                              {key !== "status" && (
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: key === "startDate" || key === "endDate" ? null : "",
                                        },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {telegramNotifications?.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Action</th>
                              <th>Image</th>
                              <th>Message</th>
                              <th>Type</th>
                              <th width={100}>Sent At</th>
                              <th width={100}>Edited At</th>
                              <th width={100}>Deleted At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {telegramNotifications?.map((telegramNotification, i) => (
                              <tr key={telegramNotification?._id}>
                                <td>
                                  {!telegramNotification?.deleted && (
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        {!telegramNotification?.edited && (
                                          <Dropdown.Item
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              this.setState({ telegramNotification: telegramNotification }, () => {
                                                this.editNotificationToggle(e);
                                              });
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                          className="dropdown-item delete"
                                          onClick={(e) => {
                                            this.setState({ deleteId: telegramNotification?.messageGroupId }, () => {
                                              this.alertModalToggle(e);
                                            });
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </td>
                                <td>
                                  <div style={{ width: 60 }}>
                                    {telegramNotification?.image && (
                                      <img src={process.env.REACT_APP_CDN + telegramNotification?.image} alt="" />
                                    )}
                                  </div>
                                </td>
                                <td>{telegramNotification?.message && parse(telegramNotification?.message)}</td>
                                <td className="text-capitalize">{telegramNotification?.type}</td>
                                <td>
                                  <div className="td-text-box">
                                    {telegramNotification?.sentAt &&
                                      moment(telegramNotification?.sentAt).format("MM/DD/YYYY hh:mm a")}
                                  </div>
                                </td>
                                <td>
                                  <div className="td-text-box">
                                    {telegramNotification?.editedAt &&
                                      moment(telegramNotification?.editedAt).format("MM/DD/YYYY hh:mm a")}
                                  </div>
                                </td>
                                <td>
                                  <div className="td-text-box">
                                    {telegramNotification?.deletedAt &&
                                      moment(telegramNotification?.deletedAt).format("MM/DD/YYYY hh:mm a")}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && telegramNotifications?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {telegramNotifications?.length >= 20 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${telegramNotifications?.length < 20 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this notification?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        <SendNotificationFlyer
          showModal={this.state.showEditNotification}
          toggleModal={this.editNotificationToggle}
          notification={this.state.telegramNotification}
          reload={this.searchFilters}
        />
        {this.state.showEditNotification && (
          <div className="modal-backdrop z-index-1000 show fade" onClick={this.editNotificationToggle}></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadTelegramNotifications: (params, fresh, callback) => dispatch(loadTelegramNotifications(params, fresh, callback)),
  deleteTelgramNotification: (id, callback) => dispatch(deleteTelgramNotification(id, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TelegramNotificationsListing));
