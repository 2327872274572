import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  addSweepstakesTemplate,
  updateSweepstakesTemplate,
  addSweepstakeTemplateReceived,
  updateSweepstakeTemplateReceived,
  loadPrizes,
  getTelegram,
} from "store/telegram";
import { getSettings } from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import SelectMulti from "common/form/multiSelectSearch";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initTier = { startRank: "", endRank: "", prizes: [] };
  initState = {
    loading: false,
    data: {
      name: "",
      image: null,
      tiers: "",
      minEntry: 1,
    },
    errors: {},
    imageChange: false,
    loadingTemplate: false,
    edit: false,
  };
  state = { ...structuredClone(this.initState) };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        this.setState({ loading: true });
        this.props.loadPrizes();
        const data = { ...this.state.data };
        if (this.props.edit) {
          data.name = this.props.edit.name;
          data.minEntry = this.props.edit.minEntry.toString();
          data.image = this.props.edit.image;
          data.tiers = this.props.edit.tiers?.map((tier) => ({
            name: tier.tierName,
            value: tier._id,
            data: tier,
          }));
        }
        this.setState({
          data,
          loading: false,
          edit: this.props.edit,
        });
      }
    }
  };

  doSubmit = (e) => {
    this.setState({ loadingTemplate: true });
    const data = { ...this.state.data };
    const payLoad = {
      name: data.name,
      minEntry: parseInt(data.minEntry),
      tiers: data.tiers.map((tier) => ({ ...tier.data })),
    };

    if (this.state.imageChange) {
      payLoad.image = data.image.medium;
    } else {
      payLoad.image = data.image;
    }

    if (this.props.edit) {
      this.props.updateSweepstakesTemplate(this.props.edit._id, payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          this.props.updateSweepstakeTemplateReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addSweepstakesTemplate(payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          this.props.addSweepstakeTemplateReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          this.setState({ data });
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;

    delete errors[e.name];
    this.setState({ data, errors });
  };
  schema = {
    name: Joi.string().required(),
    image: Joi.alternatives().try(Joi.string(), Joi.object()).required(),
    minEntry: Joi.number().min(0).required(),
    tiers: Joi.array().items(
      Joi.object().keys({
        data: Joi.object().allow(null),
        name: Joi.string().allow(""),
        value: Joi.string().required(),
      }),
    ),
  };
  clearData = () => {
    this.setState({ ...this.initState });
  };

  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          data,
          imageChange: true,
          loadingImages: false,
          data: {
            ...this.state.data,
            image: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };

  render() {
    const data = this.state.data;
    const prizes = this.props.getTelegram.prizes;
    const loadingPrizes = this.props.getTelegram.loading;
    console.log("error:", this.state.errors);
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end  custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add a"} Sweepstakes Template</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.loading && (
            <div className="form-check-outer">
              <div className="new-form-group">
                <div className="row">
                  <div className="col-8">{this.renderInput("name", "Name")}</div>
                  <div className="col-4">{this.renderInput("minEntry", "Min Entry", "", "number", false)}</div>
                </div>
              </div>

              <div className="new-form-group">
                <label htmlFor="">Prizes</label>
                <SelectMulti
                  name="tiers"
                  options={prizes?.map((s, i) => ({
                    name: s.tierName,
                    value: s._id,
                    data: s,
                  }))}
                  label="Select Prizes"
                  defaultValue={this.state.data.tiers}
                  value={this.state.data.tiers}
                  error={this.state.errors.tiers}
                  onChange={this.onChange}
                  isLoading={loadingPrizes}
                  isDisabled={loadingPrizes}
                />
              </div>
              <div className="flex-wrap kt-upload-row d-flex align-items-start">
                <div
                  className={`kt-upload-outer ${
                    !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                  } ${this.state.errors.image && "border-error"}`}
                >
                  <div className="kt-upload-img">
                    <img
                      src={
                        this.state.data.image
                          ? this.state.data.image.medium
                            ? this.state.data.image.medium.includes("https://")
                              ? this.state.data.image.medium
                              : process.env.REACT_APP_CDN + this.state.data.image.medium
                            : process.env.REACT_APP_CDN + this.state.data.image
                          : plusIcon
                      }
                      alt=""
                    />
                  </div>{" "}
                  {this.state.data.image && (
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = this.state.data;
                        data.image = null;
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} />
                    </div>
                  )}
                </div>
                <div className="kt-file-input">
                  <label htmlFor="formFile" className="kt-label">
                    {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                  </label>
                  <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            disabled={data.prizeType === "NFT" && !this.state.nftFetched}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSweepstakesTemplate: (data, callback) => dispatch(addSweepstakesTemplate(data, callback)),
  updateSweepstakesTemplate: (id, data, callback) => dispatch(updateSweepstakesTemplate(id, data, callback)),
  loadPrizes: (callback) => dispatch(loadPrizes(callback)),
  addSweepstakeTemplateReceived: (payLoad) => dispatch(addSweepstakeTemplateReceived(payLoad)),
  updateSweepstakeTemplateReceived: (payLoad) => dispatch(updateSweepstakeTemplateReceived(payLoad)),
  // loadSweepstakeTemplate: (params, callback) => dispatch(loadSweepstakeTemplate(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
