import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTelegram, loadVerisoulTelegramAuthResults } from "store/telegram";
import VerisoulAuthResults from "./verisoulAuthResults";
const VerisoulTelegramUsersStats = (props) => {
  const ref = useRef();

  const [showViewAll, setShowViewAll] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState({});

  const [showFilters, setShowFilters] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    searchFilters();
  }, []);
  const { verisoulTelegramAuthResults, verisoulTelegramAuthFilteredCount, verisoulTelegramAuthTotalCount } =
    props.getTelegram;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    setShowViewAll(!showViewAll);
  };
  const searchFilters = (e, searchKeyword = "", searchPage = 1, sorting) => {
    if (e) e.preventDefault();
    setLoadingFilters(true);
    if (searchKeyword !== keyword) setKeyword(searchKeyword);
    if (searchPage !== page) setPage(searchPage);
    props.loadVerisoulTelegramAuthResults(
      { keyword: searchKeyword, page: searchPage, sort: sorting?.sort, order: sorting?.order },
      () => {
        setLoadingFilters(false);
        setLoadingMain(false);
      },
    );
  };
  const sort = (e, type) => {
    const data = {};
    if (type === 1) {
      if (sorting?.sort === "accountScore" && sorting?.order === "desc") {
        data.sort = "accountScore";
        data.order = "asc";
      } else {
        data.sort = "accountScore";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (sorting?.sort === "bot" && sorting?.order === "desc") {
        data.sort = "bot";
        data.order = "asc";
      } else {
        data.sort = "bot";
        data.order = "desc";
      }
    } else if (type === 3) {
      if (sorting?.sort === "riskSignals" && sorting?.order === "desc") {
        data.sort = "riskSignals";
        data.order = "asc";
      } else {
        data.sort = "riskSignals";
        data.order = "desc";
      }
    } else if (type === 4) {
      if (sorting?.sort === "multipleAccounts" && sorting?.order === "desc") {
        data.sort = "multipleAccounts";
        data.order = "asc";
      } else {
        data.sort = "multipleAccounts";
        data.order = "desc";
      }
    }
    setSorting(data);

    searchFilters(e, keyword, 1, data);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="telegram-mini-app-verisoul-auth" />
        <div className="main-content-wrapper position-relative">
          <div className="mcw-header d-flex align-items-center">
            <h1>
              Verisoul Authentication Stats{" "}
              {verisoulTelegramAuthTotalCount > 0 && `(${verisoulTelegramAuthTotalCount})`}
            </h1>
            <div className="filter-menu-box d-flex align-items-center ms-auto">
              <div className="fmb-box">
                <a
                  href="#!"
                  className="filter-link-box"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFilters(!showFilters);
                  }}
                >
                  <img src={filterIcon} /> <span>Filters</span>
                </a>
              </div>
            </div>
          </div>
          <div className="fwc-wrapper">
            {showFilters && (
              <div className="fwc-head ">
                <div className="fwc-inner">
                  <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                    <li className="flex-fill width-100">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"keyword"} className="form-label">
                            Keyword
                          </label>

                          <input
                            name={"keyword"}
                            id={"keyword"}
                            className={"form-control"}
                            placeholder="Search via keywords"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                      </div>
                    </li>{" "}
                  </ul>

                  <div className="fwc-btn d-flex align-items-center justify-content-end">
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, "", 1, sorting)}
                      classData="btn-text pointer"
                      label="Reset"
                    />
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, keyword, 1, sorting)}
                      classData="btn btn-primary btn-sm"
                      label="Search"
                      loading={loadingFilters}
                    />
                  </div>
                </div>{" "}
              </div>
            )}
            <VerisoulAuthResults
              authResults={verisoulTelegramAuthResults}
              filteredCount={verisoulTelegramAuthFilteredCount}
              loadingMain={loadingMain}
              sorting={sorting}
              sort={sort}
              page={page}
              searchFilters={(e, page, sorting) => searchFilters(e, keyword, page, sorting)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadVerisoulTelegramAuthResults: (params, callback) => dispatch(loadVerisoulTelegramAuthResults(params, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerisoulTelegramUsersStats));
