import moment from "moment";

export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};
export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(/^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
export const msToHMS = (ms = 0) => {
  // 1- Convert to seconds:
  let seconds = parseInt(ms / 1000);
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = parseInt(seconds % 3600); // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = parseInt(seconds % 60);
  const hoursStr = ("00" + hours).slice(-2);
  const minutesStr = ("00" + minutes).slice(-2);
  const secondsStr = ("00" + seconds).slice(-2);
  return hoursStr + ":" + minutesStr + ":" + secondsStr;
};
// Function to check if a given day is greater than today in UTC
export const isDayGreaterThanTodayInUTC = (day) => {
  const givenDayUTC = moment.utc(day); // Convert the given day to UTC
  const todayUTC = moment.utc().startOf("day"); // Get the current day in UTC and set the time to the start of the day
  return givenDayUTC.isAfter(todayUTC); // Check if the given day is after today
};
// Function to check if a given day is less than today in UTC
export const isDayLessThanTodayInUTC = (day) => {
  const givenDayUTC = moment.utc(day); // Convert the given day to UTC
  const todayUTC = moment.utc().startOf("day"); // Get the current day in UTC and set the time to the start of the day
  return givenDayUTC.isBefore(todayUTC); // Check if the given day is before today
};

export const pluralize = (count, alwaysSingular = false, singular, plural = `${singular}s`) => {
  if (alwaysSingular) {
    return singular; // Return the singular form if alwaysSingular is true
  }
  console.log("count:", count, singular);
  return count === 1 ? singular : plural;
};

export const camelize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const camelCaseToNormal = (text) => {
  return text
    ?.replace(/([A-Z])/g, " $1") // Insert space before each uppercase letter
    ?.toLowerCase() // Convert the entire string to lowercase
    ?.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};
export const getFormattedHours = (hour) => {
  const period = hour < 12 ? "am" : "pm";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${formattedHour}${period}`;
};

/**
 * Generates an array of color shades based on a given base color.
 *
 * @param {string} baseColor - The base color in hexadecimal format (e.g., "#ff0000").
 * @param {number} count - The number of shades to generate.
 * @returns {string[]} An array of generated color shades in hexadecimal format.
 */
export const generateShades = (baseColor, count) => {
  const hexToHSL = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l;

    l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // Achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h * 360, s, l];
  };

  const hslToHex = (h, s, l) => {
    const hueToRGB = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRGB(p, q, h + 1 / 3);
      g = hueToRGB(p, q, h);
      b = hueToRGB(p, q, h - 1 / 3);
    }

    const toHex = (x) =>
      Math.round(x * 255)
        .toString(16)
        .padStart(2, "0");
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const [h, s, l] = hexToHSL(baseColor);
  const shades = [];

  for (let i = 0; i < count; i++) {
    const lightness = Math.min(Math.max(l - i * (0.5 / count), 0), 1); // Gradually decrease lightness
    shades.push(hslToHex(h / 360, s, lightness));
  }

  return shades;
};
