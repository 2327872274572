import moment from "moment/moment";
import React, { createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";
import { getTelegram, loadBattleGameParticipants } from "store/telegram";

class ParticipantsFlyer extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: false,
    loadingMore: false,
    page: 1,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { battleGameParticipants, battleGameParticipantsTotalEntries } = this.props.getTelegram;
      if (!this.state.loadingMore && battleGameParticipants?.length < battleGameParticipantsTotalEntries) {
        this.setState({ loadingMore: true });
        const params = { page: this.state.page + 1 };

        this.props.loadBattleGameParticipants(this.props.battleGameId, params, false, (res) =>
          this.setState({ loadingMore: false, page: this.state.page + 1 }),
        );
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      const params = {};

      this.props.loadBattleGameParticipants(this.props.battleGameId, params, true, (res) => {
        this.setState({ loading: false });
      });
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({
        loading: false,
        loadingMore: false,
        page: 1,
      });
    }

    if (
      !this.props.getTelegram.battleGameParticipantsLoading &&
      this.props.getTelegram.battleGameParticipantsLoading !== prevProps.getTelegram.battleGameParticipantsLoading
    ) {
      this.ref.current.complete();
    }
    if (
      this.props.getTelegram.battleGameParticipantsLoading &&
      this.props.getTelegram.battleGameParticipantsLoading !== prevProps.getTelegram.battleGameParticipantsLoading
    ) {
      this.ref.current.continuousStart();
    }

    if (
      this.props.getTelegram?.battleGameParticipants?.length <
      this.props.getTelegram?.battleGameParticipantsTotalEntries
    ) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  onRefChange = (node, i) => {
    const { battleGameParticipants, battleGameParticipantsTotalEntries } = this.props.getTelegram;

    if (
      i === battleGameParticipants?.length - 1 &&
      battleGameParticipants?.length < battleGameParticipantsTotalEntries
    ) {
      this.scrollRef.current = node;
    }
  };

  render() {
    const { loading } = this.state;
    const { battleGameParticipants, battleGameParticipantsTotalEntries } = this.props.getTelegram;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header">
            <div>
              <h5>
                Participants{" "}
                {!loading && `(${battleGameParticipantsTotalEntries > 0 ? battleGameParticipantsTotalEntries : ""})`}
              </h5>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {battleGameParticipants?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Users</th>
                            <th>Stage</th>
                            <th>Level</th>
                            <th>Equipped Gears</th>
                            <th>Win</th>
                            <th>Reward Transferred</th>
                            <th>Stage Bonus Reward Transferred</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {battleGameParticipants?.map((participant, i) => (
                            <tr key={participant?._id} ref={(node) => this.onRefChange(node, i)}>
                              <td className="td-img-group d-flex align-items-start ">
                                <div className="td-img round-img m-right-2">
                                  {participant?.userDetails?.image ? (
                                    <img
                                      src={process.env.REACT_APP_CDN + participant?.userDetails?.image?.small}
                                      alt=""
                                    />
                                  ) : (
                                    <img src={userIcon} alt="" />
                                  )}
                                </div>

                                <div>
                                  {participant?.userDetails?.firstName} {participant?.userDetails?.lastName}
                                  <br />
                                  {participant?.userDetails?.userName} <br />
                                  {participant?.userDetails?.telegramId}
                                </div>
                              </td>
                              <td>{participant?.stage}</td>
                              <td>{participant?.level}</td>
                              <td>{participant?.gearDetails?.map((gear) => gear?.name)?.join(", ")}</td>
                              <td className="ust-tag">
                                <span
                                  className={`w-100 text-center d-block ${participant?.isWin ? "active" : "suspended"}`}
                                >
                                  {participant?.isWin ? "Yes" : "No"}
                                </span>
                              </td>
                              <td className="ust-tag">
                                <span
                                  className={`w-100 text-center d-block ${
                                    participant?.isRewardTransferred ? "active" : "suspended"
                                  }`}
                                >
                                  {participant?.isRewardTransferred ? "Yes" : "No"}
                                </span>
                              </td>
                              <td className="ust-tag">
                                <span
                                  className={`w-100 text-center d-block ${
                                    participant?.isStageBonusRewardTransferred ? "active" : "suspended"
                                  }`}
                                >
                                  {participant?.isStageBonusRewardTransferred ? "Yes" : "No"}
                                </span>
                              </td>
                              <td>
                                <div className="small-text">
                                  {moment(participant?.updatedAt).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(participant?.updatedAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadBattleGameParticipants: (id, params, fresh, callback) =>
    dispatch(loadBattleGameParticipants(id, params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParticipantsFlyer));
