import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import moment from "moment";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getSettings } from "store/settings";
import {
  addTeamPlayChallengeReceived,
  addTeamPlayChallenge,
  getTelegram,
  updateTeamPlayChallengeReceived,
  updateTeamPlayChallenge,
} from "store/telegram";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import NextButton from "common/form/submitButton";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  // Reference to the file input element
  fileInputRef = React.createRef(null);

  // List of all prize types available
  allPrizesTypes = [
    {
      name: "Point",
      value: 1,
    },
    {
      name: "USDT",
      value: 2,
    },

    {
      name: "Spin",
      value: 4,
    },
  ];

  // Initial state for a tier
  initTier = { startRank: "", endRank: "", prizes: [] };

  // Initial state for the component
  initState = {
    loading: false, // Loading state for the component
    data: {
      title: "", // Title of the challenge
      startDate: "", // Start date of the challenge
      endDate: "", // End date of the challenge
      image: null, // Image associated with the challenge
      claimRewardImage: null, // Image associated with the notification for claiming rewards
      prizeType: { name: "USDT", value: 2 }, // Default prize type
      prizeValue: 1, // Default prize value
    },
    errors: {}, // Object to hold form validation errors
    imageChange: false, // Flag to indicate if the image has been changed
    claimRewardImageChange: false, // Flag to indicate if the image has been changed
    loadingTemplate: false, // Loading state for the template
    edit: false, // Flag to indicate if the form is in edit mode
  };

  // Set the initial state of the component
  state = { ...structuredClone(this.initState) };

  // Lifecycle method to handle component updates
  componentDidUpdate = (prevProps, prevState) => {
    // Check if the showModal prop has changed
    if (this.props.showModal !== prevProps.showModal) {
      // Clear form data
      this.clearData();

      // If the modal is shown, initialize the form with existing data if editing
      if (this.props.showModal) {
        // Set loading state to true
        this.setState({ loading: true });

        // Clone the current state data
        const data = { ...this.state.data };

        // If editing an existing challenge, populate the form with existing data
        if (this.props.edit) {
          data.title = this.props.edit.title;
          data.numberOfGames = this.props.edit.numberOfGames.toString();
          data.startDate = new Date(this.props.edit?.startDate);
          data.endDate = this.props.edit.endDate ? new Date(this.props.edit?.endDate) : "";
          data.image = this.props.edit.image;
          data.claimRewardImage = this.props.edit.claimRewardImage;
          data.prizeValue = this.props.edit.prizeValue;
          data.prizeType = this.allPrizesTypes.find((s) => s.value === this.props.edit.prizeType);
        }

        // Update the state with the new data and reset loading state
        this.setState({
          data,
          loading: false,
          edit: this.props.edit,
        });
      }
    }
  };

  // Handle form submission
  doSubmit = (e) => {
    // Set loading state to true to indicate submission in progress
    this.setState({ loadingTemplate: true });

    // Clone the current state data
    const data = { ...this.state.data };

    // Prepare the payload for submission
    const payLoad = {
      title: data.title,
      startDate: parseInt(moment(data.startDate).format("x")), // Convert start date to timestamp
      endDate: data.endDate ? parseInt(moment(data.endDate).format("x")) : null, // Convert end date to timestamp if it exists
      prizeValue: parseInt(data.prizeValue), // Convert prize value to integer
      prizeType: parseInt(data.prizeType.value), // Convert prize type value to integer
      numberOfGames: parseInt(data.numberOfGames), // Convert number of games to integer
    };

    // Check if the image has been changed and set the appropriate image URL
    if (this.state.imageChange) {
      payLoad.image = data.image.medium;
    } else {
      payLoad.image = data.image;
    }

    // Check if the claim reward image has been changed and set the appropriate image URL
    if (this.state.claimRewardImageChange) {
      payLoad.claimRewardImage = data.claimRewardImage.medium;
    } else {
      payLoad.claimRewardImage = data.claimRewardImage;
    }

    // If editing an existing challenge, update it
    if (this.props.edit) {
      this.props.updateTeamPlayChallenge(this.props.edit._id, payLoad, (templateResponse) => {
        // Reset loading state
        this.setState({
          loadingTemplate: false,
        });

        // Handle successful response
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          this.props.updateTeamPlayChallengeReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          // Handle error response
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      // If adding a new challenge, create it
      this.props.addTeamPlayChallenge(payLoad, (templateResponse) => {
        // Reset loading state
        this.setState({
          loadingTemplate: false,
        });

        // Handle successful response
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          this.props.addTeamPlayChallengeReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          // Handle error response and reset data state
          this.setState({ data });
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };

  // Handle input change events
  onChange = (e) => {
    // Clone the current state data and errors
    const data = this.state.data;
    const errors = this.state.errors;

    // Update the data object with the new value from the input field
    data[e.name] = e.currentTarget;

    // Remove any existing error for the changed input field
    delete errors[e.name];

    // Update the state with the new data and errors
    this.setState({ data, errors });
  };

  // Joi schema for form validation
  schema = {
    title: Joi.string().required(),
    image: Joi.alternatives().try(Joi.string(), Joi.object()).required(),
    claimRewardImage: Joi.alternatives().try(Joi.string(), Joi.object()).required(),
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
    prizeValue: Joi.number().min(0).required(),
    prizeType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
    numberOfGames: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
  };

  // Clear form data
  clearData = () => {
    this.setState({ ...this.initState });
  };

  // Get minimum end date based on start date
  getEndDate = () => {
    var startDate = new Date(this.state.data.startDate);
    var endDate = "";
    if (startDate) endDate = new Date(startDate.getTime());
    else endDate = new Date();
    return endDate;
  };

  // Handle image change event
  handleImageChange = (e) => {
    // Clone the current state data and errors
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    // Set loading state to true to indicate image upload in progress
    this.setState({ loadingImages: true });

    // Get the file size in MB and file type
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();

    // Check if the file size exceeds the maximum allowable size
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    }
    // Check if the file type is not one of the allowed formats
    else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    }
    // If file size and type are valid, proceed with the upload
    else {
      // Generate a unique file name using uuid
      const fileName = uuidv4() + "." + ext;

      // Upload the file to AWS S3 using Amplify Storage
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        // Determine if the image is for claimRewardImage or regular image
        const isClaimRewardImage = e.target.id === "claimRewardImage";

        // Update the state with the new image URL and reset loading state
        this.setState({
          errors,
          data,
          loadingImages: false,
          [isClaimRewardImage ? "claimRewardImageChange" : "imageChange"]: true,
          data: {
            ...this.state.data,
            [isClaimRewardImage ? "claimRewardImage" : "image"]: { medium: "public/" + result.key },
          },
        });
      });
    }

    // Reset the file input value
    this.setState({ data });
    e.target.value = null;
  };

  render() {
    const data = this.state.data;
    console.log("error", this.state.errors);
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end  custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add a"} Team Play Challenge</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.loading && (
            <div className="form-check-outer">
              <div className="new-form-group">
                <div className="row">
                  <div className="col-8">{this.renderInput("title", "Title")}</div>
                  <div className="col-4">{this.renderInput("numberOfGames", "Number of Games")} </div>
                </div>
              </div>

              <div className="new-form-group">
                <div className="row">
                  {this.renderDateInput("startDate", "Start Date & Time", new Date(), false, true)}
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  {this.renderDateInput("endDate", "End Date & Time", this.getEndDate(), false, true)}
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-8">
                    <label htmlFor="">Prize Type</label>
                    <Select
                      name="prizeType"
                      options={this.allPrizesTypes?.map((s, i) => ({
                        name: s.name,
                        value: s.value,
                      }))}
                      label="Select Prize Type"
                      value={this.state.data.prizeType}
                      error={this.state.errors.prizeType}
                      onChange={this.onChange}
                      // isLoading={loadingPrizes}
                      // isDisabled={loadingPrizes}
                    />
                  </div>
                  <div className="col-4">{this.renderInput("prizeValue", "Prize Value", "", "number")}</div>
                </div>
              </div>
              <div className="flex-wrap kt-upload-row d-flex align-items-start">
                <div
                  className={`kt-upload-outer ${
                    !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                  } ${this.state.errors.image && "border-error"}`}
                >
                  <div className="kt-upload-img">
                    <img
                      src={
                        this.state.data.image
                          ? this.state.data.image.medium
                            ? this.state.data.image.medium.includes("https://")
                              ? this.state.data.image.medium
                              : process.env.REACT_APP_CDN + this.state.data.image.medium
                            : process.env.REACT_APP_CDN + this.state.data.image
                          : plusIcon
                      }
                      alt=""
                    />
                  </div>{" "}
                  {this.state.data.image && (
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = this.state.data;
                        data.image = null;
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} />
                    </div>
                  )}
                </div>
                <div className="kt-file-input">
                  <label htmlFor="formFile" className="kt-label">
                    {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                  </label>
                  <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
                </div>
              </div>
              <div className="flex-wrap mt-4 d-flex align-items-start">
                <div
                  className={`kt-upload-outer ${
                    !this.state.data.claimRewardImage ? "placeholder-uploaded-img" : " position-relative"
                  } ${this.state.errors.claimRewardImage && "border-error"}`}
                >
                  <div className="kt-upload-img">
                    <img
                      src={
                        this.state.data.claimRewardImage
                          ? this.state.data.claimRewardImage.medium
                            ? this.state.data.claimRewardImage.medium.includes("https://")
                              ? this.state.data.claimRewardImage.medium
                              : process.env.REACT_APP_CDN + this.state.data.claimRewardImage.medium
                            : process.env.REACT_APP_CDN + this.state.data.claimRewardImage
                          : plusIcon
                      }
                      alt=""
                    />
                  </div>{" "}
                  {this.state.data.claimRewardImage && (
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = this.state.data;
                        data.claimRewardImage = null;
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} />
                    </div>
                  )}
                </div>
                <div className="kt-file-input">
                  <label htmlFor="claimRewardImage" className="kt-label">
                    {this.state.data.claimRewardImage ? " Change Claim Reward Image" : "+ Upload Claim Reward Image"}
                  </label>
                  <input
                    className="form-control d-none"
                    type="file"
                    id="claimRewardImage"
                    onChange={this.handleImageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            disabled={data.prizeType === "NFT" && !this.state.nftFetched}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addTeamPlayChallenge: (data, callback) => dispatch(addTeamPlayChallenge(data, callback)),
  updateTeamPlayChallenge: (id, data, callback) => dispatch(updateTeamPlayChallenge(id, data, callback)),
  addTeamPlayChallengeReceived: (payLoad) => dispatch(addTeamPlayChallengeReceived(payLoad)),
  updateTeamPlayChallengeReceived: (payLoad) => dispatch(updateTeamPlayChallengeReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
