import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getTelegram,
  addPrizes,
  updatePrizes,
  addPrizeReceived,
  updatePrizeReceived,
  loadPrizes,
} from "store/telegram";
import { getSettings, loadHowItWorksTemplates, loadSweepstakeTemplate } from "store/settings";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { SWEEP_INSTANCES_STATUS, NFT_CHAIN_LISTS, SERVER } from "utils/constants";
import moment from "moment";
import { fetchNFT, ethToUSD, fetchTestNFT } from "utils/externalApiCaller";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import SelectMulti from "common/form/multiSelectSearch";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  allPrizesTypes = [
    {
      name: "USDT",
      value: "usdt",
    },
    {
      name: "Spin",
      value: "spin",
    },
    {
      name: "Points",
      value: "point",
    },
  ];
  initState = {
    loading: false,
    data: {
      tierName: "",
      startRank: "1",
      endRank: "1",
      prizes: [{ prizeType: { name: "USDT", value: "usdt" }, quantity: "1" }],
    },
    errors: {},
    edit: false,
  };
  initPrize = { prizeType: { name: "USDT", value: "usdt" }, quantity: "1" };

  state = { ...structuredClone(this.initState) };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        const data = { ...this.state.data };
        if (this.props.edit) {
          data.tierName = this.props.edit.tierName;
          data.startRank = this.props.edit.startRank?.toString();
          data.endRank = this.props.edit.endRank?.toString();
          data.prizes = this.props.edit.prizes?.map((p) => {
            const prizeType = this.allPrizesTypes?.find((p1) => p1.value === p.prizeType);
            return {
              prizeType,
              quantity: p.quantity?.toString(),
            };
          });
        }
        this.setState({
          data,
          edit: this.props.edit,
        });
      }
    }
  };

  doSubmit = (e) => {
    this.setState({ loadingTemplate: true });
    const data = { ...this.state.data };
    const payLoad = {
      tierName: data.tierName,
      startRank: parseInt(data.startRank),
      endRank: parseInt(data.endRank),
      prizes: this.state.data.prizes?.map((p) => ({ prizeType: p.prizeType.value, quantity: p.quantity })),
    };

    if (this.props.edit) {
      this.props.updatePrizes(this.props.edit._id, payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          payLoad.contract = data.sweepContract;
          this.props.updatePrizeReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addPrizes(payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          this.props.addPrizeReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          this.setState({ data });
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };

  schema = {
    tierName: Joi.string().required(),
    startRank: Joi.string().required(),
    endRank: Joi.string().required(),
    prizes: Joi.array().items({
      prizeType: Joi.object().keys({
        name: Joi.string().allow(""),
        value: Joi.string().required(),
      }),
      quantity: Joi.string().required(),
    }),
  };
  clearData = () => {
    this.setState({ ...structuredClone(this.initState) });
  };

  /**
   * Prize
   */
  addOne = (e) => {
    if (e) e.preventDefault();
    const data = { ...this.state.data };
    data.prizes = [...structuredClone(data.prizes), { prizeType: { name: "USDT", value: "usdt" }, quantity: "1" }];
    this.setState({ data });
  };
  removeOne = (index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (errors.prizes && errors.prizes[index]) {
      delete errors?.prizes[index];
    }
    data.prizes?.splice(index, 1);
    this.setState({ data });
  };
  changeOne = (e, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    console.log("Change:", index, data);
    const newStep = data.prizes[index];
    newStep[e.target.name] = e.target.value;
    data.prizes[index] = newStep;
    this.setState({ data, errors });
  };

  render() {
    const data = this.state.data;

    return (
      <Offcanvas
        className={"offcanvas offcanvas-end  custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add"} Sweepstakes Prize</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.loadingCurrencies && (
            <div className="form-check-outer">
              <div className="new-form-group">
                <div className="row">
                  <div className="col-12 new-form-group">{this.renderInput("tierName", "Title")}</div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-6">{this.renderInput("startRank", "Start Rank", "", "number")}</div>
                  <div className="col-6">{this.renderInput("endRank", "End Rank", "", "number")}</div>
                </div>
              </div>

              <div className="new-form-group">
                <table className="table large-table flyer-table">
                  <thead>
                    <tr>
                      <th>Prize type</th>
                      <th style={{ width: "30%" }}>Quantity</th>
                      {/* <th style={{ width: 10 }}>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data?.prizes.map((step, index) => (
                      <tr className="flyer-table" key={index}>
                        <td>
                          <div className="new-form-group">
                            <Select
                              name="prizeType"
                              options={this.allPrizesTypes?.map((s, i) => ({
                                name: s.name,
                                value: s.value,
                                data: s,
                              }))}
                              label="Select Prize Type"
                              value={this.state.data.prizes[index]?.prizeType}
                              error={this.state.errors.prizes}
                              onChange={(e) => {
                                const data = this.state.data;
                                const errors = this.state.errors;
                                data.prizes[index].prizeType = e.currentTarget?.data;
                                delete errors.prizes;
                                this.setState({ data, errors });
                              }}
                              isLoading={false}
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            key={index}
                            name="quantity"
                            type="number"
                            value={step?.quantity}
                            className={`form-control`}
                            onChange={(e) => {
                              this.changeOne(e, index);
                            }}
                          />
                        </td>
                        {/* <td>
                          {this.state.data.prizes?.length > 1 && (
                            <img
                              src={closeIcon}
                              alt=""
                              className="pointer"
                              onClick={(e) => {
                                this.removeOne(index);
                              }}
                            />
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>{" "}
                {/* <div className="new-form-group new-form-btn">
                  <NextButton
                    handleSubmit={this.addOne}
                    label="+ Add more"
                    loading={this.state.loadingContest || this.state.loadingImages}
                    classData={"btn btn-primary btn-sm orange-hover"}
                  />
                </div> */}
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            disabled={data.prizeType === "NFT" && !this.state.nftFetched}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addPrizes: (data, callback) => dispatch(addPrizes(data, callback)),
  updatePrizes: (id, data, callback) => dispatch(updatePrizes(id, data, callback)),
  loadPrizes: (callback) => dispatch(loadPrizes(callback)),
  addPrizeReceived: (payLoad) => dispatch(addPrizeReceived(payLoad)),
  updatePrizeReceived: (payLoad) => dispatch(updatePrizeReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
