import React, { Component } from "react";
import Select from "react-select";

class SelectSearch extends Component {
  state = {
    objValue: null,
  };

  customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    loadingIndicator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#F5F5F5",
        color: "#000",
        cursor: "pointer",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#1C1D23" : "white",
      maxHeight: "84px",
      minHeight: 40,
      borderRadius: 4,
      marginTop: 8,
      marginBottom: 8,
      padding: 10,
    }),
    menu: (base, state) => ({
      ...base,
      paddingLeft: 8,
      paddingRight: 8,
      border: 0,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15);",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      paddingLeft: 8,
      color: "#333230",
      borderColor: state.isFocused ? (this.props.error ? "red" : "#1C1D23") : this.props.error ? "red" : "#F5F5F5",
      boxShadow: "0 !important",
      backgroundColor: "#F5F5F5",
      borderRadius: 6,
      "&:hover": {
        borderColor: "#1C1D23",
        cursor: "pointer",
      },
      "&:focus": {
        borderColor: "#1C1D23",
      },
    }),
  };

  render() {
    const { name, options, label, error, value, defaultValue, isDisabled, isLoading, ...rest } = this.props;
    return (
      <div className=" custom-input">
        <Select
          {...rest}
          onChange={(a) => {
            this.props.onChange({
              name: name,
              currentTarget: a,
            });
          }}
          isMulti
          value={this.props.value}
          className="basic-single"
          defaultValue
          classNamePrefix="select-search"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          name={name}
          options={isLoading ? [] : options}
          placeholder={label}
          isDisabled={isDisabled}
          styles={this.customStyles}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default SelectSearch;
